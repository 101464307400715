import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { dateFilterFn, dateFilterProps } from "@/lib/common-utils";
import { BoardingPlayerSessionDto } from "@/shared/api";

const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleString();
};

export const useBoardingPlayerSessionsColumns = () => {
  const { t } = useTranslation();

  return useMemo<MRT_ColumnDef<BoardingPlayerSessionDto>[]>(
    () => [
      {
        header: t("boardingPlayerSessions.room"),
        id: "room",
        accessorFn: (row) => row.room,
      },
      {
        header: t("boardingPlayerSessions.pid"),
        id: "pid",
        accessorFn: (row) => row.pid,
      },
      {
        header: t("boardingPlayerSessions.tid"),
        id: "tid",
        accessorFn: (row) => row.tid,
      },
      {
        header: t("boardingPlayerSessions.boarding"),
        id: "boarding",
        accessorFn: (row) => formatDate(row.boarding),
        filterVariant: "datetime-range",
        muiFilterDateTimePickerProps: dateFilterProps,
        filterFn: dateFilterFn,
      },
      {
        header: t("boardingPlayerSessions.start"),
        id: "start",
        accessorFn: (row) => formatDate(row.start),
        filterVariant: "datetime-range",
        muiFilterDateTimePickerProps: dateFilterProps,
        filterFn: dateFilterFn,
      },
      {
        header: t("boardingPlayerSessions.end"),
        id: "end",
        accessorFn: (row) => formatDate(row.end),
        filterVariant: "datetime-range",
        muiFilterDateTimePickerProps: dateFilterProps,
        filterFn: dateFilterFn,
      },
    ],
    [t]
  );
}; 