import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppFullPageTabs from "@/components/app-full-page-tabs";
import StatserviceTable from "@/module/service-configs-module/statservice-module/statservice-table";
import { Route } from "@/routes/service-config/$configName";

export const statServiceRoomVariants = [
  "hh",
  "ff",
  "aa",
  "wpc",
  "pw",
  "pl",
  "dpzx",
  "wp",
  "dd",
  "ep",
  "tpuke",
  "rd",
];

const StatserviceModule = () => {
  const { t } = useTranslation();
  const navigate = Route.useNavigate();
  const { statserviceTab: tabValue } = Route.useSearch();

  const setTabValue = (value: number) => {
    void navigate({ search: { statserviceTab: value } });
  };

  const [oneDayAmount, setOneDayAmount] = useState<number>(0);
  const [sevenDaysAmount, setSevenDaysAmount] = useState<number>(0);
  const [thirtyDaysAmount, setThirtyDaysAmount] = useState<number>(0);

  const [selectedRoom, setSelectedRoom] = useState<string | null>(null);

  return (
    <AppFullPageTabs
      onTabChange={(val) => setTabValue(val)}
      defaultValue={tabValue}
      items={[
        {
          value: 0,
          label: t("daysAmount", { days: 1, amount: oneDayAmount }),
          render: () => (
            <StatserviceTable
              setRowsAmount={(amount: number) => setOneDayAmount(amount)}
              period={1}
              selectedRoom={selectedRoom}
              setSelectedRoom={setSelectedRoom}
            />
          ),
        },
        {
          value: 1,
          label: t("daysAmount", { days: 7, amount: sevenDaysAmount }),
          render: () => (
            <StatserviceTable
              setRowsAmount={(amount: number) => setSevenDaysAmount(amount)}
              period={7}
              selectedRoom={selectedRoom}
              setSelectedRoom={setSelectedRoom}
            />
          ),
        },
        {
          value: 2,
          label: t("daysAmount", { days: 30, amount: thirtyDaysAmount }),
          render: () => (
            <StatserviceTable
              setRowsAmount={(amount: number) => setThirtyDaysAmount(amount)}
              period={30}
              selectedRoom={selectedRoom}
              setSelectedRoom={setSelectedRoom}
            />
          ),
        },
      ]}
    />
  );
};

export default StatserviceModule;
