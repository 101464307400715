import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RouterInstanceDto } from "@/shared/api";

export const useRouterInstanceTableColumns = () => {
  const { t } = useTranslation();

  return useMemo<MRT_ColumnDef<RouterInstanceDto>[]>(
    () => [
      {
        id: "instance",
        header: t("routerInstanceTable.instance"),
        accessorFn: (row) => row.instance ?? "-",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        id: "always_active",
        header: t("routerInstanceTable.isAlwaysActive"),
        accessorFn: (row) => row.always_active?.toString() ?? "-",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        id: "probability",
        header: t("routerInstanceTable.probability"),
        accessorFn: (row) => row.probability ?? "-",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        id: "Status",
        header: t("routerInstanceTable.status"),
        sortingFn: "alphanumeric",
        filterFn: "contains",
        accessorFn: (row) => row.Status ?? "-",
        Cell: ({ row: { original } }) => {
          const color =
            original.Status.toUpperCase() === "READY" ? { color: "green" } : { color: "red" };
          return original.Status ? <p style={{ ...color }}>{original.Status}</p> : "-";
        },
      },
      {
        id: "UpdateAt",
        header: t("updatedAt"),
        accessorFn: (row) =>
          new Date(row.UpdateAt)
            .toLocaleString("ru-RU", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              fractionalSecondDigits: 3,
              timeZone: "UTC",
              hour12: false,
            })
            .replace(",", "")
            .replace(" ", " ") ?? "-",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
    ],
    [t]
  );
};
