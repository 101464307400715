import { Box, Tab, Tabs } from "@mui/material";
import { Fragment, ReactNode, SyntheticEvent, useRef, useState } from "react";

import HeaderedFullHeightContainer from "@/components/headered-full-height-container";

export interface AppTabItem {
  value: any;
  label?: ReactNode;
  render: () => ReactNode;
  condition?: () => boolean;
}

const AppFullPageTabs = ({
  defaultValue,
  items = [],
  onTabChange,
}: {
  defaultValue?: any;
  items?: AppTabItem[];
  onTabChange?: (val: any) => void;
}) => {
  const [tabValue, setTabValue] = useState<any>(defaultValue);

  const handleChangeTab = (_event: SyntheticEvent<Element, Event>, value: any) => {
    onTabChange?.(value);
    setTabValue(value);
  };

  return (
    <HeaderedFullHeightContainer
      header={
        <Tabs
          onChange={handleChangeTab}
          value={tabValue}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          {items
            .filter((item) => !item.condition || item.condition())
            .map((item) => (
              <Tab key={item.value} label={item.label} value={item.value} />
            ))}
        </Tabs>
      }
    >
      <>
        {items
          .filter((item) => !item.condition || item.condition())
          .map((item) => (
            <Fragment key={item.value}>{item.value === tabValue && item.render()}</Fragment>
          ))}
      </>
    </HeaderedFullHeightContainer>
  );
};

export default AppFullPageTabs;
