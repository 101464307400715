import { AddOutlined, DeleteOutlineOutlined, Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, Button, Checkbox, IconButton } from "@mui/material";
import {
  MRT_ColumnDef,
  MRT_TableInstance,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useSysCfgAddProxiesMutation,
  useSysCfgChangeEnabledMutation,
  useSysCfgDeleteMutation,
  useSysCfgQuery,
  useSysCfgUpdateMutation,
} from "@/api/hooks/sys-cfg-hooks";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import CommonConfigWriteModal from "@/components/config-modals/common-config-write-modal";
import ProxyAddModal from "@/components/config-modals/proxy-add-modal";
import { useTableSettings } from "@/hooks/use-table-settings";
import { assembleNamesFilter } from "@/lib/cfg-utils";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import {
  ResultDtoLong,
  ResultDtoSysCfgRegularDto,
  SysCfgRegularDto,
  SysCfgUpdateRequestDto,
} from "@/shared/api";
import { NilObj } from "@/types/commonTypes";
import Popconfirm from "@/components/popcofirm";

const ProxyConfigTable = ({ room }: { room: string }) => {
  const { t } = useTranslation();
  const {
    data: proxiesRs,
    isLoading: proxiesIsLoading,
    refetch,
  } = useSysCfgQuery({
    domain: room,
    config_name: ROOM_CONFIGS_NAMES.PROXY,
    sort: [{ key: "created_at", mode: "DESC" }],
  });

  const cfgPerm = useCfgPerm({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.PROXY,
  });

  const proxies = useMemo(() => proxiesRs?.data.content ?? [], [proxiesRs]);

  const isOwner = useIsOwnerOrSuperAdmin();

  const { mutate: addProxiesMutate, isPending: addProxiesIsPending } =
    useSysCfgAddProxiesMutation();
  const { mutate: changeEnabledMutate } = useSysCfgChangeEnabledMutation();
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();
  const { mutate: updateCfgMutate } = useSysCfgUpdateMutation();

  //This logic for reset row selection while using filtering/grouping
  const [addConfigModalIsOpen, setAddConfigModalIsOpen] = useState<boolean>(false);
  const [editingConfig, setEditingConfig] = useState<SysCfgRegularDto>();
  const [addProxiesReport, setAddProxiesReport] = useState<ResultDtoSysCfgRegularDto[]>();
  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  function handleClickUpdateConfig(name: string, config: SysCfgUpdateRequestDto) {
    updateCfgMutate(
      {
        domain: room,
        cfgName: ROOM_CONFIGS_NAMES.PROXY,
        name: name,
        body: config,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("configUpdated"), { variant: "success" });
          setEditingConfig(undefined);
          void refetch();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministratorMessage", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Config patch edit failure", err);
        },
      }
    );
  }

  function handleClickAddConfig() {
    setAddConfigModalIsOpen(true);
  }

  function handleAddNewProxies(authGroups: string[], value: string[]) {
    addProxiesMutate(
      {
        domain: room,
        authGroups: authGroups,
        connectionStrings: value,
      },
      {
        onSuccess: (response) => {
          setAddConfigModalIsOpen(false);
          void refetch();
          setAddProxiesReport(response.data);
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministratorMessage", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Adding proxies failure", err);
        },
      }
    );
  }

  function handleChangeEnableBulk(
    table: MRT_TableInstance<SysCfgRegularDto>,
    enabled: boolean
  ) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    changeEnabledMutate(
      {
        domain: room,
        cfgName: ROOM_CONFIGS_NAMES.PROXY,
        enabled: enabled,
        filters: new Set([
          { key: "name", mode: "STRICT_IN", value: names as unknown as object },
        ]),
      },
      {
        onSuccess: (response) => {
          enqueueSnackbar(
            t("elementsHasBeenUpdated", {
              data: response.data,
            }),
            {
              variant: "success",
            }
          );
          table.resetRowSelection();
          void refetch();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministratorMessage", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Change enabled operation failure", err);
        },
      }
    );
  }

  function handleDeleteConfigs(table: MRT_TableInstance<SysCfgRegularDto>) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate(
      {
        domain: room,
        cfgName: ROOM_CONFIGS_NAMES.PROXY,
        filters: new Set([
          { key: "name", mode: "STRICT_IN", value: names as unknown as object },
        ]),
      },
      {
        onSuccess: (response) => {
          setBulkOperationResult([response.data]);
          response.data.dataOnError
            ? enqueueSnackbar(t("somethingWentWrong"), { variant: "error" })
            : enqueueSnackbar(t("elementsHasBeenDeleted"), { variant: "success" });
          table.resetRowSelection();
          void refetch();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministratorMessage", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Delete operation failure", err);
        },
      }
    );
  }

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        id: "enabled",
        header: t("proxyTable.enabled"),
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        size: 80,
        Cell: ({ row }) => <Checkbox disabled checked={row.original.enabled} />,
      },
      {
        header: t("proxyTable.name"),
        accessorKey: "name",
        accessorFn: (row) => row.name ?? "-",
        filterFn: "contains",
        size: 120,
      },
      {
        id: "data.account_usage_count",
        header: t("proxyTable.connectedAccounts"),
        accessorFn: ({ data }) => (data as NilObj)?.account_usage_count ?? "-",
        filterFn: "contains",
        size: 120,
      },
      {
        id: "data.country",
        header: t("proxyTable.country"),
        accessorFn: ({ data }) => (data as NilObj)?.country ?? "-",
        filterFn: "contains",
        size: 120,
      },
      {
        id: "data.city",
        header: t("proxyTable.city"),
        accessorFn: ({ data }) => (data as NilObj)?.city ?? "-",
        filterFn: "contains",
        size: 120,
      },
      {
        id: "data.timezone",
        header: t("proxyTable.timeZone"),
        accessorFn: ({ data }) => (data as NilObj)?.timezone ?? "-",
        filterFn: "contains",
        size: 120,
      },
      {
        id: "data.proxy",
        header: t("proxyTable.proxyAddress"),
        accessorFn: ({ data }) => (data as NilObj)?.proxy ?? "-",
        filterFn: "contains",
        size: 250,
      },
      {
        id: "data.gps.lat",
        header: t("proxyTable.gpsLatitude"),
        accessorFn: ({ data }) => (data as NilObj)?.gps?.lat ?? "-",
        filterFn: "contains",
        size: 120,
      },
      {
        id: "data.gps.lng",
        header: t("proxyTable.gpsLongitude"),
        accessorFn: ({ data }) => (data as NilObj)?.gps?.lng ?? "-",
        filterFn: "contains",
        size: 120,
      },
      {
        header: t("proxyTable.mapLink"),
        Cell: ({
          row: {
            original: { data },
          },
        }) => {
          const lat = (data as NilObj)?.gps?.lat;
          const lng = (data as NilObj)?.gps?.lng;
          return (
            <>
              {lat && lng && (
                <a
                  href={`https://www.google.com/maps/search/${lat},${lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("proxyTable.onMap")}
                </a>
              )}
            </>
          );
        },
      },
    ],
    [t]
  );

  const { defaultProps, state, columnFilters, grouping, globalFilter } =
    useTableSettings<SysCfgRegularDto>("proxy");

  const table = useMaterialReactTable({
    data: proxies ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    ...defaultProps,
    initialState: {
      ...defaultProps,
      sorting: [{ id: "name", desc: false }],
    },
    state: {
      isLoading: proxiesIsLoading,
      ...state,
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: true,
    enablePagination: true,
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button onClick={() => handleClickAddConfig()}>
              <AddOutlined />
              {t("new")}
            </Button>
          )}

          {table.getSelectedRowModel().rows.length > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> {t("enable")}
                </IconButton>
              )}

              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> {t("disable")}
                </IconButton>
              )}

              {cfgPerm.delete && (
                <Popconfirm
                  title={t("deleteItems")}
                  description={t("areYouSureToDeleteItems")}
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                    <DeleteOutlineOutlined /> {"delete"}
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={room}
                  cfgName={ROOM_CONFIGS_NAMES.PROXY}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetch()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton onClick={() => setEditingConfig({ ...row.original })}>
          <Edit />
        </IconButton>
      </Box>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      {addConfigModalIsOpen && (
        <ProxyAddModal
          open={addConfigModalIsOpen}
          confirmLoading={addProxiesIsPending}
          title={t("addNewProxy")}
          onSave={(authGroups, value) => handleAddNewProxies(authGroups, value)}
          onCancel={() => setAddConfigModalIsOpen(false)}
        />
      )}

      {editingConfig && (
        <CommonConfigWriteModal
          mode="edit"
          isOpen={!!editingConfig}
          defaultValues={editingConfig}
          onCancel={() => setEditingConfig(undefined)}
          onSave={(config) => handleClickUpdateConfig(config.name, config)}
          disabled={!cfgPerm.write}
        />
      )}

      <BulkOperationReportModal
        title={t("addingProxiesReport")}
        resultList={addProxiesReport ?? []}
        showSuccess
        showProblematic
        open={!!addProxiesReport}
        onCancel={() => setAddProxiesReport(undefined)}
      />

      {bulkOperationResult && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title={t("operationResult")}
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default ProxyConfigTable;
