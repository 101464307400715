import { MRT_ColumnDef, MRT_TableOptions, useMaterialReactTable } from "material-react-table";

import { useMuiTableToolbarAlertBannerProps } from "@/hooks/use-mui-table-toolbar-alert-banner-props";
import { useTableSettings } from "@/hooks/use-table-settings";
import { SysAccountSessionRegularDto } from "@/shared/api";

export const useSessionsTable = (
  {
    columns,
    data,
    isDataLoadingError,
    loadingErrorMessage,
    name,
  }: {
    columns: MRT_ColumnDef<SysAccountSessionRegularDto>[];
    data?: SysAccountSessionRegularDto[];
    isDataLoadingError?: boolean;
    loadingErrorMessage?: string;
    name: string;
  },
  tableOptions?: Omit<MRT_TableOptions<SysAccountSessionRegularDto>, "columns" | "data">
) => {
  const { defaultProps, state } = useTableSettings<SysAccountSessionRegularDto>(name);
  const muiToolbarAlertBannerProps = useMuiTableToolbarAlertBannerProps(
    Boolean(isDataLoadingError),
    loadingErrorMessage
  );

  return useMaterialReactTable({
    ...defaultProps,
    data: data ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    enableSelectAll: true,
    enableRowSelection: true,
    enableColumnDragging: false,
    enableColumnFilterModes: true,
    muiToolbarAlertBannerProps,
    enablePagination: true,
    manualPagination: true,
    enableSorting: true,
    autoResetPageIndex: false,
    enableGlobalFilter: false,
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 40, //allow columns to get larger than default
      size: 40, //make columns wider by default
    },
    ...tableOptions,
    initialState: {
      columnVisibility: {
        clubs: false,
        "$.gold": false,
        "$.account.data.alc": false,
        "$.account.data.clubs": false,
      },
      density: "compact",
      ...tableOptions?.initialState,
    },
    state: {
      ...state,
      ...tableOptions?.state,
    }
  });
};
