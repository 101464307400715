import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useCreateAuthGroupMutation } from "@/api/hooks/auth-groups-hooks";
import { AuthGroupRequestDto } from "@/shared/api";

const CreateAuthGroupModal = ({
  onClose,
  onSuccess,
  open = false,
}: {
  onClose: () => void;
  onSuccess?: () => void;
  open?: boolean;
}) => {
  const { t } = useTranslation();
  const { mutate: createAuthGroup, isPending } = useCreateAuthGroupMutation();
  const form = useForm<AuthGroupRequestDto>();

  const handleOnSuccess = (data: AuthGroupRequestDto) => {
    createAuthGroup(
      { group: data },
      {
        onSuccess: () => {
          onClose();
          onSuccess && onSuccess();
          enqueueSnackbar(t("authGroupCreated"), { variant: "success" });
        },
        onError: (err) => {
          console.error("crate auth group error:", err);
          enqueueSnackbar(
            t("somethingWentWrongWhileSavingAuthGroup", {
              error: err.message,
            }),
            {
              variant: "error",
            }
          );
        },
      }
    );
  };

  useEffect(() => {
    if (!open) {
      form.reset();
    }
  }, [form, open]);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{t("addNewAuthGroup")}</DialogTitle>

      <FormContainer formContext={form} onSuccess={(data) => handleOnSuccess(data)}>
        <DialogContent>
          <TextFieldElement
            autoFocus
            name="name"
            required
            label={t("name")}
            variant="outlined"
          />
        </DialogContent>

        <DialogActions>
          <Button
            type="submit"
            endIcon={isPending ? <CircularProgress color="inherit" size={20} /> : undefined}
          >
            {t("save")}
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
};

export default CreateAuthGroupModal;
