import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useAtom } from "jotai/index";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { useSysCfgInRoomsQuery, useSysCfgRawQuery } from "@/api/hooks/sys-cfg-hooks";
import { formInitState } from "@/components/config-modals/clusterizer-edit-modal/constants";
import {
  EditClusteringType,
  EditedClusteringType,
} from "@/components/config-modals/clusterizer-edit-modal/types";
import { gameType } from "@/components/config-modals/router-edit-modal/constants";
import CustomSelectOption from "@/components/custom-select-option";
import { isOwner } from "@/lib/auth-predicates";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { SysCfgRawDto } from "@/shared/api";
import { meStore } from "@/store/store";

export default function ClusteringTypeEditModal({
  open,
  mode = "add",
  initialData,
  onSave,
  onCancel,
  disabled = false,
}: {
  open: boolean;
  mode?: "add" | "edit";
  initialData?: EditClusteringType;
  onSave?: (data: EditedClusteringType) => void;
  onCancel?: () => void;
  confirmLoading?: boolean;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const { data: authGroupsRs } = useAuthGroupsQuery();
  const [me] = useAtom(meStore);

  const defaultValues = useMemo<EditedClusteringType>(() => {
    let authGroups = initialData?.auth_groups ? Array.from(initialData.auth_groups) : [];

    if (authGroupsRs?.data.length === 1 && !isOwner(me)) {
      authGroups = authGroupsRs.data.map((it) => it.name);
    }

    return initialData
      ? {
          ...initialData,
          enabled: true,
          authGroups: authGroups,
        }
      : {
          ...formInitState,
          authGroups: authGroups,
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, /* authGroupsRs,*/ me]); //TODO: fix authGroupsRs. Because of authGroupsRs default values got broken by tab switching

  const { data: clusteringPatternsRs, refetch } = useSysCfgRawQuery(
    {
      domain: ROOM_NAMES.SERVICES,
      config_name: ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN,
    },
    {
      refetchOnMount: true,
    }
  );

  const { data: gameGroupsBaseRs } = useSysCfgInRoomsQuery(
    {
      configName: ROOM_CONFIGS_NAMES.GAME_GROUP,
    },
    {
      refetchOnMount: true,
    }
  );

  //TODO Remove useEffect and return config names with their rooms from back-end
  const [gameGroupsRs, setGameGroupsRs] = useState<SysCfgRawDto[]>([]);
  useEffect(() => {
    setGameGroupsRs(
      Array.from(new Set(gameGroupsBaseRs?.data?.map((it) => it.name) ?? [])).map((it) => ({
        name: it,
      }))
    );
  }, [gameGroupsBaseRs]);

  const {
    register,
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<EditedClusteringType>({
    mode: "all",
    disabled: disabled,
    defaultValues: { ...defaultValues },
  });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, open, reset]);

  const onSubmit: SubmitHandler<EditedClusteringType> = (data) => {
    data.name =
      data.name && data.name !== ""
        ? data.name
        : "clustering_type_".concat(Date.now().toString()); //TODO: move to Back-end

    if (!onSave) return;
    onSave(data);

    onCancel && onCancel(); //purifying form and close dialog
  };

  return (
    <>
      <Dialog open={open} key="Clusterig type modal">
        <DialogTitle>
          {mode === "add" ? t("addClusteringType") : t("editClusteringType")}
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "500px",
            }}
          >
            <input key="dialog-cl-type-name" type="hidden" {...register("name")} />

            <Controller
              name="authGroups"
              control={control}
              rules={{
                required: !isOwner(me) ? t("fieldRequired") : undefined,
              }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  options={authGroupsRs?.data.map((it) => it.name) ?? []}
                  value={value}
                  disabled={disabled || (authGroupsRs?.data.length === 1 && !isOwner(me))}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    setValue("authGroups", newValue); // Update the value in react-hook-form
                  }}
                  renderOption={(props, option, { selected }) => (
                    <CustomSelectOption label={option} selected={selected} props={props} />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t("authGroups")}
                      placeholder={t("authGroups")}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <TextField
              key="dialog-cl-type-comment"
              {...register("data.comment", { required: t("fieldRequired") })}
              label={t("comment")}
              placeholder={t("comment")}
              variant="standard"
              error={!!errors.data?.comment}
              helperText={!!errors.data?.comment && errors.data?.comment.message}
              disabled={disabled}
            />

            <Controller
              name="data.game_group"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  value={value}
                  disabled={disabled}
                  disableCloseOnSelect
                  onChange={(_event, newValue) => {
                    //const newIds = newValue.map(item => item.name);
                    onChange(newValue);
                    setValue("data.game_group", newValue);
                  }}
                  options={gameGroupsRs?.map((el) => el.name) ?? []}
                  getOptionLabel={(option) => option}
                  //value={gameGroupsRs?.filter(option => value.includes(option.name))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("gameGroups")}
                      placeholder={t("addTag")}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.game_type"
              control={control}
              rules={{
                required: t("fieldRequired"),
              }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  options={gameType.map((el) => el.value)}
                  value={value}
                  disabled={disabled}
                  onChange={(_event, newValue) => {
                    onChange(newValue);
                    setValue("data.game_type", newValue); // Update the value in react-hook-form
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("gameType")}
                      placeholder={t("addTag")}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.limit"
              control={control}
              rules={{
                required: t("fieldRequired"),
                validate: (v) =>
                  v.filter((a) => isNaN(a)).length === 0 || t("enterOnlyNumber"),
                pattern: {
                  value: /^[0-9]+$/,
                  message: t("enterOnlyNumber"),
                },
              }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={value}
                  disabled={disabled}
                  onChange={(_event, newValue) => {
                    onChange(newValue);

                    setValue(
                      "data.limit",
                      newValue.map((v) => parseInt(v.toString()))
                    ); // Update the value in react-hook-form
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("limit")}
                      placeholder={t("addTag")}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                      // inputProps={{inputMode:"numeric", pattern:"[0-9]*", title: "Enter only digits"}}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.pattern"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  // freeSolo
                  disabled={disabled}
                  options={clusteringPatternsRs?.data ?? []}
                  getOptionLabel={(option) => option.name}
                  value={clusteringPatternsRs?.data.filter((option) =>
                    value.includes(option.name)
                  )}
                  onChange={(_event, newValue) => {
                    const newIds = newValue.map((item) => item.name);
                    onChange(newIds);
                    setValue("data.pattern", newIds);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("pattern")}
                      placeholder={t("addTag")}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      onFocus={() => refetch()}
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.pre_flop"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={value}
                  disabled={disabled}
                  onChange={(_event, newValue) => {
                    onChange(newValue);
                    setValue("data.pre_flop", newValue); // Update the value in react-hook-form
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("preflop")}
                      placeholder={t("addTag")}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.post_flop"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={value}
                  disabled={disabled}
                  onChange={(_event, newValue) => {
                    onChange(newValue);
                    setValue("data.post_flop", newValue); // Update the value in react-hook-form
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("postflop")}
                      placeholder={t("addTag")}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" disabled={disabled}>
                {t("submit")}
              </Button>
              <Button type="button" onClick={() => onCancel && onCancel()}>
                {t("cancel")}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
