import { Groups } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { AxiosResponse } from "axios";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { useSysCfgApplyGroupsMutation } from "@/api/hooks/sys-cfg-hooks";
import ApplyAuthGroupsModal from "@/components/apply-auth-groups-modal";
import { assembleParticularFilter } from "@/lib/cfg-utils";
import { KeyFilter } from "@/shared/api";
import { NameIdentityItem } from "@/types/commonTypes";
import { enqueueSnackbar } from "notistack";

const ApplyAuthGroupsTableControl = <T extends NameIdentityItem>({
  domain,
  cfgName,
  table,
  filters,
  totalElementsCount,
  onSuccess,
}: {
  domain: string;
  cfgName: string;
  table: MRT_TableInstance<T>;
  filters: Set<KeyFilter>;
  totalElementsCount: number;
  onSuccess?: (rowsCount: number) => void;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const mutation = useSysCfgApplyGroupsMutation();

  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery({
    enabled: isOpen,
  });

  function handleMutationAction(rows: MRT_Row<T>[], authGroups: string[]) {
    if (rows.length > 0) {
      mutateParticular(rows, authGroups);
    } else {
      mutateAll(authGroups);
    }
  }

  function mutateAll(authGroups: string[]) {
    callMutation(filters, authGroups);
  }

  function mutateParticular(rows: MRT_Row<T>[], authGroups: string[]) {
    callMutation(assembleParticularFilter(rows), authGroups);
  }

  const callMutation = (filters: Set<KeyFilter>, authGroups: string[]) => {
    mutation.mutate(
      { domain, cfgName, filters, authGroups },
      {
        onSuccess: (rs: AxiosResponse<number>) => {
          enqueueSnackbar(t("configsSuccessfullyUpdated", { data: rs.data }), { variant: "success" });
          setIsOpen(false);
          onSuccess && onSuccess(rs.data);
        },
        onError: (err) => {
          console.error("configs update error", err);
          enqueueSnackbar(t("somethingWentWrongDuringUpdatingData"), { variant: "error" });
        },
      }
    );
  };

  return (
    <>
      <Tooltip title={t("setAuthGroups")}>
        <IconButton
          size="small"
          style={{ fontSize: 12 }}
          color="info"
          onClick={() => setIsOpen(true)}
        >
          <Groups /> &nbsp; {t("setAG")} (
          {table.getSelectedRowModel().rows.length > 0
            ? table.getSelectedRowModel().rows.length
            : totalElementsCount}
          )
        </IconButton>
      </Tooltip>

      <ApplyAuthGroupsModal
        onClose={() => setIsOpen(false)}
        onSave={(data) =>
          handleMutationAction(table.getSelectedRowModel().rows, data.auth_groups)
        }
        authGroups={authGroupsRs?.data.map((i) => i.name) ?? []}
        authGroupsIsLoading={authGroupsIsLoading}
        confirmLoading={mutation.isPending}
        open={isOpen}
        maxWidth="xs"
        fullWidth
      />
    </>
  );
};

export default ApplyAuthGroupsTableControl;
