import { Delete, LockReset, Undo } from "@mui/icons-material";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { Button, Checkbox } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { red } from "@mui/material/colors";
import {
  MRT_ColumnDef,
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDeleteUsersMutation, useRevokeTokensMutation } from "@/api/hooks/user-hooks";
import { useIsSuperAdmin } from "@/auth/hooks";
import ChangeUserPasswordModal from "@/components/change-user-password-modal";
import { Route as UsersRoute } from "@/routes/users/index";
import { UpdateUserRequestDto, UserResponseRegularDto } from "@/shared/api";
import Popconfirm from "@/components/popcofirm";

const redColor = red[500];

const UsersListTable = ({
  users,
  usersIsLoading,
  onUpdate,
  onDeleted,
}: {
  users: UserResponseRegularDto[];
  usersIsLoading: boolean;
  userIsRefetching: boolean;
  onUpdate: (user: UpdateUserRequestDto) => void;
  onDeleted: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = UsersRoute.useNavigate();
  // const navigate = useNavigate();
  const revokeTokensMutation = useRevokeTokensMutation();
  const deleteUsersMutation = useDeleteUsersMutation();
  const isSuperAdmin = useIsSuperAdmin();

  const [userId, setUserId] = useState<number>(0);
  const [changePasswordIsOpen, setChangePasswordIsOpen] = useState<boolean>(false);

  const columns = useMemo<MRT_ColumnDef<UserResponseRegularDto>[]>(
    () => [
      {
        id: "username",
        header: t("username"),
        accessorFn: (row) => row.username,
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {!row.original.enabled && (
              <Tooltip title="disabled">
                <ErrorOutlinedIcon
                  style={{ width: 15, height: 15, color: redColor, marginRight: 3 }}
                />
              </Tooltip>
            )}
            <span>{row.original.username}</span>
          </div>
        ),
      },
      {
        id: "is_owner",
        header: t("isOwner"),
        accessorFn: (row) => row.is_owner,
        Cell: ({ row: { original } }) => "" + (original.is_owner ?? "-"),
      },
      {
        id: "enabled",
        header: t("enabled"),
        accessorFn: (row) => row.enabled,
        Cell: ({ row: { original } }) => (
          <Checkbox
            checked={original.enabled}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onChange={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onUpdate({
                ...original,
                enabled: event.target.checked,
                group: Array.from(original.group),
              });
            }}
          />
        ),
      },
    ],
    [onUpdate, t]
  );

  function handleRevokeTokens(rows: MRT_Row<UserResponseRegularDto>[]) {
    revokeTokensMutation.mutate(
      {
        userIds: rows.map((item) => item.original.id),
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("tokenRevoked"), { variant: "success" });
        },
        onError: (err) => {
          enqueueSnackbar(t("tokenRevokeError"), { variant: "error" });
          console.error("revoke tokens failure", err);
        },
      }
    );
  }

  function handleDeleteUsers(rows: MRT_Row<UserResponseRegularDto>[]) {
    deleteUsersMutation.mutate(
      {
        ids: rows.map((item) => item.original.id),
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("usersSuccessfullyDeleted"), { variant: "success" });
          onDeleted();
        },
        onError: (err) => {
          enqueueSnackbar(t("usersDeleteError"), {
            variant: "error",
          });
          console.error("Users deletion failure", err);
        },
      }
    );
  }

  const table = useMaterialReactTable({
    data: users,
    columns: columns,
    getRowId: (originalRow) => String(originalRow.id),
    selectAllMode: "all",
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
      sorting: [{ id: "username", desc: false }],
    },
    state: {
      isLoading: usersIsLoading,
      showProgressBars: usersIsLoading,
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: false,
    enableColumnFilterModes: true,
    enablePagination: true,
    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => navigate({ to: "/users/$id", params: { id: row.original.id } }),
      sx: {
        cursor: "pointer",
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    muiTableContainerProps: {
      sx: { flexGrow: 10, overflowY: "scroll", height: "500px" },
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const rows = table.getSelectedRowModel().rows;
      return (
        <div>
          {rows.length > 0 && (
            <>
              <Button startIcon={<Undo />} onClick={() => handleRevokeTokens(rows)}>
                {t("revokeTones", { length: rows.length })}
              </Button>

              {isSuperAdmin && (
                <Popconfirm
                  title={t("areYouSureToDeleteUsers")}
                  onConfirm={() => handleDeleteUsers(rows)}
                >
                  <Button startIcon={<Delete />}>
                    {t("deleteUsersLength", { length: rows.length })}
                  </Button>
                </Popconfirm>
              )}
            </>
          )}

          {rows.length === 1 && (
            <Button
              startIcon={<LockReset />}
              color="secondary"
              onClick={() => {
                const userId = rows[0]?.original.id;
                if (userId) {
                  setUserId(userId);
                  setChangePasswordIsOpen(true);
                } else {
                  enqueueSnackbar(t("noUsersSelected"), { variant: "error" });
                }
              }}
            >
              {t("changePassword")}
            </Button>
          )}
        </div>
      );
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <ChangeUserPasswordModal
        open={changePasswordIsOpen}
        userId={userId}
        onClose={() => setChangePasswordIsOpen(false)}
      />
    </>
  );
};

export default UsersListTable;
