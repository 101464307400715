import { Badge } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useUpdateNicknameMutation,
  useUpdateNicknamesMutation,
} from "@/api/hooks/command-hooks";
import { assembleParticularFilterCt } from "@/lib/cfg-utils";
import UpdateNicknameModal from "@/module/accounts-module/components/update-nickname-modal";
import { KeyFilter, SysAccountSessionRegularDto } from "@/shared/api";
import { enqueueSnackbar } from "notistack";
import Popconfirm from "@/components/popcofirm";

const UpdateNicknamesTableControl = ({
  roomName,
  table,
  filters,
  totalElementsCount,
  onSuccess,
}: {
  roomName: string;
  table: MRT_TableInstance<SysAccountSessionRegularDto>;
  filters: Set<KeyFilter>;
  totalElementsCount: number;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const [updateOneIsOpen, setUpdateOneIsOpen] = useState<boolean>(false);

  const updateNicknameMutation = useUpdateNicknameMutation();
  const updateNicknamesMutation = useUpdateNicknamesMutation();

  function handleOneMutationAction({
    row,
    newNickname,
  }: {
    row?: MRT_Row<SysAccountSessionRegularDto>;
    newNickname?: string;
  }) {
    if (!row) {
      enqueueSnackbar(t("thereAreNoOneRoleSelected"), { variant: "error" });
      return;
    }

    callOneMutation(row.original.id, newNickname);
  }

  function handleManyMutationAction(rows: MRT_Row<SysAccountSessionRegularDto>[]) {
    if (rows.length === 0) {
      callManyMutation(filters);
    } else {
      callManyMutation(
        assembleParticularFilterCt({
          rows: rows,
          keyName: "id",
          keySupplier: (val) => val.id,
        })
      );
    }
  }

  function callOneMutation(sessionId: string, newNickname?: string) {
    updateNicknameMutation.mutate(
      {
        domain: roomName,
        sessionId: sessionId,
        newNickname: newNickname,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("updateNicknameCommandSuccessfullySent"), { variant: "success" });
          setUpdateOneIsOpen(false);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.log("update nickname command failure", err);
          enqueueSnackbar(t("operationFailureContactAdministratorMessage"), { variant: "error" });
        },
      }
    );
  }

  function callManyMutation(filters: Set<KeyFilter>) {
    updateNicknamesMutation.mutate(
      {
        domain: roomName,
        filters: filters,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("updateNicknamesCommandSuccessfullySent"), { variant: "success" });
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.log("update nicknames command failure", err);
          enqueueSnackbar(t("operationFailureContactAdministratorMessage"), { variant: "error" });
        },
      }
    );
  }

  return (
    <>
      {table.getSelectedRowModel().rows.length === 1 ? (
        <IconButton
          size="small"
          style={{ fontSize: 12 }}
          color="primary"
          onClick={() => setUpdateOneIsOpen(true)}
        >
          <Badge />
          &nbsp;{" "}
          {t("updateNicknamesLength", {
            length:
              table.getSelectedRowModel().rows.length > 0
                ? table.getSelectedRowModel().rows.length
                : totalElementsCount,
          })}
        </IconButton>
      ) : (
        <Popconfirm
          title={t("areYouSureYouWantToUpdateNicknames")}
          onConfirm={() => handleManyMutationAction(table.getSelectedRowModel().rows)}
        >
          <IconButton size="small" style={{ fontSize: 12 }} color="primary">
            <Badge />
            &nbsp;
            {t("updateNicknamesLength", {
              length:
                table.getSelectedRowModel().rows.length > 0
                  ? table.getSelectedRowModel().rows.length
                  : totalElementsCount,
            })}
          </IconButton>
        </Popconfirm>
      )}

      <UpdateNicknameModal
        onCancel={() => setUpdateOneIsOpen(false)}
        onSave={(nickname) =>
          handleOneMutationAction({
            row: table.getSelectedRowModel().rows[0],
            newNickname: nickname,
          })
        }
        isOpen={updateOneIsOpen}
      />
    </>
  );
};

export default UpdateNicknamesTableControl;
