import { Autocomplete, Button, FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useJsonFormRawJson, useJsonFormUpdateMutation } from "@/api/hooks/json-form-hooks";
import { formatJson } from "@/lib/common-utils";
import AceEditor from "react-ace";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useAtom } from "jotai/index";
import { meStore } from "@/store/store";
import { AxiosResponse } from "axios";
import { enqueueSnackbar } from "notistack";

const JsonFormManagementModule = () => {

  const [me] = useAtom(meStore);
  const { t } = useTranslation();
  const aceRef = useRef(null);
  const updateMutation = useJsonFormUpdateMutation()

  const [selectedForm, setSelectedForm] = useState<any | null>(null);

  const {
    data: jsonFormsRawData,
    isLoading,
    refetch: refetchJsonFormsRawData
  } = useJsonFormRawJson({})

  const parsedJsonFormsResponse = useMemo(() => {
    return jsonFormsRawData?.data.map(el => JSON.parse(el));
  }, [jsonFormsRawData]);

  const jsonFormNames = useMemo(() => {
    return parsedJsonFormsResponse?.map(el => {
      return {
        label: el.room_name.concat(" - ").concat(el.collection_name),
        value: el._id
      }
    })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [parsedJsonFormsResponse]);

  const handleFormChange = (event: any, newValue: any) => {
    setSelectedForm(parsedJsonFormsResponse?.find(el => el._id === newValue?.value) ?? null);
    setValue("aceEditor", formatJson(JSON.stringify(parsedJsonFormsResponse?.find(el => el._id === newValue?.value)) ?? "{}"));
  };

  const schema = useMemo(() => {
    return z.object({
      aceEditor: z.string().refine(
        (data) => {
          try {
            const parsed = JSON.parse(data);
            return typeof parsed === "object" && parsed !== null && !Array.isArray(parsed);
          } catch {
            return false;
          }
        },
        { message: t("invalidJson") }
      ),
    })
  }, [me, t]);

  type Form = z.infer<typeof schema>;

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Form>({
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  const submit = handleSubmit((data) => {
    updateMutation.mutate({
      id: selectedForm?._id.$oid,
      body: data.aceEditor
    }, {
      onSuccess: (rs: AxiosResponse<number>) => {
        enqueueSnackbar(t("configsSuccessfullyUpdated", { data: rs.data }), { variant: "success" });
      },
      onError: (err) => {
        console.error("configs update error", err);
        enqueueSnackbar(t("somethingWentWrongDuringUpdatingData"), { variant: "error" });
      },
    })
  })


  return<>
    <Autocomplete
      options={jsonFormNames ?? []}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => <TextField {...params} label="Select a form" />}
      style={{ width: 300 }}
      onChange={handleFormChange}
    />

    {selectedForm &&
      <Stack spacing={2} sx={{ paddingTop: "5px", width: "600px" }}>
        <Controller
          control={control}
          name="aceEditor"
          render={({ field }) => (
            <>
              <FormControl error={!!errors.aceEditor}>
                <p>{t("data")}</p>
                <AceEditor
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  readOnly={false}
                  ref={aceRef}
                  mode="json"
                  theme="dracula"
                  width="600px"
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    tabSize: 2,
                  }}
                />
                <FormHelperText>{errors.aceEditor?.message ?? " "}</FormHelperText>
              </FormControl>
            </>
          )}
        />
        <Button
        sx={{ width: "100px" }}
        variant="contained"
        onClick={submit}>
          {t("save")}
        </Button>
      </Stack>
    }

  </>
}

export default JsonFormManagementModule;
