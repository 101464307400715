import {
  AddOutlined,
  ContentCopy,
  DeleteOutlineOutlined,
  Edit,
  Power,
  PowerOff,
} from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysCfgQuery } from "@/api/hooks/sys-cfg-hooks";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import PlayerGroupConfigModal from "@/components/config-modals/player-group-config-modal";
import Popconfirm from "@/components/popcofirm";
import { useTableSettings } from "@/hooks/use-table-settings";
import { assembleNamesFilter } from "@/lib/cfg-utils";
import { getArrayOrEmpty } from "@/lib/common-utils";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import {
  useConfigChangeEnabled,
  useDeleteConfigs,
  useSaveConfig,
  useUpdateConfig,
} from "@/module/room-configs-module/hooks/room-configs-hooks";
import { ResultDtoLong, SysCfgCreateRequestDto, SysCfgRegularDto } from "@/shared/api";
import { AnyObj } from "@/types/commonTypes";

const PlayerGroupConfigTableComponent = ({ room }: { room: string }) => {
  const { t } = useTranslation();

  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch,
  } = useSysCfgQuery({
    domain: room,
    config_name: ROOM_CONFIGS_NAMES.PLAYER_GROUP,
  });

  const cfgPerm = useCfgPerm({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.PLAYER_GROUP,
  });

  const roomConfigsSortedData = React.useMemo(() => {
    return (
      configsRs?.data.content?.sort(
        (a, b) => new Date(b.created_at).getDate() - new Date(a.created_at).getDate()
      ) ?? []
    );
  }, [configsRs]);

  const isOwner = useIsOwnerOrSuperAdmin();

  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  const handleClickSaveNewConfig = useSaveConfig({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.PLAYER_GROUP,
    onSuccessCallback: () => {
      void refetch();
      closeAddConfig();
    },
  });

  const handleClickUpdateConfig = useUpdateConfig({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.PLAYER_GROUP,
    onSuccessCallback: () => {
      void refetch();
      setEditingConfig(undefined);
    },
  });

  const handleChangeEnableBulk = useConfigChangeEnabled({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.PLAYER_GROUP,
    onSuccessCallback: (table) => {
      void refetch();
      table.resetRowSelection();
    },
  });

  const handleDeleteConfigs = useDeleteConfigs({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.PLAYER_GROUP,
    onSuccessCallback: (table, response) => {
      setBulkOperationResult([response.data]);
      void refetch();
      table.resetRowSelection();
    },
  });

  //This logic for reset row selection while using filtering/grouping
  const [editingConfig, setEditingConfig] = useState<SysCfgRegularDto | undefined>(undefined);
  const [addConfig, setAddConfig] = useState<boolean>(false);
  const [addingConfig, setAddingConfig] = useState<SysCfgCreateRequestDto | undefined>(
    undefined
  );

  function closeAddConfig() {
    setAddingConfig(undefined);
    setAddConfig(false);
  }

  function handleClickAddConfig() {
    setAddConfig(true);
  }

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        id: "enabled",
        header: t("playerGroupTable.enabled"),
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => <Checkbox checked={row.original.enabled} disabled />,
      },
      {
        id: "name",
        header: t("playerGroupTable.name"),
        accessorKey: "name",
        filterFn: "contains",
      },
      {
        id: "vpip",
        header: t("playerGroupTable.vpip"),
        filterFn: "contains",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          return (
            <>
              <span>{t("minValue", { value: data?.vpip?.min ?? "-" })}</span>
              <br />
              <span>{t("maxValue", { value: data?.vpip?.max ?? "-" })}</span>
            </>
          );
        },
      },
      {
        id: "pfr",
        header: t("playerGroupTable.pfr"),
        filterFn: "contains",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          return (
            <>
              <span>{t("minValue", { value: data?.pfr?.min ?? "-" })}</span>
              <br />
              <span>{t("maxValue", { value: data?.pfr?.max ?? "-" })}</span>
            </>
          );
        },
      },
      {
        id: "hands",
        header: t("playerGroupTable.hands"),
        filterFn: "contains",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          return (
            <>
              <span>{t("minValue", { value: data?.hands?.min ?? "-" })}</span>
              <br />
              <span>{t("maxValue", { value: data?.hands?.max ?? "-" })}</span>
            </>
          );
        },
      },
      {
        id: "ai_profiles",
        header: t("playerGroupTable.aiProfiles"),
        filterFn: "contains",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          const aiProfiles = getArrayOrEmpty<string>(data?.ai_profiles);
          return aiProfiles.join(", ");
        },
      },
    ],
    [t]
  );

  const { defaultProps, state, columnFilters, grouping, globalFilter } =
    useTableSettings<SysCfgRegularDto>("playersGroup");

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    ...defaultProps,
    initialState: {
      ...defaultProps,
      sorting: [{ id: "name", desc: false }],
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: true,
    enablePagination: true,
    enableSorting: true,
    state: {
      ...state,
      isLoading: configsIsLoading,
    },
    renderTopToolbarCustomActions: () => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button onClick={() => handleClickAddConfig()}>
              <AddOutlined /> {t("new")}
            </Button>
          )}

          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> {t("enable")}
                </IconButton>
              )}

              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> {t("disable")}
                </IconButton>
              )}

              {cfgPerm.delete && (
                <Popconfirm
                  title={t("deleteItems")}
                  description={t("areYouSureToDeleteItems")}
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                    <DeleteOutlineOutlined /> {t("delete")}
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={room}
                  cfgName={ROOM_CONFIGS_NAMES.PLAYER_GROUP}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetch()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton
          onClick={() =>
            setEditingConfig({
              ...row.original,
              auth_groups: row.original.auth_groups ?? new Set(),
            })
          }
        >
          <Edit />
        </IconButton>
        {cfgPerm.write && (
          <IconButton
            onClick={() => {
              setAddingConfig({ ...row.original, name: "", auth_groups: new Set() });
              handleClickAddConfig();
            }}
          >
            <ContentCopy />
          </IconButton>
        )}
      </Box>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      {addConfig && (
        <PlayerGroupConfigModal
          mode="add"
          isOpen={addConfig}
          defaultValues={addingConfig}
          onCancel={() => closeAddConfig()}
          onSave={(config) => handleClickSaveNewConfig(config)}
          disabled={!cfgPerm.write}
        />
      )}

      {editingConfig && (
        <PlayerGroupConfigModal
          mode="edit"
          isOpen={!!editingConfig}
          defaultValues={editingConfig}
          onCancel={() => setEditingConfig(undefined)}
          onSave={(config) => handleClickUpdateConfig(config.name, config)}
          disabled={!cfgPerm.write}
        />
      )}

      {bulkOperationResult && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title={t("operationResult")}
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default PlayerGroupConfigTableComponent;
