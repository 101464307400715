import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useAtom } from "jotai/index";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { AutocompleteElement, PasswordElement, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { useSysCfgCombinedQuery } from "@/api/hooks/sys-cfg-hooks";
import {
  ACC_OS_TP,
  ACC_TP,
  ACC_TP_LB,
} from "@/components/config-modals/router-edit-modal/constants";
import CustomSelectOption from "@/components/custom-select-option";
import { convertEditFormToUpdateDto, mapSysAccountToEditForm } from "@/lib/accountsUtils";
import { isOwner } from "@/lib/auth-predicates";
import {
  ACCOUNT_RELATION_CONFIGS_NAMES,
  ACCOUNT_RELATION_SERVICE_CONFIGS_NAMES,
  ROOM_NAMES,
} from "@/lib/constants";
import { SysAccountRegularDto, SysAccountUpdateDto } from "@/shared/api";
import { meStore } from "@/store/store";
import { SysAccountEditForm } from "@/types/accountTypes";

const SysAccountWriteModal = ({
  mode,
  accountType,
  isOpen = false,
  roomName,
  defaultValues,
  onSave,
  onClose,
  confirmLoading = false,
  disabled = false,
}: {
  mode: "add" | "edit";
  accountType: "service" | "regular";
  isOpen?: boolean;
  roomName: string;
  defaultValues?: SysAccountRegularDto;
  onSave: (name: string, account: SysAccountUpdateDto) => void;
  onClose: () => void;
  confirmLoading?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [me] = useAtom(meStore);

  const schema = useMemo(() => {
    return z.object({
      name: z.string().min(1),
      authGroups: isOwner(me)
        ? z.array(z.string())
        : z.array(z.string()).min(1, t("containsAtLeastOneGroup")),
      password: z.string().min(1),
      walletCode: z.string().min(1, t("fieldRequired")).regex(/^\d+$/, t("onlyNumbers")),
      source: z.string(),
      osType: z
        .number({ invalid_type_error: t("fieldRequired") })
        .int()
        .min(0)
        .max(2),
      accountType: z.number(),
      comment: z.string().nullable(),
      gameGroup: z.string().nullable(),
      cid: z.coerce.string().nullable(),
      proxy: z.string().nullable(),
      behaviour: z.string().nullable(),
      schedule: z.string().nullable(),
      gameType: z.string().nullable(),
      aiProfile: z.string().nullable(),
      aiProfileOb: z.string().nullable(),
      timingProfile: z.string().nullable(),
      timingProfileOb: z.string().nullable(),
      insuranceProfile: z.string().nullable(),
      insuranceProfileOb: z.string().nullable(),
    });
  }, [me, t, t]);

  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery({
    enabled: isOpen,
  });

  const authGroups = useMemo(() => {
    return authGroupsRs?.data.map((it) => it.name).sort((a, b) => a.localeCompare(b)) ?? [];
  }, [authGroupsRs]);

  const initAccount = useMemo<SysAccountEditForm>(() => {
    const rs = mapSysAccountToEditForm(defaultValues);

    if (mode === "add" && accountType === "regular") {
      rs.accountType = ACC_TP.REGULAR;
    }

    if (authGroups.length === 1 && !isOwner(me)) {
      rs.authGroups = authGroups;
    }

    return rs;
  }, [accountType, defaultValues, mode, me, authGroups]);

  const accountTypes = useMemo<number[]>(() => {
    if (mode === "edit")
      return [ACC_TP.REGULAR, ACC_TP.MANAGER, ACC_TP.SCANNER, ACC_TP.GRABBER];

    if (mode === "add") {
      return accountType === "service"
        ? [ACC_TP.MANAGER, ACC_TP.SCANNER, ACC_TP.GRABBER]
        : [ACC_TP.REGULAR];
    }

    return [];
  }, [accountType, mode]);

  const { data: selectConfigsRs } = useSysCfgCombinedQuery(
    {
      domain: roomName,
      config_names: ACCOUNT_RELATION_CONFIGS_NAMES,
    },
    {
      enabled: isOpen,
    }
  );

  const { data: selectServiceConfigsRs } = useSysCfgCombinedQuery(
    {
      domain: ROOM_NAMES.SERVICES,
      config_names: ACCOUNT_RELATION_SERVICE_CONFIGS_NAMES,
    },
    {
      enabled: isOpen,
    }
  );

  const configs = useMemo(
    () =>
      new Map(
        (selectConfigsRs?.data ?? [])
          .concat(selectServiceConfigsRs?.data ?? [])
          .map((config) => [
            config.config_name,
            config.configs.sort((a, b) =>
              a.name.localeCompare(b.name, undefined, { numeric: true })
            ),
          ])
      ),
    [selectConfigsRs, selectServiceConfigsRs]
  );

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<SysAccountEditForm>({
    mode: "onChange",
    defaultValues: { ...initAccount },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    reset({ ...initAccount });
  }, [isOpen, initAccount, reset]);

  const submit = handleSubmit((data) => {
    onSave(data.name, convertEditFormToUpdateDto(data));
  });

  const handleSave = () => {
    void submit();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {mode === "add"
          ? t("createAccount")
          : t("editAccountName", { name: initAccount.name })}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ paddingTop: "5px", width: "400px" }}>
          <TextFieldElement
            required
            name="name"
            control={control}
            label={t("name")}
            error={!!errors.name}
            helperText={errors.name?.message ?? " "}
            variant="standard"
            InputProps={{
              disabled: mode === "edit",
            }}
          />

          <AutocompleteElement
            name="authGroups"
            loading={authGroupsIsLoading}
            control={control}
            label={t("authGroups")}
            options={authGroups}
            multiple
            required={!isOwner(me)}
            textFieldProps={{
              error: !!errors.authGroups,
              helperText: errors.authGroups?.message ?? " ",
              variant: "standard",
            }}
            autocompleteProps={{
              readOnly: disabled || (authGroups.length === 1 && !isOwner(me)),
              renderOption: (props, option, { selected }) => (
                <CustomSelectOption label={option} selected={selected} props={props} />
              ),
            }}
          />

          <PasswordElement
            name="password"
            required
            control={control}
            label={t("password")}
            error={!!errors.password}
            helperText={errors.password?.message ?? " "}
            variant="standard"
            InputProps={{
              disabled: disabled,
            }}
            renderIcon={disabled ? () => <></> : undefined}
          />

          {disabled ? (
            <PasswordElement
              name="walletCode"
              required
              control={control}
              label={t("walletCode")}
              error={!!errors.walletCode}
              helperText={errors.walletCode?.message ?? " "}
              variant="standard"
              InputProps={{ disabled: disabled }}
              renderIcon={disabled ? () => <></> : undefined}
            />
          ) : (
            <TextFieldElement
              required
              name="walletCode"
              control={control}
              label={t("walletCode")}
              error={!!errors.walletCode}
              helperText={errors.walletCode?.message ?? " "}
              variant="standard"
              InputProps={{ disabled: disabled }}
            />
          )}

          <TextFieldElement
            name="source"
            control={control}
            label={t("source")}
            error={!!errors.source}
            helperText={errors.source?.message ?? " "}
            variant="standard"
            InputProps={{ disabled: disabled }}
          />

          <TextFieldElement
            type="number"
            name="cid"
            control={control}
            label={t("cid")}
            error={!!errors.cid}
            helperText={errors.cid?.message ?? " "}
            variant="standard"
            InputProps={{ disabled: disabled }}
          />

          <AutocompleteElement
            name="osType"
            control={control}
            label={t("osType")}
            options={[ACC_OS_TP.ANY, ACC_OS_TP.IOS, ACC_OS_TP.ANDROID].sort((a, b) => a - b)}
            autocompleteProps={{
              readOnly: disabled,
              getOptionLabel: (option) => ["Any", "Android", "IOS"][option],
              disableClearable: true,
            }}
            textFieldProps={{
              variant: "standard",
              error: !!errors.osType,
              helperText: errors.osType?.message ?? " ",
            }}
          />

          {(mode === "edit" || accountType === "service") && (
            <AutocompleteElement
              name="accountType"
              control={control}
              label={t("accountType")}
              options={accountTypes}
              autocompleteProps={{
                readOnly: disabled,
                getOptionLabel: (option) => ACC_TP_LB.get(option) ?? t("unknown"),
                disableClearable: true,
              }}
              textFieldProps={{
                variant: "standard",
                error: !!errors.accountType,
                helperText: errors.accountType?.message ?? " ",
              }}
            />
          )}

          <AutocompleteElement
            name="gameGroup"
            control={control}
            label={t("gameGroup")}
            options={configs.get("game_group")?.map((i) => i.name) ?? []}
            autocompleteProps={{
              readOnly: disabled,
            }}
            textFieldProps={{
              variant: "standard",
              error: !!errors.gameGroup,
              helperText: errors.gameGroup?.message ?? " ",
            }}
          />

          {accountType === "regular" && (
            <>
              <AutocompleteElement
                name="proxy"
                control={control}
                label={t("proxy")}
                options={configs.get("proxy")?.map((i) => i.name) ?? []}
                autocompleteProps={{
                  readOnly: disabled,
                }}
                textFieldProps={{
                  variant: "standard",
                  error: !!errors.proxy,
                  helperText: errors.proxy?.message ?? " ",
                }}
              />

              <AutocompleteElement
                name="behaviour"
                control={control}
                label={t("behavior")}
                options={configs.get("behaviour")?.map((i) => i.name) ?? []}
                autocompleteProps={{
                  readOnly: disabled,
                }}
                textFieldProps={{
                  variant: "standard",
                  error: !!errors.behaviour,
                  helperText: errors.behaviour?.message ?? " ",
                }}
              />

              <AutocompleteElement
                name="schedule"
                control={control}
                label={t("schedule")}
                options={configs.get("schedule")?.map((i) => i.name) ?? []}
                autocompleteProps={{
                  readOnly: disabled,
                }}
                textFieldProps={{
                  variant: "standard",
                  error: !!errors.schedule,
                  helperText: errors.schedule?.message ?? " ",
                }}
              />

              <AutocompleteElement
                name="gameType"
                control={control}
                label={t("gameType")}
                options={configs.get("game_type")?.map((i) => i.name) ?? []}
                autocompleteProps={{
                  readOnly: disabled,
                }}
                textFieldProps={{
                  variant: "standard",
                  error: !!errors.gameType,
                  helperText: errors.gameType?.message ?? " ",
                }}
              />

              <AutocompleteElement
                name="aiProfile"
                control={control}
                label={t("aiProfile")}
                options={configs.get("ai_profile")?.map((i) => i.name) ?? []}
                autocompleteProps={{
                  readOnly: disabled,
                }}
                textFieldProps={{
                  variant: "standard",
                  error: !!errors.aiProfile,
                  helperText: errors.aiProfile?.message ?? " ",
                }}
              />

              <AutocompleteElement
                name="aiProfileOb"
                control={control}
                label={t("aiProfileOb")}
                options={configs.get("ai_profile")?.map((i) => i.name) ?? []}
                autocompleteProps={{
                  readOnly: disabled,
                }}
                textFieldProps={{
                  variant: "standard",
                  error: !!errors.aiProfileOb,
                  helperText: errors.aiProfileOb?.message ?? " ",
                }}
              />

              <AutocompleteElement
                name="insuranceProfile"
                control={control}
                label={t("insuranceProfile")}
                options={configs.get("insurance")?.map((i) => i.name) ?? []}
                autocompleteProps={{
                  readOnly: disabled,
                }}
                textFieldProps={{
                  variant: "standard",
                  error: !!errors.insuranceProfile,
                  helperText: errors.insuranceProfile?.message ?? " ",
                }}
              />

              <AutocompleteElement
                name="insuranceProfileOb"
                control={control}
                label={t("insuranceProfileOb")}
                options={configs.get("insurance")?.map((i) => i.name) ?? []}
                autocompleteProps={{
                  readOnly: disabled,
                }}
                textFieldProps={{
                  variant: "standard",
                  error: !!errors.insuranceProfileOb,
                  helperText: errors.insuranceProfileOb?.message ?? " ",
                }}
              />
            </>
          )}

          {accountType === "service" && (
            <TextFieldElement
              name="comment"
              control={control}
              label={t("comment")}
              error={!!errors.walletCode}
              helperText={errors.walletCode?.message ?? " "}
              variant="standard"
              InputLabelProps={{ disabled: disabled }}
            />
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button sx={{ width: "100px" }} variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>

        <Button
          sx={{ width: "100px" }}
          variant="contained"
          disabled={disabled || confirmLoading}
          startIcon={confirmLoading ? <CircularProgress size={20} /> : undefined}
          onClick={handleSave}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SysAccountWriteModal;
