import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { IconButton } from "@mui/material";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useJoinClubsMutation } from "@/api/hooks/command-hooks";
import { assembleParticularFilterCt } from "@/lib/cfg-utils";
import JoinClubModal from "@/module/accounts-module/components/join-leave-club-modal";
import { KeyFilter, SysAccountSessionRegularDto } from "@/shared/api";
import { enqueueSnackbar } from "notistack";

const JoinClubsTableControl = ({
  roomName,
  table,
  filters,
  totalElementsCount,
  onSuccess,
}: {
  roomName: string;
  table: MRT_TableInstance<SysAccountSessionRegularDto>;
  filters: Set<KeyFilter>;
  totalElementsCount: number;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const mutation = useJoinClubsMutation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function handleMutationAction(
    rows: MRT_Row<SysAccountSessionRegularDto>[],
    clubs: string[]
  ) {
    if (rows.length > 0) {
      mutateParticular(rows, clubs);
    } else {
      mutateAll(clubs);
    }
  }

  function mutateAll(clubs: string[]) {
    callMutation(filters, clubs);
  }

  function mutateParticular(rows: MRT_Row<SysAccountSessionRegularDto>[], clubs: string[]) {
    callMutation(
      assembleParticularFilterCt({
        rows: rows,
        keyName: "id",
        keySupplier: (val) => val.id,
      }),
      clubs
    );
  }

  function callMutation(filters: Set<KeyFilter>, clubs: string[]) {
    mutation.mutate(
      {
        domain: roomName,
        filters: filters,
        clubs: new Set(clubs),
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("joinSessionsClubsCommandSuccessfullySent"), { variant: "success" });
          setIsOpen(false);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error("join sessions to clubs command error", err);
          enqueueSnackbar(t("somethingWentWrongDuringSessionsBeingJoinedToClubs"), { variant: "error" });
        },
      }
    );
  }

  return (
    <>
      <IconButton
        size="small"
        style={{ fontSize: 12 }}
        color="success"
        onClick={() => setIsOpen(true)}
      >
        <ArrowUpwardIcon />{" "}
        {t("joinClubsLength", {
          length:
            table.getSelectedRowModel().rows.length > 0
              ? table.getSelectedRowModel().rows.length
              : totalElementsCount,
        })}
      </IconButton>
      <JoinClubModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        title={t("joinClub")}
        onSave={(clubIds) => handleMutationAction(table.getSelectedRowModel().rows, clubIds)}
      />
    </>
  );
};

export default JoinClubsTableControl;
