import { CheckCircle, Info } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { Link } from "@tanstack/react-router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";



import { AccountType } from "@/module/accounts-module/accounts-module";
import { ResultDto } from "@/types/commonTypes";


const BulkOperationReportModal = ({
  title = undefined,
  resultList,
  showSuccess = false,
  showProblematic = false,
  open,
  onCancel,
}: {
  title?: string;
  resultList: ResultDto<any>[];
  showSuccess?: boolean;
  showProblematic?: boolean;
  open?: boolean;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const copyToClipboard = (data: string) => {
    navigator.clipboard.writeText(data).then(() => alert(t("textCopied")));
  };

  const successList = resultList.filter((item) => item.status === "SUCCESS");
  const errorList = resultList.filter((item) => item.status === "ERROR");

  const [successListIsShown, setSuccessListIsShown] = useState<boolean>(false);
  const [errorListIsShown, setErrorListIsShown] = useState<boolean>(false);

  const joinListNamesToString = (data: ResultDto<any>[]) => {
    return data.map((proxy) => proxy.name).join("\n");
  };

  return (
    <Dialog open={!!open} onClose={onCancel} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <Divider sx={{ marginBottom: 2 }} />

          {showSuccess && successList.length > 0 && (
            <Button onClick={() => setSuccessListIsShown(!successListIsShown)}>
              {successListIsShown
                ? t("hide")
                : t("showSuccess", { length: successList.length })}
            </Button>
          )}

          {successList.length > 0 && successListIsShown && (
            <List>
              <ListItem>
                <ListItemText
                  primary={t("successElements", { length: successList.length })}
                  secondary={
                    <IconButton
                      onClick={() => copyToClipboard(joinListNamesToString(successList))}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  }
                />
              </ListItem>
              {successList.map((item) => (
                <ListItem key={item.name}>
                  <ListItemText
                    primary={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CheckCircle style={{ color: green[500], marginRight: 8 }} />
                        {item.data?.name ?? item.name}
                      </div>
                    }
                    secondary={
                      <Tooltip title={t("copyValue")}>
                        <IconButton onClick={() => copyToClipboard(item?.name ?? "")}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}

          {showSuccess &&
            showProblematic &&
            successList.length > 0 &&
            errorList.length > 0 && <Divider sx={{ marginTop: 2, marginBottom: 2 }} />}

          {showProblematic && errorList.length > 0 && (
            <Button onClick={() => setErrorListIsShown(!errorListIsShown)}>
              {errorListIsShown
                ? t("hide")
                : t("showProblematic", {
                  length: errorList.length,
                })}
            </Button>
          )}

          {errorList.length > 0 && errorListIsShown && (
            <List>
              <ListItem>
                <ListItemText
                  primary={t("problematicElements", { length: errorList.length })}
                  secondary={
                    <IconButton
                      onClick={() => copyToClipboard(joinListNamesToString(errorList))}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  }
                />
              </ListItem>
              {errorList.map((item) => (
                <ListItem key={item.name}>
                  <ListItemText
                    primary={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Info style={{ color: red[500], marginRight: 8 }} />
                        {item.name}
                      </div>
                    }
                    secondary={
                      <>
                        {item.message}
                        <br />
                        {item.dataOnError &&
                          Object.entries(item.dataOnError).map(
                            ([key, value]: [string, any]) => (
                              <div key={key}>
                                <div>
                                  <strong>{t("collectionName")}</strong> {value.collectionName}
                                </div>
                                <div>
                                  <strong>{t("deletableEntityValue")}</strong>{" "}
                                  {value.foreignKeyValue}
                                </div>
                                <div>
                                  <strong>{t("room")}</strong> {value.roomName}
                                </div>
                                <div>
                                  <strong>{t("amountOfDependencies")}</strong>{" "}
                                  {value.collectionName === "account" ? (
                                    <Link
                                      to="/accounts/$room/$type"
                                      params={{
                                        room: value.roomName,
                                        type: AccountType.regular,
                                      }}
                                      search={{
                                        [value.foreignKeyFieldName]: value.foreignKeyValue,
                                      }}
                                    >
                                      {value.amountOfDependencies}
                                    </Link>
                                  ) : (
                                    "value.amountOfDependencies"
                                  )}
                                </div>
                              </div>
                            )
                          )}
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkOperationReportModal;
