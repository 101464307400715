import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SysCfgRegularDto } from "@/shared/api";

export const useUsernameTableColumns = () => {
  const { t } = useTranslation();

  return useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        id: "data.username",
        header: t("usernameTable.username"),
        accessorKey: "data.username",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        id: "data.is_used",
        header: t("usernameTable.isUsed"),
        accessorKey: "data.is_used",
        accessorFn: (dto) => (dto.data as any)?.is_used.toString() ?? "-",
        sortingFn: "alphanumeric",
        filterFn: "equals",
      },
    ],
    [t]
  );
};
