import { Input } from "@/components/JsonForm/types/base-types";

export type TimePrecision = "hours" | "minutes" | "seconds";

export interface DatePickerType<T> extends Input<T> {
  timePrecision?: TimePrecision;
  displayTimeFormat?: string;
}

export interface TimestampType extends DatePickerType<number> {
  type: "Timestamp";
}

type TimeFormat = "HH:mm:ss" | "HH:mm" | "HH";

export const computeFormatByTimePrecision = (timePrecision?: TimePrecision): TimeFormat => {
  if (timePrecision === "hours") return "HH";
  if (timePrecision === "minutes") return "HH:mm";
  return "HH:mm:ss";
};
