import { useAtom } from "jotai/index";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import UsersListModule from "@/module/users-list-module/users-list-module";
import { headerTitle } from "@/store/store";

const UsersPage = () => {
  const { t } = useTranslation();

  const [, setHeaderTitle] = useAtom(headerTitle);
  useEffect(() => {
    setHeaderTitle(t("users"));
  }, [setHeaderTitle, t]);

  return <UsersListModule />;
};

export default UsersPage;
