import { red } from "@ant-design/colors";
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { useAtom } from "jotai/index";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { isOwner } from "@/lib/auth-predicates";
import { SysCfgCreateRequestDto, SysCfgRegularDto } from "@/shared/api";
import { meStore } from "@/store/store";
import { MakeOptional } from "@/types/commonTypes";

import JsonForm from "../JsonForm/json-form";
import { UiSchema } from "../JsonForm/types/wrapper-types";

const RegularConfigCreateModal = ({
  open,
  uiSchema,
  title,
  onSave,
  onCancel,
  defaultValues,
}: {
  open: boolean;
  uiSchema: UiSchema;
  title?: string;
  onSave?: (data: SysCfgCreateRequestDto) => void;
  onCancel?: () => void;
  defaultValues?: MakeOptional<SysCfgRegularDto, "id" | "created_at" | "updated_at">;
}) => {
  const { t } = useTranslation();
  const [me] = useAtom(meStore);

  const { data: authGroupsRs } = useAuthGroupsQuery({
    enabled: open,
  });

  const authGroupsOptions = useMemo(() => {
    return (
      authGroupsRs?.data
        .map((i) => ({ label: i.name, value: i.name }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [authGroupsRs?.data]);

  const [configName, setConfigName] = useState<string | undefined>(defaultValues?.name);
  const [authGroups, setAuthGroup] = useState<Array<string>>(() => {
    if (authGroupsRs?.data.length === 1 && !isOwner(me)) {
      return authGroupsRs?.data.map((it) => it.name);
    }
    return defaultValues?.auth_groups ? Array.from(defaultValues.auth_groups) : [];
  });
  const [configEnabled, setConfigEnabled] = useState<boolean>(true);

  const [dataForm] = Form.useForm<object>();

  const validate = async () => {
    const result = await Promise.all([validateRootFields(), dataForm.validateFields()]);
    console.log(result);

    return {
      name: configName as string,
      auth_groups: authGroups ? new Set(authGroups) : new Set<string>(),
      enabled: configEnabled,
      data: result[1],
    };
  };

  const validateRootFields = async () => {
    if (configName === undefined || configName.trim() === "")
      return Promise.reject(t("configNameMustNotBeEmpty"));

    if (!isOwner(me) && authGroups.length < 1) {
      return Promise.reject(t("authGroupsFieldRequired"));
    }
    return Promise.resolve();
  };

  function handleSubmitClick() {
    validate()
      .then((value) => onSave && onSave(value))
      .catch((error) => {
        if (typeof error === "string") void message.error(error);
        else if (error instanceof Error)
          void message.error(t("validationErrorMessage", { message: error.message }));
      });
  }

  useEffect(() => {
    if (authGroupsRs?.data.length === 1 && !isOwner(me)) {
      setAuthGroup(authGroupsRs.data.map((it) => it.name));
    }
  }, [authGroupsRs, me, setAuthGroup]);

  if (!open) {
    return <></>;
  }

  return (
    <Modal
      width="80%"
      open={open}
      okText={t("submit")}
      maskClosable={false}
      title={title && <Typography.Title level={3}>{title}</Typography.Title>}
      onCancel={onCancel}
      onOk={() => handleSubmitClick()}
    >
      {title && <Divider style={{ marginTop: 10, marginBottom: 30 }} />}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Typography.Text
            style={{ fontSize: "16px", marginBottom: "10px", display: "block" }}
          >
            <span style={{ color: red.primary }}>*</span>
            &nbsp;{t("name")}:
          </Typography.Text>
          <Input
            value={configName}
            placeholder={t("typeConfigName")}
            onChange={(e) => setConfigName(e.target.value)}
          />
        </Col>

        <Col span={24}>
          <Typography.Text
            style={{ fontSize: "16px", marginBottom: "10px", display: "block" }}
          >
            {!isOwner(me) && <span style={{ color: red.primary }}>*</span>}
            &nbsp;{t("authGroups")}:
          </Typography.Text>
          <Select
            disabled={authGroupsRs?.data.length === 1 && !isOwner(me)}
            options={authGroupsOptions}
            value={authGroups}
            onChange={(e) => setAuthGroup(e)}
            mode="multiple"
            placeholder={t("authGroups")}
            style={{ width: "100%" }}
          />
        </Col>

        <Col span={24}>
          <div style={{ display: "flex" }}>
            <Checkbox
              style={{ width: 25, height: 25 }}
              checked={configEnabled}
              onChange={(e) => setConfigEnabled(e.target.checked)}
            />
            <Typography.Text style={{ fontSize: "16px", display: "block" }}>
              {t("enabled")}
            </Typography.Text>
          </div>
        </Col>

        <Col span={24}>
          <JsonForm ui={uiSchema} form={dataForm} initData={defaultValues?.data} />
        </Col>
      </Row>
    </Modal>
  );
};

export default RegularConfigCreateModal;
