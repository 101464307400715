import { Checkbox } from "@mui/material";
import { MRT_ColumnDef, MRT_TableOptions, useMaterialReactTable } from "material-react-table";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useTableSettings } from "@/hooks/use-table-settings";
import CreateDomainTableControl from "@/module/rooms-management-module/components/create-domain-table-control";
import { SysDomainRegularDto } from "@/shared/api";

const useDomainsListTable = (
  {
    domains,
    refetchDomains,
  }: {
    domains: SysDomainRegularDto[];
    refetchDomains: () => void;
  },
  tableOptions?: Omit<MRT_TableOptions<SysDomainRegularDto>, "columns" | "data">
) => {
  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<SysDomainRegularDto>[]>(
    () => [
      {
        id: "name",
        header: t("name"),
        accessorFn: (row) => row.name,
      },
      {
        id: "is_room",
        header: t("isRoom"),
        accessorFn: (row) => (row.is_room ? "true" : "false"),
        Cell: ({ cell }) => <Checkbox checked={cell.getValue() === "true"} disabled />,
      },
    ],
    [t]
  );

  const { defaultProps, state } = useTableSettings<SysDomainRegularDto>("domainsList");

  return useMaterialReactTable({
    data: domains,
    columns: columns,
    getRowId: (row) => String(row.name),
    ...defaultProps,
    state,
    initialState: {
      density: "compact",
    },
    enableStickyHeader: true,
    renderTopToolbarCustomActions: () => {
      return (
        <div>
          <CreateDomainTableControl onSuccess={() => refetchDomains()} />
        </div>
      );
    },
    ...tableOptions,
  });
};

export default useDomainsListTable;
