import { Button, IconButton } from "@mui/material";
import {
  MRT_TableInstance,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useSysCfgDeleteMutation,
  useSysCfgQuery,
  useSysCfgsCreateMutation,
} from "@/api/hooks/sys-cfg-hooks";
import EmailDomainCreateModal from "@/components/config-modals/email-domain-edit-modal/email-domain-create-modal";
import { useMuiTableToolbarAlertBannerProps } from "@/hooks/use-mui-table-toolbar-alert-banner-props";
import { useTableSettings } from "@/hooks/use-table-settings";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { useEmailDomainTableColumns } from "@/module/service-configs-module/email-domain-module/email-domain-table-columns";
import { SysCfgCreateRequestDto, SysCfgRegularDto } from "@/shared/api";
import { AddOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import Popconfirm from "@/components/popcofirm";

const EmailDomainConfigTable = () => {
  const { t } = useTranslation();
  const {
    data: configsRs,
    isError: isDataLoadingError,
    isLoading: isDataLoading,
    refetch: refetchConfigs,
  } = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES,
    config_name: ROOM_CONFIGS_NAMES.EMAIL_DOMAIN,
  });

  const { mutate: createCfgsMutate } = useSysCfgsCreateMutation();
  //const { mutate: createCfgsMutate } = useSysCfgCreateMutation(); //TODO Remove after recieving access to db
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();

  const [modalMode, setModalMode] = useState<"edit" | "add" | undefined>(undefined);
  const [editItemName, setEditItemName] = useState<string>("");
  const [modalInitValues, setModalInitValues] = useState<SysCfgRegularDto | undefined>(
    undefined
  );

  function handleClickSaveNewConfig(config: SysCfgCreateRequestDto[]) {
    createCfgsMutate(
      {
        domain: ROOM_NAMES.SERVICES,
        cfgName: ROOM_CONFIGS_NAMES.EMAIL_DOMAIN,
        body: config.map((item) => ({ ...item, authGroups: item.auth_groups })),
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("elementsCreated"), { variant: "success" });
          setModalInitValues(undefined);
          setModalMode(undefined);
          setEditItemName("");
          void refetchConfigs();
        },
        onError: (err) => {
          enqueueSnackbar(t("operationFailure", { message: err.message }), {
            variant: "error",
          });
          console.error("Adding config failure", err);
        },
      }
    );
  }

  function handleDeleteConfigs(table: MRT_TableInstance<SysCfgRegularDto>) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate(
      {
        domain: ROOM_NAMES.SERVICES,
        cfgName: ROOM_CONFIGS_NAMES.EMAIL_DOMAIN,
        filters: new Set([
          { key: "name", mode: "STRICT_IN", value: names as unknown as object },
        ]),
      },
      {
        onSuccess: (response) => {
          enqueueSnackbar(
            t("elementsDeleted", {
              data: response.data,
            }),
            {
              variant: "success",
            }
          );
          table.resetRowSelection();
          void refetchConfigs();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailure", {
              message: err.message,
            }),
            {
              variant: "error",
            }
          );
          console.error("Delete operation failure", err);
        },
      }
    );
  }

  const columns = useEmailDomainTableColumns();
  const { defaultProps, columnFilters, grouping, globalFilter, state } =
    useTableSettings<SysCfgRegularDto>("emailSettings");

  const muiToolbarAlertBannerProps = useMuiTableToolbarAlertBannerProps(
    isDataLoadingError,
    configsRs?.statusText
  );

  const table = useMaterialReactTable({
    data: configsRs?.data.content ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    enableRowSelection: true,
    enableGrouping: true,
    muiToolbarAlertBannerProps,
    state: {
      ...state,
      isLoading: isDataLoading,
    },
    ...defaultProps,
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "name", desc: false }],
    },
    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => {
              setModalInitValues(undefined);
              setModalMode("add");
            }}
          >
            <AddOutlined />
            New
          </Button>
          {table.getSelectedRowModel().rows.length > 0 && (
            <div style={{ marginLeft: 30 }}>
              <Popconfirm
                title={t("deleteItems")}
                description={t("deleteItemsConfirm")}
                onConfirm={() => handleDeleteConfigs(table)}
              >
                <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                  <DeleteOutlineOutlined /> {t("delete")}
                </IconButton>
              </Popconfirm>
            </div>
          )}
        </div>
      );
    },
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />
      <EmailDomainCreateModal
        open={!!modalMode}
        onSave={(data) => {
          handleClickSaveNewConfig(data);
        }}
        onCancel={() => {
          setModalMode(undefined);
          setModalInitValues(undefined);
          setEditItemName("");
        }}
      />
    </>
  );
};

export default EmailDomainConfigTable;
