import { Card, Form, FormInstance, Space } from "antd";

import SpecificControl from "./specific-control";
import { Path } from "./types/base-types";
import { GroupType } from "./types/wrapper-types";
import { computeWrapperCardTitle, computeWrapperLabel, computeWrapperTitle } from "./util";

const Group = ({
  control,
  parentPath = [],
  disabled = false,
  form,
}: {
  control: GroupType;
  parentPath?: Path;
  disabled?: boolean;
  form: FormInstance;
}) => {
  const content = (
    <>
      <Form.Item
        label={computeWrapperLabel(control, control.label)}
        style={{ margin: 0 }}
        labelCol={{ span: 24 }}
      >
        {computeWrapperTitle(control.label)}
        <Space direction={control.direction} style={{ width: "100%" }}>
          {control.elements.map((item, index) => (
            <SpecificControl
              disabled={disabled}
              key={index}
              control={item}
              parentPath={[...parentPath, ...(control.path ?? [])]}
              form={form}
            />
          ))}
        </Space>
      </Form.Item>
    </>
  );

  return (
    <>
      {!control.noWrapper ? (
        <Card bordered type="inner" title={computeWrapperCardTitle(control, control.label)}>
          <Space direction="vertical" style={{ width: "100%" }}>
            {content}
          </Space>
        </Card>
      ) : (
        content
      )}
    </>
  );
};

export default Group;
