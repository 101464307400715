import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  dateFilterFn,
  dateFilterProps,
  getDate,
} from "@/lib/common-utils";
import { BoardingTable } from "@/shared/api";

export const useBoardingTablesColumns = () => {
  const { t } = useTranslation();

  return useMemo<MRT_ColumnDef<BoardingTable>[]>(
    () => [
      {
        header: t("boardingTables.room"),
        id: "room",
        accessorFn: (row) => row.room,
      },
      {
        header: t("boardingTables.tid"),
        id: "tid",
        accessorFn: (row) => row.tid,
      },
      {
        header: t("boardingTables.gameType"),
        id: "gameType",
        accessorFn: (row) => row.gameType,
      },
      {
        header: t("boardingTables.gameGroup"),
        id: "gameGroup",
        accessorFn: (row) => row.gameGroup,
      },
      {
        header: t("boardingTables.aid"),
        id: "aid",
        accessorFn: (row) => row.aid,
      },
      {
        header: t("boardingTables.cid"),
        id: "cid",
        accessorFn: (row) => row.cid,
      },
      {
        header: t("boardingTables.limit"),
        id: "limit",
        accessorFn: (row) => row.limit,
      },
      {
        header: t("boardingTables.players"),
        id: "players",
        accessorFn: (row) => row.players.length,
      },
      {
        header: t("boardingTables.lastBoardingTimestamp"),
        id: "lastBoardingTimestamp",
        accessorFn: (row) => getDate(row.lastBoardingTimestamp),
        filterVariant: "datetime-range",
        muiFilterDateTimePickerProps: dateFilterProps,
        filterFn: dateFilterFn,
      },
      {
        header: t("boardingTables.lastExitTime"),
        id: "lastExitTime",
        accessorFn: (row) => getDate(row.lastExitTime),
        filterVariant: "datetime-range",
        muiFilterDateTimePickerProps: dateFilterProps,
        filterFn: dateFilterFn,
      },
      {
        header: t("boardingTables.minHandsAtProfit"),
        id: "minHandsAtProfit",
        accessorFn: (row) => row.minHandsAtProfit,
      },
      {
        header: t("boardingTables.minHandsAfterAllin"),
        id: "minHandsAfterAllin",
        accessorFn: (row) => row.minHandsAfterAllin,
      },
      {
        header: t("boardingTables.tableLeftTimeLimit"),
        id: "tableLeftTimeLimit",
        accessorFn: (row) => row.tableLeftTimeLimit,
      },
      {
        header: t("boardingTables.boardingMode"),
        id: "boardingMode",
        accessorFn: (row) => row.boardingMode,
      },
    ],
    [t]
  );
}; 