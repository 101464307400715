import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import SysAccountWriteModal from "@/module/accounts-module/components/sys-account-write-modal";
import { useSaveNewAccount } from "@/module/accounts-module/hooks/accounts-mutate-hooks";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import { ResultDtoSysAccountRegularDto } from "@/shared/api";
import { Button } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";

const CreateAccountTableControl = ({
  roomName,
  onSuccess,
}: {
  roomName: string;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [operationResult, setOperationResult] = useState<ResultDtoSysAccountRegularDto[]>();


  const { mutate: handleClickSaveNewAccount, isPending: createAccountIsPending } =
    useSaveNewAccount({
      roomName: roomName,
      successCallback: (response) => {
        setIsOpen(false);
        setOperationResult(response?.data)
        onSuccess && onSuccess();
      },
    });

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <AddOutlined /> {t("new")}
      </Button>

      <SysAccountWriteModal
        mode="add"
        accountType="regular"
        isOpen={isOpen}
        roomName={roomName}
        onSave={(name, account) => handleClickSaveNewAccount({ name, ...account })}
        onClose={() => setIsOpen(false)}
        confirmLoading={createAccountIsPending}
      />

      {operationResult != null && (
        <BulkOperationReportModal
          open
          showProblematic
          showSuccess
          title={t("importAccountsTableControl.operationResultTitle")}
          resultList={operationResult}
          onCancel={() => setOperationResult(undefined)}
        />
      )}
    </>
  );
};

export default CreateAccountTableControl;
