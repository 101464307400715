import { Grid } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { useAtom } from "jotai";
import { useEffect } from "react";

import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import MainTable from "@/module/room-configs-module/main-table";
import { appCurrentRoom, headerTitle } from "@/store/store";

const RoomConfigPage = ({ room, config }: { room: string; config: string }) => {
  const [currentRoom] = useAtom(appCurrentRoom);
  const navigate = useNavigate();

  const [, setHeaderTitle] = useAtom(headerTitle);
  useEffect(() => {
    setHeaderTitle(
      config === ROOM_CONFIGS_NAMES.CMS_AGGREGATOR
        ? "Clubs Cms"
        : config
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
    );
  }, [config, setHeaderTitle]);

  useEffect(() => {
    if (!currentRoom) {
      void navigate({ to: "/" });
    } else {
      void navigate({ to: `/room-config/${currentRoom.name}/${config}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoom, navigate]);

  return (
    <>
      <MainTable room={room} cfg={config} />
    </>
  );
};

export default RoomConfigPage;
