import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { enqueueSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import { Controller, ValidateResult, useForm } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";



import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { trimSplitCsvRecord } from "@/lib/common-utils";
import { SysAccountCreateDto } from "@/shared/api";
import { meStore } from "@/store/store";


type CsvDelimiter = "," | ";";

const ImportAccountsDialog = ({
  title,
  onCancel,
  confirmLoading = false,
  onSave,
}: {
  title?: string;
  confirmLoading?: boolean;
  onCancel: () => void;
  onSave: (accounts: SysAccountCreateDto[]) => void;
}) => {
  const { t } = useTranslation();
  const [me] = useAtom(meStore);
  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery();

  /* const authGroupsOptions = useMemo(() => {
     return authGroupsRs?.data
       .map((i) => ({ label: i.name, value: i.name }))
       .sort((a, b) => a.label.localeCompare(b.label)) ?? [];
   }, [authGroupsRs?.data]);*/
  const authGroupsOptions = useMemo(() => {
    return authGroupsRs?.data.map((i) => i.name).sort((a, b) => a.localeCompare(b));
  }, [authGroupsRs?.data]);

  console.log(authGroupsOptions);
  const [csvDelimiter, setCsvDelimiter] = useState<CsvDelimiter>(",");
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<{ authGroups: string; csv: string }>();

  /*useEffect(() => {
    if (authGroupsRs?.data.length === 1 && !isOwner(me)) {
      setValue("authGroups", authGroupsRs?.data.map((it) => it.name));
    }
  }, [authGroupsRs, setValue, me]);

  const formValues = watch();*/

  function handleChangeDelimiter(value: CsvDelimiter) {
    setCsvDelimiter(value);
  }

  function handleSaveClick(data: any) {
    const { csv, authGroups } = data;
    const accounts = convertCsvInputToAccountsCreateObj({ csv }, authGroups);

    if (accounts === null) {
      enqueueSnackbar(t("somethingWentWrongWhileSavingAccounts"), { variant: "error" });
      return;
    }

    onSave(accounts);
  }

  function accountCsvValidator(value: any): ValidateResult | Promise<ValidateResult> {
    if (!value) {
      return t("accountsAreRequired");
    }

    if (typeof value !== "string") {
      return t("accountsMustBeString");
    }

    if (!value.trim()) {
      return t("proxiesRequired");
    }

    const records = value.split("\n");

    for (const record of records) {
      const validationMessage = validateAccountsCsvRecord(record);

      if (validationMessage !== undefined) {
        return t("invalidRecordInInput", {
          validationMessage,
          record,
        });
      }
    }

    return true;
  }

  function validateAccountsCsvRecord(record: string): string | undefined {
    const values = trimSplitCsvRecord(record, csvDelimiter);

    if (!values[0] || values[0] === "") return t("nameCannotBeNullOrEmpty");
    if (!values[1] || values[1] === "") return t("passwordCannotBeBullOrEmpty");
    if (!values[2] || values[2] === "") return t("walletCodeCannotBeNullOrEmpty");
    return undefined;
  }

  function convertCsvInputToAccountsCreateObj(
    input: { csv: string },
    commonAuthGroups: string[]
  ) {
    const records = input.csv.split("\n");
    return records.map((item) => convertCsvRecordToAccountJson(item, commonAuthGroups));
  }

  function convertCsvRecordToAccountJson(
    record: string,
    authGroups: string[]
  ): SysAccountCreateDto {
    const values = trimSplitCsvRecord(record, csvDelimiter);

    return {
      name: values[0],
      password: values[1],
      walletCode: values[2],
      authGroups: new Set(authGroups),
      source: values[3],
      cid: values[4],
      gameGroup: values[5],
      gameType: values[6],
      proxy: values[7],
      behaviour: values[8],
      schedule: values[9],
      aiProfile: values[10],
      aiProfileOb: values[11],
      timingProfile: values[12],
      timingProfileOb: values[13],
      insuranceProfile: values[14],
      insuranceProfileOb: values[15],
      osType: values[16] as unknown as number,
      accountType: values[17] as unknown as number,
    };
  }

  return (
    <Dialog open onClose={onCancel} fullWidth maxWidth="md">
      <DialogTitle>{title && <Typography variant="h6">{title}</Typography>}</DialogTitle>
      <DialogContent>
        <Divider />
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <div>
            <AutocompleteElement
              control={control}
              loading={authGroupsIsLoading}
              name="authGroups"
              multiple
              options={authGroupsOptions ?? []}
              label={t("authGroups")}
              textFieldProps={{
                error: !!errors.authGroups,
                helperText: errors.authGroups?.message ?? " ",
                variant: "standard",
              }}
            />
          </div>

          <div>
            <Typography variant="subtitle1">{t("delimiter")}</Typography>
            <RadioGroup
              row
              value={csvDelimiter}
              onChange={(e) => handleChangeDelimiter(e.target.value as CsvDelimiter)}
            >
              <FormControlLabel value="," control={<Radio />} label="," />
              <FormControlLabel value=";" control={<Radio />} label=";" />
            </RadioGroup>
          </div>

          <div>
            <Typography variant="subtitle1">{t("accounts")}</Typography>
            <Controller
              name="csv"
              control={control}
              rules={{
                required: true,
                validate: accountCsvValidator,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={10}
                  variant="outlined"
                  placeholder={`Name${csvDelimiter}Password${csvDelimiter}Wallet code${csvDelimiter}Source...`}
                  error={!!errors.csv}
                  helperText={errors.csv?.message ? String(errors.csv.message) : " "}
                />
              )}
            />
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          type="submit"
          color="primary"
          onClick={handleSubmit(handleSaveClick)}
          disabled={confirmLoading}
        >
          {confirmLoading ? t("loading") : t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportAccountsDialog;
