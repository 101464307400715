import { MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { APP_GMT_DATE_FORMAT } from "@/lib/constants";
import { SysCfgRegularDto } from "@/shared/api";
import { AnyObj } from "@/types/commonTypes";
import Checkbox from "@mui/material/Checkbox";

export const useReporterRuleTableColumns = ({
  updateConfig,
}: {
  updateConfig: (cfg: SysCfgRegularDto) => void;
}) => {
  const { t } = useTranslation();

  return useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(() => {
    return [
      {
        header: t("enabled"),
        filterFn: "contains",
        enableEditing: false,
        accessorKey: "enabled",
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => <Checkbox checked={row.original.enabled} disabled />,
      },
      {
        header: t("name"),
        accessorKey: "name",
        filterFn: "contains",
      },
      {
        header: t("displayName"),
        accessorKey: "data.display_name",
        filterFn: "contains",
      },
      {
        header: t("pattern"),
        accessorFn: ({ data }) => (data as AnyObj)?.pattern?.join(", ") ?? "-",
        accessorKey: "data.pattern",
        filterFn: "contains",
      },
      {
        header: t("amount"),
        accessorKey: "data.amount",
        filterFn: "contains",
      },
      {
        header: t("limits"),
        accessorFn: ({ data }) => (data as AnyObj)?.limits?.join(", ") ?? "-",
        filterFn: "contains",
      },
      {
        header: t("replay"),
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => (
          <Checkbox
            checked={(row.original.data as AnyObj)?.replay}
            onChange={(e) =>
              updateConfig({
                ...row.original,
                data: { ...row.original.data, replay: e.target.checked },
              })
            }
          />
        ),
      },
      {
        header: t("frequency"),
        accessorKey: "data.frequency",
        filterFn: "contains",
      },
      {
        header: t("createdAt"),
        accessorKey: "created_at",
        accessorFn: (row) => (row.created_at ? new Date(row.created_at) : 0),
        Cell: ({
          row: {
            original: { created_at },
          },
        }) => (created_at ? moment(created_at).utc(false).format(APP_GMT_DATE_FORMAT) : "-"),
      },
      {
        header: t("updatedAt"),
        accessorKey: "updated_at",
        accessorFn: (row) => (row.updated_at ? new Date(row.updated_at) : 0),
        Cell: ({
          row: {
            original: { created_at },
          },
        }) => (created_at ? moment(created_at).utc(false).format(APP_GMT_DATE_FORMAT) : "-"),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
};
