import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { renderClubsCell } from "@/lib/accountsUtils";
import {
  ACCOUNT_STAT_GAME_TYPES,
  ACCOUNT_STAT_GAME_TYPE_KEYS,
  ACCOUNT_STAT_ROW_ID_PREFIX,
} from "@/lib/constants";
import useAccountCommonColumns from "@/module/accounts-module/hooks/account-common-columns-hook";
import { SysAccountRegularDto } from "@/shared/api";
import { MRT_OrderedColumnDef, NilObj } from "@/types/commonTypes";

export const useRegularAccountsTableColumns = ({ room }: { room: string }) => {
  const { t } = useTranslation();

  const gameTypeStats = useMemo<MRT_OrderedColumnDef<SysAccountRegularDto>[]>(
    () =>
      ACCOUNT_STAT_GAME_TYPES.flatMap((gameType) =>
        ACCOUNT_STAT_GAME_TYPE_KEYS.map((stat) => ({
          id: `${ACCOUNT_STAT_ROW_ID_PREFIX}.${gameType}.${stat}`,
          header: `${gameType.toUpperCase()} ${stat.toUpperCase()}`,
          order: 602,
          accessorFn: ({ data }) => (data as any)?.aid_stats?.[gameType]?.[stat] ?? "-",
        }))
      ),
    []
  );

  const commonColumns = useAccountCommonColumns({ room });

  return useMemo<MRT_OrderedColumnDef<SysAccountRegularDto>[]>(
    () => [
      {
        id: "data.pid",
        header: t("regularAccountsTable.pid"),
        order: 399,
        accessorFn: ({ data }) => (data as NilObj)?.pid ?? "-",
      },
      {
        id: "data.aid",
        header: t("regularAccountsTable.aid"),
        order: 401,
        accessorFn: ({ data }) => (data as NilObj)?.aid ?? "-",
      },
      {
        id: "data.nick",
        header: t("regularAccountsTable.nick"),
        order: 501,
        accessorFn: ({ data }) => (data as NilObj)?.nick ?? "-",
      },
      {
        id: "data.clubs",
        header: t("regularAccountsTable.clubs"),
        order: 601,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ row: { original } }) => renderClubsCell(original.data),
      },
      {
        id: "data.alc",
        header: t("regularAccountsTable.alc"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.alc ?? "-",
      },
      {
        id: "data.gold",
        header: t("regularAccountsTable.gold"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.gold ?? "-",
      },
      {
        id: "data.game_group",
        header: t("regularAccountsTable.group"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.game_group ?? "-",
      },
      {
        id: "data.game_type",
        header: t("regularAccountsTable.gameType"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.game_type ?? "-",
      },
      {
        id: "data.proxy",
        header: t("regularAccountsTable.proxy"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.proxy ?? "-",
      },
      {
        id: "data.behaviour",
        header: t("regularAccountsTable.behaviour"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.behaviour ?? "-",
      },
      {
        id: "data.schedule",
        header: t("regularAccountsTable.schedule"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.schedule ?? "-",
      },
      {
        id: "data.ai_profile",
        header: t("regularAccountsTable.aiProfile"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.ai_profile ?? "-",
      },
      {
        id: "data.ai_profile_ob",
        header: t("regularAccountsTable.aiProfileOb"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.ai_profile_ob ?? "-",
      },
      {
        id: "data.timing_profile",
        header: t("regularAccountsTable.timing"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.timing_profile ?? "-",
      },
      {
        id: "data.timing_profile_ob",
        header: t("regularAccountsTable.timingOb"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.timing_profile_ob ?? "-",
      },
      {
        id: "data.insurance_profile",
        header: t("regularAccountsTable.insurance"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.insurance_profile ?? "-",
      },
      {
        id: "data.insurance_profile_ob",
        header: t("regularAccountsTable.insuranceOb"),
        order: 601,
        accessorFn: ({ data }) => (data as NilObj)?.insurance_profile_ob ?? "-",
      },
      ...gameTypeStats,
      ...commonColumns,
    ],
    [commonColumns, gameTypeStats, t]
  );
};
