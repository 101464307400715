import { DataObject, Dataset, DynamicForm, Groups, Home, ManageAccounts } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "@tanstack/react-router";
import { useAtom } from "jotai/index";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useCfgPerm } from "@/auth/hooks";
import { SidebarMenuItemType } from "@/components/sidebar/sidebar-menu-item-type";
import { isOwner, isSuperAdmin } from "@/lib/auth-predicates";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { SysDomainRegularDto } from "@/shared/api";
import { appCurrentRoom, meStore } from "@/store/store";

const useSidebarMenuItems = ({ domains }: { domains: SysDomainRegularDto[] }) => {
  const { t } = useTranslation();
  const [me] = useAtom(meStore);
  const navigate = useNavigate();
  const [selectedRoom] = useAtom(appCurrentRoom);

  const chatsPerms = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_CHAT,
  });

  const rulesPerm = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_RULE,
  });

  const roomTablesPerms = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.ROOM_TABLES_CACHE_ROOM_TABLES,
  });

  const selectedRoomConfigs = useMemo(() => {
    return selectedRoom?.config_names.filter((it) => it !== ROOM_CONFIGS_NAMES.ACCOUNT) ?? [];
  }, [selectedRoom]);

  const accountIsPresent = useMemo(
    () => selectedRoom?.config_names.some((it) => it === ROOM_CONFIGS_NAMES.ACCOUNT) ?? false,
    [selectedRoom]
  );

  const serviceDomain = useMemo(() => {
    return domains.find((it) => it.name === ROOM_NAMES.SERVICES);
  }, [domains]);

  const serviceConfigs = useMemo(() => {
    const resultConfigs = [];

    if (chatsPerms.read || rulesPerm.read) {
      resultConfigs.push(ROOM_CONFIGS_NAMES.PLAYERS_REPORTER);
    }

    if (roomTablesPerms.read) {
      resultConfigs.push(ROOM_CONFIGS_NAMES.TABLE_CACHE);
    }

    const configNames =
      serviceDomain?.config_names
        ?.filter(
          (it) =>
            it !== ROOM_CONFIGS_NAMES.REPORT_CHAT && it !== ROOM_CONFIGS_NAMES.REPORT_RULE
        )
        ?.filter(
          (it) =>
            it !== ROOM_CONFIGS_NAMES.ROOM_TABLES_CACHE_ROOM_TABLES &&
            it !== ROOM_CONFIGS_NAMES.ROOM_TABLES_CACHE_ROOM_INFO
        ) ?? [];

    return [...configNames, ...resultConfigs];
  }, [chatsPerms, roomTablesPerms, rulesPerm, serviceDomain]);

  return useMemo<SidebarMenuItemType[]>(
    () => [
      {
        name: t("home"),
        icon: <Home />,
        link: { to: "/" },
      },
      {
        name: t("accounts"),
        icon: <AccountCircleIcon />,
        link: {
          to: "/accounts",
          isDisabled: true,
        },
        onClick: () =>
          navigate({
            to: "/accounts/$room/$type",
            params: { room: selectedRoom?.name, type: "regular" },
          }),
        isHiddenAccessor: () => !accountIsPresent,
      },
      {
        name: t("roomSettings"),
        icon: <DataObject />,
        link: { to: "/room-config" },
        subItemsAccessor: () =>
          selectedRoomConfigs.map((cfg) => ({
            name: cfg,
            menuItemText: cfg === ROOM_CONFIGS_NAMES.CMS_AGGREGATOR ? "Clubs Cms" : undefined,
            link: {
              to: "/room-config/$roomName/$configName",
              params: { roomName: selectedRoom?.name, configName: cfg },
            },
          })),
        isHiddenAccessor: () => selectedRoomConfigs.length <= 0,
      },
      {
        name: t("services"),
        icon: <SettingsIcon />,
        link: { to: "/service-config" },
        subItemsAccessor: () =>
          serviceConfigs.map((cfg) => {
            return {
              name: t(`servicesMenu.${cfg}`, cfg),
              link: {
                to: "/service-config/$configName",
                params: {
                  configName: cfg,
                },
              },
            };
          }) ?? [],
        isHiddenAccessor: () => !serviceDomain || serviceDomain.config_names.length <= 0,
      },
      {
        name: t("userManagement"),
        link: {
          to: "/users",
        },
        icon: <ManageAccounts />,
        isHiddenAccessor: () => !isOwner(me),
      },
      {
        name: t("authGroups"),
        link: {
          to: "/auth-groups",
        },
        icon: <Groups />,
        isHiddenAccessor: () => !isOwner(me),
      },
      {
        name: t("dashboard"),
        link: {
          to: "/dashboard",
        },
        icon: <DashboardIcon />,
        isHiddenAccessor: () => !isOwner(me),
      },
      {
        name: t("roomManagement"),
        link: {
          to: "/room-management",
        },
        icon: <Dataset />,
        isHiddenAccessor: () => !isSuperAdmin(me),
      },
      {
        name: t("jsonFormManagement"),
        link: {
          to: "/json-form-management",
        },
        icon: <DynamicForm />,
        isHiddenAccessor: () => !isSuperAdmin(me),
      },
    ],
    [
      accountIsPresent,
      me,
      navigate,
      selectedRoom,
      selectedRoomConfigs,
      serviceConfigs,
      serviceDomain,
      t,
    ]
  );
};

export default useSidebarMenuItems;
