import ReplayIcon from "@mui/icons-material/Replay";
import { IconButton } from "@mui/material";
import { MRT_TableInstance } from "material-react-table";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useRequestReplayMutation } from "@/api/hooks/command-hooks";
import RequestReplyModal from "@/module/accounts-module/components/request-replay-modal";
import { SysAccountSessionRegularDto } from "@/shared/api";
import { enqueueSnackbar } from "notistack";

const RequestReplayTableControl = ({
  roomName,
  table,
  onSuccess,
}: {
  roomName: string;
  table: MRT_TableInstance<SysAccountSessionRegularDto>;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const mutation = useRequestReplayMutation();

  function handleMutationAction(tid: string, hid: string) {
    const rows = table.getSelectedRowModel().rows;

    if (rows.length !== 1) {
      enqueueSnackbar(t("moreThanOneRowsSelected"), { variant: "error" });
      return;
    }

    const sessionId = rows[0].original.id;
    let hidNumber;

    try {
      hidNumber = Number.parseInt(hid);
    } catch (e) {
      enqueueSnackbar(t("couldNotFindHidNumber"), { variant: "error" });
      return;
    }

    callMutation(sessionId, tid, hidNumber);
  }

  function callMutation(sessionId: string, tid: string, hid: number) {
    mutation.mutate(
      {
        domain: roomName,
        sessionId: sessionId,
        tid: tid,
        hid: hid,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("requestReplayCommandSuccessfullySent"), { variant: "success" });
          setIsOpen(false);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error("request replay command error", err);
          enqueueSnackbar(t("somethingWentWrongDuringRequestReplay"), { variant: "error" });
        },
      }
    );
  }

  return (
    <>
      <IconButton
        size="small"
        style={{ fontSize: 12 }}
        color="primary"
        disabled={table.getSelectedRowModel().rows.length !== 1}
        onClick={() => setIsOpen(true)}
      >
        <ReplayIcon /> {t("requestReplay")}
      </IconButton>
      <RequestReplyModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        title={t("requestReplay")}
        onSave={(hid, tid) => handleMutationAction(tid, hid)}
      />
    </>
  );
};

export default RequestReplayTableControl;
