import { useState } from "react";

import HeaderedFullHeightContainer from "@/components/headered-full-height-container";
import PhGroupsForm from "@/components/user-management/ph-groups-form";
import SelectRoomFormControl from "@/components/user-management/select-room-form-control";
import { PlayhubRoomDto } from "@/shared/api";

const PhRoomsAccessForm = ({
  name = "",
  rooms,
  onSelectColumn,
  onClearColumn,
  onSelectRow,
  onClearRow,
  onSelectAll,
  onClearAll,
}: {
  name?: string;
  rooms: PlayhubRoomDto[];
  onSelectColumn?: (roomName: string, column: string) => void;
  onClearColumn?: (roomName: string, column: string) => void;
  onSelectRow?: (roomName: string, configName: string) => void;
  onClearRow?: (roomName: string, configName: string) => void;
  onSelectAll?: (roomName: string) => void;
  onClearAll?: (roomName: string) => void;
}) => {
  const [selectedRoom, setSelectedRoom] = useState<string>("");

  return (
    <HeaderedFullHeightContainer
      header={
        <SelectRoomFormControl
          rooms={rooms.map((i) => i.room_name)}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
        />
      }
    >
      <>
        {rooms
          .filter((i) => i.room_name === selectedRoom)
          .map((room) => {
            const roomIndex = rooms.findIndex(r => r.room_name === room.room_name);
            return (
              <PhGroupsForm
                key={room.room_name}
                groups={room.groups}
                name={`${name}.${roomIndex}.groups`}
                onSelectColumn={(clm) => onSelectColumn && onSelectColumn(room.room_name, clm)}
                onClearColumn={(clm) => onClearColumn && onClearColumn(room.room_name, clm)}
                onSelectRow={(cfg) => onSelectRow && onSelectRow(room.room_name, cfg)}
                onClearRow={(cfg) => onClearRow && onClearRow(room.room_name, cfg)}
                onSelectAll={() => onSelectAll && onSelectAll(room.room_name)}
                onClearAll={() => onClearAll && onClearAll(room.room_name)}
              />
            );
          })}
      </>
    </HeaderedFullHeightContainer>
  );
};

export default PhRoomsAccessForm;
