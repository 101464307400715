import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { ClubRegularDto } from "@/shared/api";
import { useApi } from "@/shared/api/use-api";

export const useSysDataClubsQuery = (
  {
    domain,
  }: {
    domain: string;
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<ClubRegularDto[]>>, "queryKey">
) => {
  const { sysDataCommonApi } = useApi();

  return useQuery({
    queryKey: [`/sys-data/${domain}/clubs`],
    queryFn: ({ signal }) => sysDataCommonApi.getClubs(domain, { signal }),
    ...queryOptions,
  });
};
