import { createFileRoute } from "@tanstack/react-router";

import RoomConfigPage from "@/pages/room-config-page";

export const Route = createFileRoute("/room-config/$room/$configName")({
  component: () => <Component />,
});

function Component() {
  const { room, configName } = Route.useParams();

  return <RoomConfigPage room={room} config={configName} />;
}
