import { HourglassTop, Stop } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import React from "react";
import { useTranslation } from "react-i18next";

import { useStopSessionsMutation } from "@/api/hooks/command-hooks";
import { assembleParticularFilterCt } from "@/lib/cfg-utils";
import { KeyFilter, SysAccountSessionRegularDto } from "@/shared/api";
import { enqueueSnackbar } from "notistack";
import Popconfirm from "@/components/popcofirm";

const StopSessionsTableControl = ({
  roomName,
  table,
  filters,
  totalElementsCount,
  onSuccess,
}: {
  roomName: string;
  table: MRT_TableInstance<SysAccountSessionRegularDto>;
  filters: Set<KeyFilter>;
  totalElementsCount: number;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();

  const mutation = useStopSessionsMutation();

  function handleMutationAction(rows: MRT_Row<SysAccountSessionRegularDto>[]) {
    if (rows.length > 0) {
      mutateParticular(rows);
    } else {
      mutateAll();
    }
  }

  function mutateAll() {
    callMutation(filters);
  }

  function mutateParticular(rows: MRT_Row<SysAccountSessionRegularDto>[]) {
    callMutation(
      assembleParticularFilterCt({
        rows: rows,
        keyName: "id",
        keySupplier: (val) => val.id,
      })
    );
  }

  function callMutation(filters: Set<KeyFilter>) {
    mutation.mutate(
      { domain: roomName, filters },
      {
        onSuccess: () => {
          enqueueSnackbar(t("stopSessionsCommandSuccessfullySent"), { variant: "success" });
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.error("stop sessions command error", err);
          enqueueSnackbar(t("somethingWentWrongDuringSessionsStopped"), { variant: "error" });
        },
      }
    );
  }

  return (
    <>
      <Popconfirm
        title={t("stopSessions")}
        description={t("areYouSureStopSessions")}
        onConfirm={() => handleMutationAction(table.getSelectedRowModel().rows)}
      >
        <IconButton size="small" style={{ fontSize: 12 }} color="error">
          {mutation.isPending ? <HourglassTop /> : <Stop />}{" "}
          {t("stopLength", {
            length:
              table.getSelectedRowModel().rows.length > 0
                ? table.getSelectedRowModel().rows.length
                : totalElementsCount,
          })}
        </IconButton>
      </Popconfirm>
    </>
  );
};

export default StopSessionsTableControl;
