import { Grid } from "@mui/material";
import { useAtom } from "jotai/index";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import RunningServicesModule from "@/module/running-services-module/running-services-module";
import { headerTitle } from "@/store/store";

const DashboardPage = () => {
  const { t } = useTranslation();

  const [, setHeaderTitle] = useAtom(headerTitle);
  useEffect(() => {
    setHeaderTitle(t("services"));
  }, [setHeaderTitle, t]);

  return (
    <>
      <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
        <Grid item xs={10}>
          <RunningServicesModule />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardPage;
