import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { getPublicUrl } from "../lib/common-utils";

const AError = ({ errorMessage }: { errorMessage?: string }) => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%", maxWidth: "500px", margin: "0 auto" }}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={`${getPublicUrl()}/error_logo.png`}
              alt="error"
              width="300px"
              style={{ margin: "0 auto" }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            {errorMessage ? errorMessage : t("somethingWentWrong")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default AError;