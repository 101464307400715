import { red } from "@ant-design/colors";
import { Form, FormInstance, Space } from "antd";
import { isEmpty } from "lodash";
import React from "react";

import "./index.css";
import SpecificControl from "./specific-control";
import { UiSchema } from "./types/wrapper-types";
import { calculateWrapperInitialValues } from "./util";

const JsonForm = ({
  ui,
  form,
  initData,
  schema,
  disabled,
}: {
  ui: UiSchema;
  form: FormInstance;
  initData?: object;
  schema?: object;
  disabled?: boolean;
}) => {
  const requiredMark = (labelNode: React.ReactNode, info: { required: boolean }) => {
    const node: any = labelNode;
    if (info.required) {
      const span = <span style={{ color: red.primary }}>*</span>;

      if (node.props?.className?.includes("right-required-mark")) {
        return (
          <>
            {labelNode}&nbsp;{span}
          </>
        );
      }

      return (
        <>
          {span}&nbsp;{labelNode}
        </>
      );
    } else {
      return labelNode;
    }
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      className="json-form a-common-form"
      autoComplete="off"
      size="large"
      initialValues={
        initData && !isEmpty(initData) ? initData : calculateWrapperInitialValues(ui)
      }
      requiredMark={requiredMark}
      disabled={disabled}
    >
      <Space direction={ui.direction} style={{ width: "100%" }}>
        {ui.elements.map((item, index) => (
          <SpecificControl key={index} control={item} disabled={disabled} form={form} />
        ))}
      </Space>
    </Form>
  );
};

export default JsonForm;
