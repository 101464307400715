import { ContentCopy } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { MRT_TableInstance } from "material-react-table";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import SysAccountWriteModal from "@/module/accounts-module/components/sys-account-write-modal";
import { useSaveNewAccount } from "@/module/accounts-module/hooks/accounts-mutate-hooks";
import { ResultDtoSysAccountRegularDto, SysAccountRegularDto } from "@/shared/api";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";

const CopyServiceAccountTableControl = ({
  roomName,
  table,
  onSuccess,
}: {
  roomName: string;
  table: MRT_TableInstance<SysAccountRegularDto>;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [operationResult, setOperationResult] = useState<ResultDtoSysAccountRegularDto[]>();

  const [account, setAccount] = useState<SysAccountRegularDto | undefined>(undefined);

  function handleCopyClick() {
    const account = table.getSelectedRowModel().rows.map((item) => item.original)[0];
    setIsOpen(true);
    setAccount({ ...account, name: "" });
  }

  const { mutate: handleClickSaveNewAccount, isPending: createAccountIsPending } =
    useSaveNewAccount({
      roomName: roomName,
      successCallback: (response) => {
        setIsOpen(false);
        setOperationResult(response?.data)
        onSuccess && onSuccess();
      },
    });

  return (
    <>
      <IconButton
        size="small"
        style={{ fontSize: 12 }}
        color="warning"
        disabled={table.getSelectedRowModel().rows.length !== 1}
        onClick={() => handleCopyClick()}
      >
        <ContentCopy /> {t("copy")}
      </IconButton>
      <SysAccountWriteModal
        mode="add"
        accountType="service"
        isOpen={isOpen}
        roomName={roomName}
        defaultValues={account}
        onSave={(name, account) => handleClickSaveNewAccount({ name, ...account })}
        onClose={() => setIsOpen(false)}
        confirmLoading={createAccountIsPending}
      />

      {operationResult != null && (
        <BulkOperationReportModal
          open
          showProblematic
          showSuccess
          title={t("importAccountsTableControl.operationResultTitle")}
          resultList={operationResult}
          onCancel={() => setOperationResult(undefined)}
        />
      )}
    </>
  );
};

export default CopyServiceAccountTableControl;
