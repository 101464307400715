import { Avatar } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { renderClubsCell } from "@/lib/accountsUtils";
import { SysAccountSessionRegularDto } from "@/shared/api";
import { NilObj } from "@/types/commonTypes";

const useSessionsColumns = ({ roomName }: { roomName: string }) => {
  const { t } = useTranslation();

  return useMemo<MRT_ColumnDef<SysAccountSessionRegularDto>[]>(
    () => [
      {
        id: "$.avatar",
        header: t("sessionColumns.avatar"),
        columnFilterModeOptions: ["contains", "empty", "notEmpty"],
        accessorFn: (row) => row.avatar ?? "-",
        Cell: ({ row }) => <Avatar alt={row.original.nick ?? "?"} src={row.original.avatar} />,
      },
      {
        id: "$.username",
        header: t("sessionColumns.username"),
        accessorFn: (row) => row.username ?? "-",
      },
      {
        id: "$.pid",
        header: t("sessionColumns.pid"),
        accessorFn: (row) => row.pid ?? "-",
      },
      {
        id: "$.nick",
        header: t("sessionColumns.nick"),
        accessorFn: (row) => row.nick ?? "-",
      },
      {
        id: "$.account.data.clubs",
        header: t("sessionColumns.clubs"),
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ row: { original } }) => renderClubsCell(original.account?.data),
      },
      {
        id: "$.cid",
        header: t("sessionColumns.cid"),
        accessorFn: (row) => row.cid ?? "-",
      },
      {
        id: "$.aid",
        header: t("sessionColumns.aid"),
        accessorFn: (row) => row.aid ?? "-",
      },
      {
        id: "$.game_info.tid",
        header: t("sessionColumns.tid"),
        accessorFn: (row) => row.game_info?.tid ?? "-",
      },
      {
        id: "$.account.data.alc",
        header: t("sessionColumns.alc"),
        accessorFn: ({ account }) => (account?.data as NilObj)?.alc ?? "-",
      },
      {
        id: "$.gold",
        header: t("sessionColumns.gold"),
        accessorFn: (row) => row.gold ?? "-",
      },
      {
        id: "$.game_info.name",
        header: t("sessionColumns.tableName"),
        accessorFn: (row) => row.game_info?.name ?? "-",
      },
      {
        id: "$.game_info.type",
        header: t("sessionColumns.gameType"),
        accessorFn: (row) => row.game_info?.type ?? "-",
      },
      {
        id: "$.game_info.limit",
        header: t("sessionColumns.tableLimit"),
        accessorFn: (row) => row.game_info?.limit ?? "-",
      },
      {
        id: "$.game_info.hands",
        header: t("sessionColumns.tableHands"),
        accessorFn: (row) => row.game_info?.hands ?? "-",
      },
      {
        id: "$.game_info.result",
        header: t("sessionColumns.tableResult"),
        accessorFn: (row) => row.game_info?.result ?? "-",
      },
      {
        id: "$.current_stats.vpip",
        header: t("sessionColumns.vpip"),
        accessorFn: (row) => row.current_stats?.vpip ?? "-",
      },
      {
        id: "$.current_stats.pfr",
        header: t("sessionColumns.pfr"),
        accessorFn: (row) => row.current_stats?.pfr ?? "-",
      },
      {
        id: "$.current_stats.hands",
        header: t("sessionColumns.hands"),
        accessorFn: (row) => row.current_stats?.hands ?? "-",
      },
      {
        id: "$.proxy",
        header: t("sessionColumns.proxy"),
        accessorFn: (row) => row.proxy ?? "-",
      },
      {
        id: "$.schedule",
        header: t("sessionColumns.schedule"),
        accessorFn: (row) => row.schedule ?? "-",
      },
      {
        id: "$.gs_instance.id",
        header: t("sessionColumns.gs"),
        accessorFn: (row) => row.gs_instance?.id ?? "-",
      },
      {
        id: "$.gs_instance.version",
        header: t("sessionColumns.gsVersion"),
        accessorFn: (row) => row.gs_instance?.version ?? "-",
      },
      {
        id: "$.gs_instance.mode",
        header: t("sessionColumns.gsMode"),
        accessorFn: (row) => row.gs_instance?.mode ?? "-",
      },
      {
        id: "$.tes_instance.id",
        header: t("sessionColumns.tes"),
        accessorFn: (row) => row.tes_instance?.id ?? "-",
      },
      {
        id: "$.tes_instance.version",
        header: t("sessionColumns.tesVersion"),
        accessorFn: (row) => row.tes_instance?.version ?? "-",
      },
      {
        id: "$.tes_instance.mode",
        header: t("sessionColumns.tesMode"),
        accessorFn: (row) => row.tes_instance?.mode ?? "-",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, roomName]
  );
};

export default useSessionsColumns;
