import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Select as FSelect,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BulkEditFormFieldValue } from "@/types/commonTypes";

import { Input, Select, TextInput } from "./types";

interface BulkEditModalProps extends Omit<DialogProps, "children" | "onSubmit"> {
  title?: string;
  onClose?: () => void;
  fields: Input[];
  isLoading?: boolean;
  onSubmit?: (data: Map<string, BulkEditFormFieldValue>) => void;
  editElementsCount?: number;
}

const fillToggles = (fields: Input[]) =>
  new Map<string, boolean>(fields.map((item) => [item.key, false]));
const fillFieldValues = (fields: Input[]) =>
  new Map<string, BulkEditFormFieldValue>(fields.map((item) => [item.key, null]));

const BulkEditModal = ({
  title,
  fields,
  open,
  onSubmit,
  isLoading,
  onClose,
  editElementsCount,
  ...dialogProps
}: BulkEditModalProps) => {
  const { t } = useTranslation();
  const [toggles, setToggles] = useState<Map<string, boolean>>(() => fillToggles(fields));
  const [fieldValues, setFieldValues] = useState<Map<string, BulkEditFormFieldValue>>(() =>
    fillFieldValues(fields)
  );
  const setToggle = (key: string, value: boolean) => {
    const toggleValue = toggles.get(key);

    if (toggleValue === undefined) return;

    const newToggles = new Map(toggles);
    newToggles.set(key, value);

    setToggles(newToggles);

    if (!value) setFieldValue(key, null);
  };
  const setFieldValue = (key: string, value: BulkEditFormFieldValue) => {
    const fieldValue = fieldValues.get(key);

    if (fieldValue === undefined) return;

    const newFieldValues = new Map(fieldValues);
    newFieldValues.set(key, value);

    setFieldValues(newFieldValues);
  };

  useEffect(() => {
    setToggles(fillToggles(fields));
  }, [fields]);
  useEffect(() => {
    setFieldValues(fillFieldValues(fields));
  }, [fields]);

  function handleSubmit() {
    if (!onSubmit || isLoading) return;

    const result = new Map<string, BulkEditFormFieldValue>();

    Array.from(fieldValues).forEach(([key, value]) => {
      let valueIsSat = value !== null;

      if (typeof value === "string") {
        valueIsSat = valueIsSat && value.trim() !== "";
      }

      if (toggles.get(key)) {
        result.set(key, valueIsSat ? value : null);
      }
    });

    onSubmit(result);
  }

  return (
    <>
      <Dialog open={open} {...dialogProps}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            {fields.map((item, index) => (
              <Grid key={index} item xs>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs>
                    {item instanceof TextInput && (
                      <TextField
                        margin="dense"
                        name={item.key}
                        disabled={!toggles.get(item.key)}
                        label={item.placeholder}
                        value={fieldValues.get(item.key) || ""}
                        onChange={(e) => setFieldValue(item.key, e.target.value)}
                        fullWidth
                        variant={toggles.get(item.key) ? "outlined" : "filled"}
                      />
                    )}
                    {item instanceof Select && (
                      <FormControl fullWidth>
                        <InputLabel id={`input-label-${item.key}`}>
                          {item.placeholder}
                        </InputLabel>
                        <FSelect
                          margin="dense"
                          labelId={`input-label-${item.key}`}
                          name={item.key}
                          disabled={!toggles.get(item.key)}
                          value={fieldValues.get(item.key) || ""}
                          onChange={(e) => setFieldValue(item.key, e.target.value)}
                          fullWidth
                          label={item.placeholder}
                          variant={toggles.get(item.key) ? "outlined" : "filled"}
                        >
                          {item.nullable && (
                            <MenuItem value="">
                              <em>{t("setNull")}</em>
                            </MenuItem>
                          )}
                          {item.options.map((opt, idx) => (
                            <MenuItem value={opt.value} key={idx}>
                              {opt.title}
                            </MenuItem>
                          ))}
                        </FSelect>
                      </FormControl>
                    )}
                  </Grid>

                  <Grid item>
                    <Switch
                      checked={
                        toggles.get(item.key) === undefined ? false : toggles.get(item.key)
                      }
                      onChange={(_e, checked) => setToggle(item.key, checked)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose && onClose()} variant="outlined">
            {t("cancel")}
          </Button>
          {isLoading ? (
            <Button variant="contained" disabled>
              {t("submit")} &nbsp;
              <CircularProgress color="inherit" size={20} />
            </Button>
          ) : (
            <Button variant="contained" onClick={() => handleSubmit()}>
              {t("submit")}
              {editElementsCount !== undefined && `(${editElementsCount})`}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkEditModal;
