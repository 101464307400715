import { FileDownload } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import React from "react";
import { useTranslation } from "react-i18next";

import { useSysAccountsExportCsvMutation } from "@/api/hooks/sys-account-hooks";
import { assembleParticularFilter } from "@/lib/cfg-utils";
import { KeyFilter, SysAccountRegularDto } from "@/shared/api";
import { enqueueSnackbar } from "notistack";

const ExportAccountsCsvTableControl = ({
  roomName,
  table,
  filters,
  totalElementsCount,
}: {
  roomName: string;
  table: MRT_TableInstance<SysAccountRegularDto>;
  filters: Set<KeyFilter>;
  totalElementsCount: number;
}) => {
  const { t } = useTranslation();
  const mutation = useSysAccountsExportCsvMutation();

  function handleMutationAction(rows: MRT_Row<SysAccountRegularDto>[]) {
    if (rows.length > 0) {
      mutateParticular(rows);
    } else {
      mutateAll();
    }
  }

  function mutateAll() {
    callMutation(filters);
  }

  function mutateParticular(rows: MRT_Row<SysAccountRegularDto>[]) {
    callMutation(assembleParticularFilter(rows));
  }

  function callMutation(filters: Set<KeyFilter>) {
    mutation.mutate(
      { domain: roomName, filters },
      {
        onError: (err) => {
          console.error("accounts csv export error", err);
          enqueueSnackbar(t("somethingWentWrongDuringExporting"), { variant: "error" });
        },
      }
    );
  }

  return (
    <IconButton
      size="small"
      style={{ fontSize: 12 }}
      color="secondary"
      onClick={() => handleMutationAction(table.getSelectedRowModel().rows)}
    >
      <FileDownload />{" "}
      {t("csvLength", {
        length:
          table.getSelectedRowModel().rows.length > 0
            ? table.getSelectedRowModel().rows.length
            : totalElementsCount,
      })}
    </IconButton>
  );
};

export default ExportAccountsCsvTableControl;
