import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import SelectClearAllControl from "@/components/select-clear-all-control";
import SelectClearAllRowControl from "@/components/select-clear-all-row-control";
import { useTableSettings } from "@/hooks/use-table-settings";
import { PlayhubGroupDto } from "@/shared/api";

import { createCheckboxColumn } from "./util";

const PhGroupsForm = ({
  name = "",
  groups,
  onSelectColumn,
  onClearColumn,
  onSelectRow,
  onClearRow,
  onSelectAll,
  onClearAll,
}: {
  name?: string;
  groups: PlayhubGroupDto[];
  onSelectColumn?: (column: string) => void;
  onClearColumn?: (column: string) => void;
  onSelectRow?: (groupName: string) => void;
  onClearRow?: (groupName: string) => void;
  onSelectAll?: () => void;
  onClearAll?: () => void;
}) => {
  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<PlayhubGroupDto>[]>(
    () => [
      {
        header: t("groupName"),
        accessorFn: (row) => row.name,
      },
      createCheckboxColumn<PlayhubGroupDto, "room_read">({
        columnName: "room_read",
        headerText: t("roomRead"),
        formInputName: (row) => `${name}.${row.index}.room_read`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<PlayhubGroupDto, "chat_read">({
        columnName: "chat_read",
        headerText: t("chatRead"),
        formInputName: (row) => `${name}.${row.index}.chat_read`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<PlayhubGroupDto, "chat_write">({
        columnName: "chat_write",
        headerText: t("chatWrite"),
        formInputName: (row) => `${name}.${row.index}.chat_write`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<PlayhubGroupDto, "max_bot_read">({
        columnName: "max_bot_read",
        headerText: t("maxBotRead"),
        formInputName: (row) => `${name}.${row.index}.max_bot_read`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<PlayhubGroupDto, "max_bot_write">({
        columnName: "max_bot_write",
        headerText: t("maxBotWrite"),
        formInputName: (row) => `${name}.${row.index}.max_bot_write`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<PlayhubGroupDto, "bot_execute_leave">({
        columnName: "bot_execute_leave",
        headerText: t("botExecuteLeave"),
        formInputName: (row) => `${name}.${row.index}.bot_execute_leave`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<PlayhubGroupDto, "bot_execute_manually_boarding">({
        columnName: "bot_execute_manually_boarding",
        headerText: t("botExecuteManuallyBoarding"),
        formInputName: (row) => `${name}.${row.index}.bot_execute_manually_boarding`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<PlayhubGroupDto, "game_read">({
        columnName: "game_read",
        headerText: t("gameRead"),
        formInputName: (row) => `${name}.${row.index}.game_read`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<PlayhubGroupDto, "game_write">({
        columnName: "game_write",
        headerText: t("gameWrite"),
        formInputName: (row) => `${name}.${row.index}.game_write`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
    ],
    [t, name, onSelectColumn, onClearColumn]
  );

  const { defaultProps, state } = useTableSettings<PlayhubGroupDto>("userPhGroup");

  const table = useMaterialReactTable({
    data: groups,
    columns,
    enableColumnActions: true,
    enableRowActions: true,
    getRowId: (originalRow) => originalRow.name,
    enableStickyHeader: true,
    enablePagination: false,
    enableGrouping: true,
    enableColumnFilterModes: true,
    enableColumnDragging: false,
    ...defaultProps,
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "name", desc: false }],
    },
    state,
    renderTopToolbarCustomActions: () => (
      <div>
        <SelectClearAllControl onSelectAll={onSelectAll} onClearAll={onClearAll} />
      </div>
    ),
    renderRowActions: ({ row: { original } }) => (
      <SelectClearAllRowControl
        onSelectRow={onSelectRow}
        onClearRow={onClearRow}
        name={original.name}
      />
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default PhGroupsForm;
