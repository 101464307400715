import { Alert, Box, Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { TextFieldElement, useForm } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import z from "zod";

const DeleteConfigModal = ({
  open,
  configName,
  onClose,
  confirmIsLoading,
  onConfirm,
}: {
  open: boolean;
  configName: string;
  onClose: () => void;
  confirmIsLoading: boolean;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      z.object({
        confirmName: z.string(),
      }),
    []
  );

  type FormType = z.infer<typeof schema>;

  const form = useForm<FormType>({
    mode: "onChange",
    defaultValues: {
      confirmName: "",
    },
  });

  const confirmName = form.watch("confirmName");

  const handleSubmit = form.handleSubmit(() => onConfirm());

  useEffect(() => {
    form.reset();
  }, [open, form]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Box>
          <form onSubmit={() => handleSubmit()} noValidate>
            <Stack spacing={2}>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {t("deleteConfigName", { configName })}
              </Typography>
              <Typography variant="caption" style={{ marginTop: 0 }}>
                {t("areYouSureToDeleteConfig", { configName })}
              </Typography>
              <br />
              <span style={{ fontWeight: "bold" }}>
                {t("typeConfirmAction", { configName })}
              </span>
              <TextFieldElement control={form.control} name="confirmName" fullWidth />
              <Alert severity="warning">{t("configWillBeDeleted", { configName })}</Alert>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={confirmIsLoading || confirmName !== configName}
              >
                {t("deleteConfig")}
              </Button>
            </Stack>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfigModal;
