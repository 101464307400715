import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";


const JoinLeaveClubModal = ({
  title,
  onCancel,
  confirmLoading = false,
  onSave,
  isOpen,
}: {
  title?: string;
  confirmLoading?: boolean;
  onCancel: () => void;
  onSave: (clubIds: string[]) => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const handleSaveClick = (data: any) => {
    if (!data.clubIds || data.clubIds.length === 0) {
      enqueueSnackbar(t("fieldRequired"), { variant: "error" });
      return;
    }
    onSave(data.clubIds);
  };

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>
        {title && (
          <Typography variant="h6" style={{ marginTop: 15 }}>
            {title}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        {title && <Divider style={{ marginTop: 10, marginBottom: 30 }} />}
        <form onSubmit={handleSubmit(handleSaveClick)} autoComplete="off" key="joinClubForm">
          <AutocompleteElement
            name="clubIds"
            control={control}
            options={[]}
            multiple
            rules={{
              required: t("fieldRequired"),
              validate: (v) =>
                v.filter((a: number) => isNaN(a)).length === 0 || t("enterOnlyNumber"),
              pattern: {
                value: /^[0-9]+$/,
                message: t("enterOnlyNumber"),
              },
            }}
            label={t("clubId")}
            textFieldProps={{
              placeholder: t("enterHere"),
              variant: "standard",
              error: !!errors.clubIds,
              helperText: errors.clubIds?.message ? String(errors.clubIds?.message) : " ",
            }}
          />

          <DialogActions>
            <Button onClick={onCancel}>{t("cancel")}</Button>
            <Button type="submit" disabled={confirmLoading}>
              {t("submit")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default JoinLeaveClubModal;