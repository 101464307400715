import dayjs from "dayjs";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  dateFilterFn,
  dateFilterProps,
  durationFilterFn,
  getDate,
  timeFilterProps,
} from "@/lib/common-utils";
import { RoomTablesCacheTableDto } from "@/shared/api";

const getTechnology = (row: RoomTablesCacheTableDto, type: string[]) => {
  return row.players?.filter((player) => {
    const tech = player.technology?.toLocaleLowerCase();
    return type.some((t) => tech?.includes(t.toLocaleLowerCase()));
  }).length;
};

export const useTableCacheTableColumns = () => {
  const { t } = useTranslation();

  return useMemo<MRT_ColumnDef<RoomTablesCacheTableDto>[]>(
    () => [
      {
        id: "pid",
        header: t("tableCache.gameType"),
        accessorKey: "gameType",
      },
      {
        header: t("tableCache.aid"),
        id: "aid",
        accessorKey: "aid",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("tableCache.gameGroups"),
        id: "gameGroups",
        accessorKey: "groups",
        accessorFn: (row) => (row.groups ? row.groups.join(", ") : "-"),
      },
      {
        header: t("tableCache.tid"),
        id: "tid",
        accessorKey: "tid",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("tableCache.name"),
        id: "name",
        accessorKey: "name",
      },
      {
        header: t("tableCache.bb"),
        id: "bb",
        accessorKey: "bigBlind",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("tableCache.ante"),
        id: "ante",
        accessorKey: "ante",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("tableCache.st"),
        id: "st",
        accessorKey: "straddle",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("tableCache.tableSize"),
        id: "tableSize",
        accessorKey: "maxPlayers",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("tableCache.players"),
        id: "players",
        accessorKey: "numPlayers",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("tableCache.observers"),
        id: "observers",
        accessorKey: "observers.length",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("tableCache.ipRestriction"),
        id: "ipRestriction",
        accessorKey: "ipRestriction",
        accessorFn: (row) => (row.ipRestriction ? "True" : "False"),
      },
      {
        header: t("tableCache.eco"),
        id: "eco",
        accessorKey: "",
        accessorFn: (row) => getTechnology(row, ["ECO"]),
      },
      {
        header: t("tableCache.adv"),
        id: "ADV",
        accessorKey: "",
        accessorFn: (row) => getTechnology(row, ["ADV"]),
      },
      {
        header: t("tableCache.aIcon"),
        id: "AIcon",
        accessorKey: "",
        accessorFn: (row) => getTechnology(row, ["AIcon"]),
      },
      {
        header: t("tableCache.our"),
        id: "our",
        accessorKey: "",
        accessorFn: (row) => getTechnology(row, ["ECO", "ADV", "AIcon"]),
      },
      {
        header: t("tableCache.other"),
        id: "other",
        accessorKey: "",
        accessorFn: (row) => getTechnology(row, ["unknown", "other"]),
      },
      // {
      //   header: "Last update",
      //   id: "lastUpdate",
      //   accessorKey: "lastUpdate",
      // },
      {
        header: t("tableCache.created"),
        id: "createdAt",
        accessorKey: "createdAt",
        accessorFn: (row) => getDate(row.createdAt),
        filterVariant: "datetime-range",
        muiFilterDateTimePickerProps: dateFilterProps,
        filterFn: dateFilterFn,
      },
      {
        header: t("tableCache.started"),
        id: "startedAt",
        accessorKey: "startedAt",
        accessorFn: (row) => getDate(row.startedAt),
        filterVariant: "datetime-range",
        muiFilterDateTimePickerProps: dateFilterProps,
        filterFn: dateFilterFn,
      },
      {
        header: t("tableCache.finished"),
        id: "finishedAt",
        accessorKey: "finishedAt",
        accessorFn: (row) => getDate(row.finishedAt),
        filterVariant: "datetime-range",
        muiFilterDateTimePickerProps: dateFilterProps,
        filterFn: dateFilterFn,
      },
      {
        header: t("tableCache.duration"),
        id: "duration",
        accessorKey: "duration",
        filterVariant: "time-range",
        muiFilterTimePickerProps: timeFilterProps,
        accessorFn: (row) =>
          row.startedAt && row.finishedAt
            ? dayjs.duration(row.finishedAt - row.startedAt, "milliseconds").format("HH:mm")
            : "-",
        filterFn: durationFilterFn,
      },
      {
        header: t("tableCache.lastUpdate"),
        id: "timestamp",
        accessorKey: "timestamp",
        accessorFn: (row) => getDate(row.timestamp),
        filterVariant: "datetime-range",
        muiFilterDateTimePickerProps: dateFilterProps,
        filterFn: dateFilterFn,
      },
    ],
    [t]
  );
};
