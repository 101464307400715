import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { AccountsAndSessionsCountByTypeDto } from "@/shared/api";
import { useApi } from "@/shared/api/use-api";

export const useAccountAndSessionsCountByTypeQuery = (
  {
    domain,
  }: {
    domain: string;
  },
  queryOptions?: Omit<
    UseQueryOptions<AxiosResponse<AccountsAndSessionsCountByTypeDto>>,
    "queryKey"
  >
) => {
  const { utilApi } = useApi();
  return useQuery({
    queryKey: [`/util/${domain}/account-and-session-count/by-types`],
    queryFn: ({ signal }) => utilApi.accountsAndSessionCountByType(domain, { signal }),
    ...queryOptions,
  });
};
