import { AddCircleOutline } from "@mui/icons-material";
import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysDomainCreateCfgCollection } from "@/api/hooks/sys-domain-hooks";
import CreateDomainConfigModal from "@/module/room-management-module/components/create-domain-config-modal";

const CreateDomainCfgTableControl = ({
  domain,
  onSuccess,
}: {
  domain: string;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const mutation = useSysDomainCreateCfgCollection();

  const [createCfg, setCreateCfg] = useState(false);

  const handleSave = (cfgName: string) => {
    mutation.mutate(
      {
        domain: domain,
        name: cfgName,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("configSuccessfullyCreated"), { variant: "success" });
          setCreateCfg(false);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministratorMessage", { message: err.message }),
            { variant: "error" }
          );
          console.error("Create domain config failure:", err);
        },
      }
    );
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        startIcon={<AddCircleOutline width={24} />}
        onClick={() => setCreateCfg(true)}
      >
        {t("newConfig")}
      </Button>
      <CreateDomainConfigModal
        open={createCfg}
        onClose={() => setCreateCfg(false)}
        onSave={(name) => handleSave(name)}
      />
    </>
  );
};

export default CreateDomainCfgTableControl;
