import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import AError from "../components/AError";

const ErrorPage = ({ errorMessage }: { errorMessage: string | undefined }) => {
  const { t } = useTranslation();

  return (
    <Row style={{ height: "100%" }} align="middle">
      <Col span={24}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <AError errorMessage={errorMessage ?? t("somethingWentWrong")} />
        </div>
      </Col>
    </Row>
  );
};

export default ErrorPage;
