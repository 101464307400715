import { Grid } from "@mui/material";
import { useAtom } from "jotai/index";
import { useEffect } from "react";

import RoomManagementModule from "@/module/room-management-module/room-management-module";
import { headerTitle } from "@/store/store";

const RoomManagementPage = ({ name }: { name: string }) => {
  const [, setHeaderTitle] = useAtom(headerTitle);
  useEffect(() => {
    setHeaderTitle(name);
  }, [setHeaderTitle, name]);

  return (
    <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
      <Grid item>
        <RoomManagementModule domain={name} />
      </Grid>
    </Grid>
  );
};

export default RoomManagementPage;
