import { Button, CircularProgress, Divider, FormControlLabel, Modal, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";


type AddingMode = "one" | "list";

const NameConfigAddModal = ({
  open,
  title,
  confirmLoading = false,
  onCancel,
  onSave,
}: {
  open: boolean;
  title?: string;
  confirmLoading?: boolean;
  onCancel?: () => void;
  onSave?: (value: string[]) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [addingMode, setAddingMode] = useState<AddingMode>("one");

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  function handleChangeAddingMode(event: React.ChangeEvent<HTMLInputElement>) {
    setAddingMode(event.target.value as AddingMode);
  }

  const onSubmit = (data: any) => {
    const values: string[] = data[addingMode].trim().split("\n");
    values.forEach((value) => validateNotEmpty(value));
    if (onSave) onSave(values);
  };

  function validateNotEmpty(value: string) {
    if (!value || !value.trim()) {
      alert(t("namesRequired"));
      return false;
    }
    return true;
  }

  return (
    <Modal
      open={open}
      onClose={() => onCancel && onCancel()}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div
        style={{
          width: 600,
          margin: "auto",
          padding: 20,
          backgroundColor: "white",
          borderRadius: 8,
        }}
      >
        {title && (
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
        )}

        <Divider sx={{ marginBottom: 2 }} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="body1">{t("chooseAddingType")}</Typography>
          <RadioGroup
            value={addingMode}
            onChange={handleChangeAddingMode}
            row
            sx={{ marginBottom: 2 }}
          >
            <FormControlLabel value="one" control={<Radio />} label={t("one")} />
            <FormControlLabel value="list" control={<Radio />} label={t("list")} />
          </RadioGroup>

          <Divider sx={{ marginY: 2 }} />

          {addingMode === "one" && (
            <Controller
              name="one"
              control={control}
              rules={{ required: t("namesRequired") }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t("name")}
                  variant="outlined"
                  fullWidth
                  error={!!error?.message}
                  helperText={error?.message}
                  margin="normal"
                />
              )}
            />
          )}

          {addingMode === "list" && (
            <Controller
              name="list"
              control={control}
              rules={{ required: t("namesRequired") }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t("names")}
                  variant="outlined"
                  multiline
                  rows={10}
                  fullWidth
                  error={!!error?.message}
                  helperText={error?.message}
                  margin="normal"
                />
              )}
            />
          )}

          <div style={{ marginTop: 20, display: "flex", justifyContent: "space-between" }}>
            <Button variant="outlined" onClick={() => onCancel && onCancel()}>
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={confirmLoading}
            >
              {confirmLoading ? <CircularProgress size={24} /> : t("add")}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NameConfigAddModal;
