import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import ServiceConfigPage from "@/pages/service-config-page";

const ROUTER_TABS = {
  rules: 0,
  instances: 1,
} as const;

const CLUSTERING_PATTERN_TABS = {
  rules: 0,
  instances: 1,
} as const;

const STATSERVICE_TABS = {
  oneDay: 0,
  sevenDays: 1,
  thirtyDays: 2,
} as const;

const PLAYERS_REPORTER_TABS = {
  reportChats: 0,
  reportRules: 1,
} as const;

const BOARDING_TABLES_TABS = {
  tables: 0,
} as const;

const searchParamsSchema = z.object({
  routerTab: z.nativeEnum(ROUTER_TABS).default(0),
  clusteringPatternTab: z.nativeEnum(CLUSTERING_PATTERN_TABS).default(0),
  statserviceTab: z.nativeEnum(STATSERVICE_TABS).default(0),
  tablesCacheTab: z.nativeEnum(STATSERVICE_TABS).default(0),
  playersReporterTab: z.nativeEnum(PLAYERS_REPORTER_TABS).default(0),
  boardingTablesTab: z.nativeEnum(BOARDING_TABLES_TABS).default(0),
});

export const Route = createFileRoute("/service-config/$configName")({
  component: Component,
  validateSearch: (search) => searchParamsSchema.parse(search),
});

function Component() {
  const { configName } = Route.useParams();
  return <ServiceConfigPage config={configName} />;
}
