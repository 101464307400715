import { Grid } from "@mui/material";
import { useAtom } from "jotai/index";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import MainTable from "@/module/service-configs-module/main-table";
import { headerTitle } from "@/store/store";

const ServiceConfigPage = ({ config }: { config: string }) => {
  const { t } = useTranslation();

  const [, setHeaderTitle] = useAtom(headerTitle);
  useEffect(() => {
    setHeaderTitle(t(`servicesMenu.${config}`));
  }, [config, setHeaderTitle, t]);

  return <MainTable cfg={config} />;
};

export default ServiceConfigPage;
