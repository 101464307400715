import { ClearAll, DoneAll } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface SelectClearAllRowControlProps {
  onSelectRow?: (name: string) => void;
  onClearRow?: (name: string) => void;
  name: string;
  disabled?: boolean;
}

const SelectClearAllRowControl = ({ onSelectRow, onClearRow, name, disabled }: SelectClearAllRowControlProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("enableAll")}>
        <IconButton size="small" onClick={() => onSelectRow && onSelectRow(name)} disabled={disabled}>
          <DoneAll />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("clearAll")}>
        <IconButton size="small" onClick={() => onClearRow && onClearRow(name)} disabled={disabled}>
          <ClearAll />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default SelectClearAllRowControl; 