import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Stack } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import { AutocompleteElement, TextFieldElement, useForm } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useSysCfgReplaceRouterInstanceMutation } from "@/api/hooks/sys-cfg-hooks";
import { ROOM_NAMES } from "@/lib/constants";

const ReplaceRouterInstanceModal = ({
  existingInstances,
  onClose,
  confirmLoading = false,
  onSave = undefined,
}: {
  existingInstances: string[];
  onClose: () => void;
  confirmLoading?: boolean;
  onSave?: () => void;
}) => {
  const { t } = useTranslation();

  const mutation = useSysCfgReplaceRouterInstanceMutation();

  const schema = z.object({
    oldInstance: z
      .string({
        invalid_type_error: t("fieldRequired"),
      })
      .min(1, t("fieldRequired")),
    newInstance: z.string().min(1, t("fieldRequired")).url(t("validUrlField")),
  });

  type SchemaType = z.infer<typeof schema>;

  const { control, handleSubmit } = useForm<SchemaType>({
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  const submit = handleSubmit((data) => {
    mutation.mutate(
      {
        domain: ROOM_NAMES.SERVICES,
        newInstance: data.newInstance,
        oldInstance: data.oldInstance,
      },
      {
        onSuccess: (response) => {
          enqueueSnackbar(t("configsSuccessfullyUpdated", { data: response.data }), {
            variant: "success",
          });
          onSave && onSave();
          onClose();
        },
        onError: (err) => {
          console.error("configs update error", err);
          enqueueSnackbar(t("somethingWentWrongDuringUpdatingData"), { variant: "error" });
        },
      }
    );
  });

  return (
    <Dialog open>
      <DialogTitle>{t("replaceInstance")}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ paddingTop: "5px", width: "400px" }}>
          <AutocompleteElement
            control={control}
            name="oldInstance"
            label={t("oldInstance")}
            textFieldProps={{
              placeholder: t("typeForSearch"),
            }}
            options={existingInstances}
          />
          <TextFieldElement
            control={control}
            name="newInstance"
            label={t("newInstance")}
            placeholder={t("newInstanceAddress")}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button sx={{ width: "100px" }} variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>

        <Button
          sx={{ width: "100px" }}
          variant="contained"
          disabled={confirmLoading}
          startIcon={confirmLoading ? <CircularProgress size={20} /> : undefined}
          onClick={submit}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReplaceRouterInstanceModal;
