import { PersonAddAlt1 } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useUpdateAvatarMutation, useUpdateAvatarsMutation } from "@/api/hooks/command-hooks";
import { assembleParticularFilterCt } from "@/lib/cfg-utils";
import UpdateAvatarModal from "@/module/accounts-module/components/update-avatar-modal";
import { KeyFilter, SysAccountSessionRegularDto } from "@/shared/api";
import { enqueueSnackbar } from "notistack";
import Popconfirm from "@/components/popcofirm";

const UpdateAvatarTableControl = ({
  roomName,
  table,
  filters,
  totalElementsCount,
  onSuccess,
}: {
  roomName: string;
  table: MRT_TableInstance<SysAccountSessionRegularDto>;
  filters: Set<KeyFilter>;
  totalElementsCount: number;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const [updateOneIsOpen, setUpdateOneIsOpen] = useState<boolean>(false);

  const updateAvatarMutation = useUpdateAvatarMutation();
  const updateAvatarsMutation = useUpdateAvatarsMutation();

  function handleOneMutationAction({
    row,
    file,
  }: {
    row?: MRT_Row<SysAccountSessionRegularDto>;
    file?: File;
  }) {
    if (!row) {
      enqueueSnackbar(t("thereAreNoOneRoleSelected"), { variant: "error" });
      return;
    }

    callOneMutation(row.original.id, file);
  }

  function handleManyMutationAction(rows: MRT_Row<SysAccountSessionRegularDto>[]) {
    if (rows.length === 0) {
      callManyMutation(filters);
    } else {
      callManyMutation(
        assembleParticularFilterCt({
          rows: rows,
          keyName: "id",
          keySupplier: (val) => val.id,
        })
      );
    }
  }

  function callOneMutation(sessionId: string, file?: File) {
    updateAvatarMutation.mutate(
      {
        domain: roomName,
        sessionId: sessionId,
        avatar: file,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("updateAvatarCommandSuccessfullySent"), { variant: "success" });
          setUpdateOneIsOpen(false);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.log("update nickname command failure", err);
          enqueueSnackbar(t("somethingWentWrongWhileUpdatingAvatar"), { variant: "error" });
        },
      }
    );
  }

  function callManyMutation(filters: Set<KeyFilter>) {
    updateAvatarsMutation.mutate(
      {
        domain: roomName,
        filters: filters,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("updateAvatarsCommandSuccessfullySent"), { variant: "success" });
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.log("update nicknames command failure", err);
          enqueueSnackbar(t("somethingWentWrongWhileUpdatingAvatars"), { variant: "error" });
        },
      }
    );
  }

  return (
    <>
      {table.getSelectedRowModel().rows.length === 1 ? (
        <IconButton
          size="small"
          style={{ fontSize: 12 }}
          color="primary"
          onClick={() => setUpdateOneIsOpen(true)}
        >
          <PersonAddAlt1 />
          &nbsp;{" "}
          {t("updateAvatarLength", {
            length:
              table.getSelectedRowModel().rows.length > 0
                ? table.getSelectedRowModel().rows.length
                : totalElementsCount,
          })}
        </IconButton>
      ) : (
        <Popconfirm
          title={t("areYouSureYouWantToUpdateNicknames")}
          onConfirm={() => handleManyMutationAction(table.getSelectedRowModel().rows)}
        >
          <IconButton size="small" style={{ fontSize: 12 }} color="primary">
            <PersonAddAlt1 />
            &nbsp;{" "}
            {t("updateAvatarLength", {
              length:
                table.getSelectedRowModel().rows.length > 0
                  ? table.getSelectedRowModel().rows.length
                  : totalElementsCount,
            })}
          </IconButton>
        </Popconfirm>
      )}
      <UpdateAvatarModal
        isOpen={updateOneIsOpen}
        onCancel={() => setUpdateOneIsOpen(false)}
        onSave={(data) =>
          handleOneMutationAction({
            row: table.getSelectedRowModel().rows[0],
            file: data,
          })
        }
      />
    </>
  );
};

export default UpdateAvatarTableControl;
