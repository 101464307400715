import { zodResolver } from "@hookform/resolvers/zod";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useMemo } from "react";
import {
  CheckboxElement,
  FormContainer,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { CreateSysDomainDto } from "@/shared/api";

const CreateDomainModal = ({
  open,
  onClose,
  onSave,
  isLoading = false,
}: {
  open: boolean;
  onClose: () => void;
  onSave: (data: CreateSysDomainDto) => void;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      z.object({
        name: z
          .string()
          .min(1, t("fieldRequired"))
          // eslint-disable-next-line no-control-regex
          // .regex(/^(?!system\.)(?!.*\.system\.)[A-Za-z_][^\x00$]*$/,
          //   "Starts with (A-Z or a-z or _). Doesn't start with 'system.'. " +
          //   "Without '.system.'. Without '$' sign."
          // ),
          // eslint-disable-next-line no-control-regex
          .regex(/^[^\x00/."$\\]+$/, t("enterDomainValidation")),
        is_room: z.boolean(),
      }),
    [t]
  );

  const form = useForm<CreateSysDomainDto>({
    mode: "onChange",
    defaultValues: { name: "", is_room: true },
    resolver: zodResolver(schema),
  });

  const onCloseHandler = () => {
    form.reset();
    onClose();
  };

  useEffect(() => {
    form.reset();
  }, [open, form]);

  return (
    <Dialog open={open} onClose={onCloseHandler} fullWidth maxWidth="sm">
      <DialogTitle title="Create User">{t("createdDomain")}</DialogTitle>
      <DialogContent style={{ paddingTop: "5px" }}>
        <FormContainer formContext={form} onSuccess={(val) => onSave(val)}>
          <Stack spacing={2}>
            <TextFieldElement name="name" label={t("domainName")} required fullWidth />
            <CheckboxElement name="is_room" label={t("isRoom")} />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={18} />}
            >
              {t("createdDomain")}
            </Button>
          </Stack>
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
};

export default CreateDomainModal;
