import { Typography } from "antd";
import { isArray, isNil } from "lodash";

import {
  AnyArrayOrObject,
  AnyObject,
  Input,
  Path,
  PatternValidated,
  SpecificControlType,
} from "./types/base-types";
import { Checkbox } from "./types/checkbox";
import { DoubleInput, IntegerInput, NumberInput, TextInput } from "./types/input-types";
import {
  DoubleTags,
  IntegerTags,
  MultiSelect,
  ObjectMultiSelect,
  ObjectSingleSelect,
  RadioButton,
  SingleSelect,
  TextTags,
} from "./types/select-types";
import {
  SimpleDynamicObjectArrayType,
  StyledWrapper,
  Wrapper,
  WrapperLabel,
} from "./types/wrapper-types";

const { Title } = Typography;

export const label = (label?: string) => {
  return label !== undefined ? label : undefined;
};

export const numberInputWidth = (value: NumberInput) => {
  return !value.tiny ? "100%" : undefined;
};

export const valueByKeyPath = (value: AnyArrayOrObject | undefined | null, path: Path) => {
  if (value === undefined || value === null) return undefined;
  let currentValue: any = value;

  for (const item of path) {
    if (
      currentValue !== null &&
      currentValue !== undefined &&
      typeof currentValue === "object"
    ) {
      currentValue = currentValue[item];
    } else {
      return undefined;
    }
  }

  return currentValue;
};

export const findObjectOptionValueByKeyValue = (
  control: ObjectSingleSelect | ObjectMultiSelect,
  value: string
) => {
  return control.options.find((opt) => valueByKeyPath(opt.value, control.key) === value)
    ?.value;
};
export const computeWrapperLabel = (wrapper: StyledWrapper, value?: WrapperLabel) => {
  if (value === undefined) return undefined;
  return (value.level === undefined || value.level === 6) && wrapper.noWrapper
    ? label(value.text)
    : undefined;
};

export const computeWrapperTitle = (value?: WrapperLabel) => {
  return value?.level && value.level !== 6 ? (
    <Title level={value.level}>{value.text}</Title>
  ) : undefined;
};

export const computeWrapperCardTitle = (wrapper: StyledWrapper, value?: WrapperLabel) => {
  if (value === undefined) return undefined;
  return (value.level === undefined || value.level === 6) && !wrapper.noWrapper
    ? value.text
    : undefined;
};

export const calculateSDOAPath = (control: SimpleDynamicObjectArrayType) => {
  return !control.path || control.path.length === 0 ? [control.type] : [...control.path];
};

export const computePurePath = (path: any): Path => {
  if (!isArray(path)) return [];
  return (path ?? [])
    .filter((p) => !isNil(p))
    .filter((p) => typeof p === "string" || typeof p === "number");
};

export const calculateInputName = (control: Input) => {
  let name = control.label;
  name = name ?? control.path[control.path.length - 1].toString();
  return name;
};

export const calculateInputRequiredRule = (control: Input) => {
  return {
    required: control.required,
    message: `Field '${calculateInputName(control)}' is required`,
  };
};

export const calculatePatternRule = (control: PatternValidated) => {
  return {
    pattern: control.pattern ? new RegExp(control.pattern.template) : undefined,
    message: control.pattern?.errorMessage,
  };
};

export const setValueByPath = (obj: AnyArrayOrObject, path: Path, value: any) => {
  const purePath: Path = computePurePath(path);
  let current: any = obj ?? {};

  for (let i = 0; i < purePath.length - 1; i++) {
    const key = path[i];
    if (isNil(current[key])) {
      current[key] = typeof path[i + 1] === "number" ? [] : {};
    }
    current = current[key];
  }

  const lastKey = purePath[purePath.length - 1];
  current[lastKey] = value;
};

export const calculateWrapperInitialValues = (
  wrapper: Wrapper,
  obj: AnyArrayOrObject = {},
  parentPath: Path = [],
  wrapperPath: Path = []
) => {
  for (const control of wrapper.elements) {
    fillSpecificControlValue(obj, [...parentPath, ...wrapperPath], control);
  }
  return obj;
};

export const fillSpecificControlValue = (
  obj: any,
  parentPath: Path,
  control: SpecificControlType
) => {
  switch (control.type) {
    case "TextInput":
      fillTextInputValue(obj, parentPath, control);
      break;
    case "IntegerInput":
      fillIntegerInputValue(obj, parentPath, control);
      break;
    case "DoubleInput":
      fillDoubleInputValue(obj, parentPath, control);
      break;
    case "SingleSelect":
      fillSingleSelectValue(obj, parentPath, control);
      break;
    case "MultiSelect":
      fillMultiSelectValue(obj, parentPath, control);
      break;
    case "TextTags":
      fillTextTagsValue(obj, parentPath, control);
      break;
    case "IntegerTags":
      fillIntegerTagsValue(obj, parentPath, control);
      break;
    case "DoubleTags":
      fillDoubleTagsValue(obj, parentPath, control);
      break;
    case "ObjectSelect":
      fillObjectSingleSelectValue(obj, parentPath, control);
      break;
    case "ObjectMultiSelect":
      fillObjectMultiSelectValue(obj, parentPath, control);
      break;
    case "RadioButton":
      fillRadioButtonValue(obj, parentPath, control);
      break;
    case "Checkbox":
      fillCheckboxValue(obj, parentPath, control);
      break;
    case "SimpleDynamicObjectArray":
      fillSimpleDynamicObjectArrayValue(obj, parentPath, control);
      break;
    case "Group":
      calculateWrapperInitialValues(control, obj, parentPath, control.path);
      break;
  }
};

export const fillTextInputValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: TextInput
) => {
  const value: string | null = control.defaultValue ?? null;
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillIntegerInputValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: IntegerInput
) => {
  const value: number | null = control.defaultValue ?? null;
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillDoubleInputValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: DoubleInput
) => {
  const value: number | null = control.defaultValue ?? null;
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillSingleSelectValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: SingleSelect
) => {
  const value: number | string | null = control.defaultValue ?? null;
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillMultiSelectValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: MultiSelect
) => {
  const value: Array<string | number> = control.defaultValue ?? [];
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillTextTagsValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: TextTags
) => {
  const value: Array<string> = control.defaultValue ?? [];
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillIntegerTagsValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: IntegerTags
) => {
  const value: Array<number> = control.defaultValue ?? [];
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillDoubleTagsValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: DoubleTags
) => {
  const value: Array<number> = control.defaultValue ?? [];
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillObjectSingleSelectValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: ObjectSingleSelect
) => {
  const value: AnyObject | null = control.defaultValue ?? null;
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillObjectMultiSelectValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: ObjectMultiSelect
) => {
  const value: Array<AnyObject> = control.defaultValue ?? [];
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillRadioButtonValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: RadioButton
) => {
  const value: string | number | boolean | null = control.defaultValue ?? null;
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillCheckboxValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: Checkbox
) => {
  const value: boolean = control.defaultValue ?? false;
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};

export const fillSimpleDynamicObjectArrayValue = (
  obj: AnyArrayOrObject,
  parentPath: Path,
  control: SimpleDynamicObjectArrayType
) => {
  const value: AnyObject[] = control.defaultValue ?? [];
  const path: Path = [...parentPath, ...control.path];
  setValueByPath(obj, path, value);
};
