import { Avatar, Checkbox, Tooltip } from "@mui/material";
import { Link } from "@tanstack/react-router";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getTabTypeBySessionType } from "@/lib/accountsUtils";
import { APP_GMT_DATE_FORMAT } from "@/lib/constants";
import { SysAccountRegularDto } from "@/shared/api";
import { MRT_OrderedColumnDef, NilObj } from "@/types/commonTypes";

const useAccountCommonColumns = ({ room }: { room: string }) => {
  const { t } = useTranslation();

  return useMemo<MRT_OrderedColumnDef<SysAccountRegularDto>[]>(
    () => [
      {
        id: "data.avatar",
        header: t("commonColumns.avatar"),
        columnFilterModeOptions: ["contains", "empty", "notEmpty"],
        order: 100,
        accessorFn: ({ data }) => (data as any)?.avatar ?? "",
        Cell: ({ row }) => {
          const dt = row.original.data as any;
          return <Avatar alt={dt?.nick ?? "?"} src={dt?.avatar} />;
        },
      },
      {
        id: "name",
        header: t("commonColumns.name"),
        order: 200,
        accessorFn: (row) => row.name ?? "-",
      },
      {
        id: "data.source",
        header: t("commonColumns.source"),
        order: 300,
        accessorFn: ({ data }) => (data as NilObj)?.source ?? "-",
      },
      {
        id: "data.cid",
        header: "Cid",
        order: 400,
        accessorFn: ({ data }) => (data as NilObj)?.cid ?? "-",
      },
      {
        id: "data.status",
        header: t("commonColumns.status"),
        order: 500,
        accessorFn: ({ data }) => (data as NilObj)?.status ?? "-",
        Cell: ({ row: { original } }) => {
          const status = (original.data as NilObj)?.status as unknown;

          return status !== undefined && typeof status === "number" ? (
            <Tooltip title={t(`accountStatus.${status}`)}><p>{status}</p></Tooltip>
          ) : (
            "-"
          );
        },
      },
      {
        id: "created_at",
        header: t("commonColumns.createdAt"),
        order: 600,
        accessorFn: (row) => (row.created_at ? new Date(row.created_at) : 0),
        Cell: ({
          row: {
            original: { created_at },
          },
        }) => (created_at ? moment(created_at).utc(false).format(APP_GMT_DATE_FORMAT) : "-"),
      },
      {
        id: "enabled",
        header: t("commonColumns.enabled"),
        order: 700,
        accessorFn: ({ enabled }) => enabled ?? "-",
        Cell: ({
          row: {
            original: { enabled },
          },
        }) => <Checkbox checked={enabled} disabled />,
      },
      {
        id: "session",
        header: t("commonColumns.session"),
        order: 800,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({
          row: {
            original: { session },
          },
        }) =>
          session ? (
            <Link
              to="/account/$room/$type"
              params={{
                room: room,
                type: getTabTypeBySessionType(session.session_type),
              }}
              search={{
                session_id: session.id,
              }}
            />
          ) : (
            "-"
          ),
      },
    ],
    [room, t]
  );
};

export default useAccountCommonColumns;
