import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAccountAndSessionsCountByTypeQuery } from "@/api/hooks/util-hooks";
import AppFullPageTabs from "@/components/app-full-page-tabs";
import { ACC_TP } from "@/components/config-modals/router-edit-modal/constants";
import { COMMON_REFETCH_INTERVAL } from "@/lib/constants";
import DebugSessionsTable from "@/module/accounts-module/debug-sessions-table/debug-sessions-table";
import GameSessionsTable from "@/module/accounts-module/game-sessions-table/game-sessions-table";
import RegularAccountsTable from "@/module/accounts-module/regular-accounts-table/regular-accounts-table";
import ServiceAccountsTable from "@/module/accounts-module/service-accounts-table/service-accounts-table";
import ServiceSessionsTable from "@/module/accounts-module/service-sessions-table/service-sessions-table";
import { Route } from "@/routes/accounts/$room.$type";
import { AccountSessionType } from "@/types/accountTypes";

export enum AccountType {
  regular = "regular",
  service = "service",
  gameSessions = "game-sessions",
  debugSessions = "debug-sessions",
  serviceSessions = "service-sessions",
}

const AccountsModule = () => {
  const { t } = useTranslation();

  const { room, type: unsafeAccountType } = Route.useParams();
  const navigate = Route.useNavigate();

  const accountType = useMemo<AccountType>(() => {
    return Object.values(AccountType).includes(unsafeAccountType as AccountType)
      ? (unsafeAccountType as AccountType)
      : AccountType.regular;
  }, [unsafeAccountType]);

  const setTabValue = (value: AccountType) => {
    void navigate({
      to: "/accounts/$room/$type",
      params: { room: room, type: value },
    });
  };

  const { data: countDataResponse } = useAccountAndSessionsCountByTypeQuery(
    {
      domain: room,
    },
    {
      refetchInterval: COMMON_REFETCH_INTERVAL,
    }
  );

  const accountAmount = useMemo<number>(() => {
    return countDataResponse?.data.accounts[ACC_TP.REGULAR.toString()] ?? 0;
  }, [countDataResponse]);

  const serviceAccountAmount = useMemo<number>(() => {
    return Object.entries(countDataResponse?.data.accounts ?? {}).reduce(
      (previousValue, currentValue) => {
        const [type, count] = currentValue;
        return type === ACC_TP.REGULAR.toString() ? previousValue : previousValue + count;
      },
      0
    );
  }, [countDataResponse]);

  const gameSessionsAmount = useMemo<number>(() => {
    return countDataResponse?.data.sessions[AccountSessionType.GAME.toString()] ?? 0;
  }, [countDataResponse]);

  const debugSessionsAmount = useMemo<number>(() => {
    return countDataResponse?.data.sessions[AccountSessionType.DEBUG.toString()] ?? 0;
  }, [countDataResponse]);

  const serviceSessionsAmount = useMemo<number>(() => {
    return Object.entries(countDataResponse?.data.sessions ?? {}).reduce(
      (previousValue, currentValue) => {
        const [type, count] = currentValue;
        return type === AccountSessionType.DEBUG.toString() ||
          type === AccountSessionType.GAME.toString()
          ? previousValue
          : previousValue + count;
      },
      0
    );
  }, [countDataResponse]);

  return (
    <AppFullPageTabs
      onTabChange={(val) => setTabValue(val)}
      defaultValue={accountType}
      items={[
        {
          value: AccountType.regular,
          label: t("accountsAmount", { amount: accountAmount }),
          render: () => <RegularAccountsTable roomName={room} isOpen />,
        },
        {
          value: AccountType.service,
          label: t("serviceAccountsAmount", { amount: serviceAccountAmount }),
          render: () => <ServiceAccountsTable roomName={room} isOpen />,
        },
        {
          value: AccountType.gameSessions,
          label: t("gameSessAmount", { amount: gameSessionsAmount }),
          render: () => <GameSessionsTable roomName={room} isOpen />,
        },
        {
          value: AccountType.serviceSessions,
          label: t("serviceSessAmount", { amount: serviceSessionsAmount }),
          render: () => <ServiceSessionsTable roomName={room} isOpen />,
        },
        {
          value: AccountType.debugSessions,
          label: t("debugSessAmount", { amount: debugSessionsAmount }),
          render: () => <DebugSessionsTable roomName={room} isOpen />,
        },
      ]}
    />
  );
};

export default AccountsModule;
