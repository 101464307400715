import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useEffect } from "react";

import { useBoardingPlayerSessionsQuery } from "@/api/hooks/boarding-player-sessions-hooks";
import { useMuiTableToolbarAlertBannerProps } from "@/hooks/use-mui-table-toolbar-alert-banner-props";
import { useTableSettings } from "@/hooks/use-table-settings";
import { BoardingPlayerSessionDto } from "@/shared/api";
import { useBoardingPlayerSessionsColumns } from "./boarding-player-sessions-columns";

const BoardingPlayerSessionsTable = ({
  setRowsAmount,  
  sessionsQuery,
}: {
  setRowsAmount?: (amount: number) => void;
  sessionsQuery: ReturnType<typeof useBoardingPlayerSessionsQuery>;
}) => {
  const columns = useBoardingPlayerSessionsColumns();
  const { state, defaultProps } = useTableSettings<BoardingPlayerSessionDto>("boardingPlayerSessionsTable");

  const {
    data: sessionsResponse,
    isError: isDataLoadingError,
    isLoading: isDataLoading,
    isRefetching: isDataRefetching,
  } = sessionsQuery;

  const muiToolbarAlertBannerProps = useMuiTableToolbarAlertBannerProps(
    isDataLoadingError,
    sessionsResponse?.statusText
  );

  useEffect(() => {
    setRowsAmount?.(sessionsResponse?.data.length ?? 0);
  }, [sessionsResponse, setRowsAmount]);

  const table = useMaterialReactTable({
    data: sessionsResponse?.data ?? [],
    muiToolbarAlertBannerProps,
    columns: columns,
    rowCount: sessionsResponse?.data.length ?? 0,
    state: {
      ...state,
      isLoading: isDataLoading,
      showProgressBars: isDataRefetching,
    },
    ...defaultProps,
    selectAllMode: "page",
  });

  return <MaterialReactTable table={table} />;
};

export default BoardingPlayerSessionsTable; 