import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormHelperText, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";


const UpdateAvatarModal = ({
  title,
  onCancel,
  confirmLoading = false,
  onSave,
  isOpen,
}: {
  title?: string;
  confirmLoading?: boolean;
  onCancel: () => void;
  onSave: (image?: File) => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation();

  const [isRandom, setIsRandom] = useState<boolean>(true);

  const [file, setFile] = useState<File | undefined>(undefined);

  const { handleSubmit, control, setValue } = useForm();

  useEffect(() => {
    if (isOpen) {
      setValue("image", undefined); // Reset file when modal opens
    }
  }, [isOpen, setValue]);

  const handleSaveClick = () => {
    if (onSave) {
      onSave(file);
    }
  };

  const validateFile = (file: File) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      alert(t("youCanUploadOnlyJPGPNG"));
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      alert(t("imageMustBeSmallerThan2MB"));
      return false;
    }
    return true;
  };

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(handleSaveClick)}
          style={{ display: "flex", flexDirection: "column", gap: "15px" }}
        >
          <Controller
            name="image"
            control={control}
            render={({ field }) => (
              <>
                <Button
                  variant="contained"
                  component="label"
                  disabled={isRandom}
                  startIcon={<CloudUploadIcon />}
                  fullWidth
                >
                  {t("selectFile")}
                  <input
                    {...field}
                    type="file"
                    hidden
                    onChange={(e) => {
                      const selectedFile = e.target.files?.[0];
                      if (selectedFile && validateFile(selectedFile)) {
                        setFile(selectedFile);
                        field.onChange(selectedFile);
                      }
                    }}
                  />
                </Button>
                {file && (
                  <Typography variant="body2" color="textSecondary">
                    {file.name}
                  </Typography>
                )}
                {isRandom && (
                  <FormHelperText error>{t("customAvatarSingleAccount")}</FormHelperText>
                )}
              </>
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isRandom}
                onChange={() => {
                  setFile(undefined);
                  setIsRandom(!isRandom);
                }}
              />
            }
            label={t("setRandomAvatarFromPool")}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={confirmLoading} color="primary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSaveClick}
          disabled={confirmLoading}
          color="primary"
          variant="contained"
        >
          {t("submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateAvatarModal;
