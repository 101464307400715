import { Grid } from "@mui/material";
import { useAtom } from "jotai/index";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import RoomsManagementModule from "@/module/rooms-management-module/rooms-management-module";
import { headerTitle } from "@/store/store";

const RoomsManagementPage = () => {
  const { t } = useTranslation();

  const [, setHeaderTitle] = useAtom(headerTitle);
  useEffect(() => {
    setHeaderTitle(t("roomManagement"));
  }, [setHeaderTitle, t]);

  return (
    <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
      <Grid item>
        <RoomsManagementModule />
      </Grid>
    </Grid>
  );
};

export default RoomsManagementPage;
