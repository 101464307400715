import { HelpOutlined } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import SelectClearAllControl from "@/components/select-clear-all-control";
import SelectClearAllRowControl from "@/components/select-clear-all-row-control";
import { useTableSettings } from "@/hooks/use-table-settings";
import { ConfigDto } from "@/shared/api";
import { createCheckboxColumn } from "./util";

export type ColumnName =
  | "read"
  | "write"
  | "delete"
  | "execute"
  | "exclusive"
  | "disable_auth_filters";

const UserConfigsForm = ({
  name = "",
  configs,
  onSelectColumn,
  onClearColumn,
  onSelectRow,
  onClearRow,
  onSelectAll,
  onClearAll,
}: {
  name?: string;
  configs: ConfigDto[];
  onSelectColumn?: (column: ColumnName) => void;
  onClearColumn?: (column: ColumnName) => void;
  onSelectRow?: (configName: string) => void;
  onClearRow?: (configName: string) => void;
  onSelectAll?: () => void;
  onClearAll?: () => void;
}) => {
  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<ConfigDto>[]>(
    () => [
      {
        header: t("collectionName"),
        editEnabled: false,
        accessorFn: (row) => row.name,
      },
      createCheckboxColumn<ConfigDto, "read">({
        columnName: "read", 
        headerText: t("read"), 
        formInputName: (row) => `${name}.${row.index}.read`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<ConfigDto, "write">({
        columnName: "write", 
        headerText: t("write"), 
        formInputName: (row) => `${name}.${row.index}.write`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<ConfigDto, "delete">({
        columnName: "delete", 
        headerText: t("delete"), 
        formInputName: (row) => `${name}.${row.index}.delete`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<ConfigDto, "execute">({
        columnName: "execute", 
        headerText: t("execute"), 
        formInputName: (row) => `${name}.${row.index}.execute`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      createCheckboxColumn<ConfigDto, "exclusive">({
        columnName: "exclusive", 
        headerText: t("migrate"), 
        formInputName: (row) => `${name}.${row.index}.exclusive`,
        onSelectColumn: (column) => onSelectColumn?.(column),
        onClearColumn: (column) => onClearColumn?.(column),
      }),
      {
        ...createCheckboxColumn<ConfigDto, "disable_auth_filters">({
          columnName: "disable_auth_filters", 
          headerText: t("daf"), 
          formInputName: (row) => `${name}.${row.index}.disable_auth_filters`,
          onSelectColumn: (column) => onSelectColumn?.(column),
          onClearColumn: (column) => onClearColumn?.(column),
        }),
        Header: () => (
          <Tooltip title={t("disableAuthFilters")}>
            <div style={{ display: "flex" }}>
              <span>{t("daf")}</span>
              <span>&nbsp;</span>
              <HelpOutlined
                sx={{ width: "12px", height: "12px", color: "rgba(0,0,0,0.44)" }}
              />
            </div>
          </Tooltip>
        ),
      },
    ],
    [t, name, onSelectColumn, onClearColumn]
  );

  const { defaultProps, state } = useTableSettings<ConfigDto>("userConfig");

  const table = useMaterialReactTable({
    data: configs,
    columns: columns,
    enableColumnActions: true,
    enableRowActions: true,
    getRowId: (originalRow) => originalRow.name,
    enableStickyHeader: true,
    enablePagination: false,
    enableGrouping: true,
    enableColumnFilterModes: true,
    enableColumnDragging: false,
    ...defaultProps,
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "name", desc: false }],
    },
    state,
    renderTopToolbarCustomActions: () => (
      <div>
        <SelectClearAllControl 
          onSelectAll={onSelectAll} 
          onClearAll={onClearAll} 
        />
      </div>
    ),
    renderRowActions: ({ row: { original } }) => (
      <SelectClearAllRowControl 
        onSelectRow={onSelectRow} 
        onClearRow={onClearRow}
        name={original.name}
      />
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default UserConfigsForm;
