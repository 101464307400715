import { Autocomplete, Box, Button, CircularProgress, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Navigate } from "@tanstack/react-router";
import { trimEnd } from "lodash";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { FormContainer, SwitchElement, useForm } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";



import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { useSysCfgCrossRoomsQuery } from "@/api/hooks/sys-cfg-hooks";
import { useSysDomainsQuery } from "@/api/hooks/sys-domain-hooks";
import { useRoomsListQuery } from "@/api/hooks/table-cache-hooks";
import { useUpdateUserMutation } from "@/api/hooks/user-hooks";
import HeaderedFullHeightContainer from "@/components/headered-full-height-container";
import PhRoomsAccessForm from "@/components/user-management/ph-rooms-access-form";
import { ColumnName } from "@/components/user-management/user-configs-form";
import UserRoomAccessForm from "@/components/user-management/user-room-access-form";
import UserRoomsAccessForm from "@/components/user-management/user-rooms-access-form";
import { mapCommonRoomsToRoomDto, mapPhRooms, mapServiceRoomToRoomDto } from "@/lib/common-utils";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { ConfigDto, PlayhubGroupDto, PlayhubRoomDto, RoomDto, SysCfgRawDto, UpdateUserRequestDto, UserResponseRegularDto } from "@/shared/api";


const getToggledGroup = (groupName: string, value: boolean): PlayhubGroupDto => ({
  name: groupName,
  room_read: value,
  chat_read: value,
  chat_write: value,
  max_bot_read: value,
  max_bot_write: value,
  bot_execute_leave: value,
  bot_execute_manually_boarding: value,
  game_read: value,
  game_write: value,
});

const getToggledCfg = (configName: string, value: boolean): ConfigDto => ({
  name: configName,
  read: value,
  write: value,
  delete: value,
  execute: value,
  exclusive: value,
  disable_auth_filters: value,
});

const UserForm = ({
  user,
  onUpdated,
}: {
  user: UserResponseRegularDto;
  onUpdated?: () => void;
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { data: authGroups } = useAuthGroupsQuery({
    refetchOnWindowFocus: false,
  });

  const updateUserMutation = useUpdateUserMutation();

  const {
    data: domains,
    isLoading: domainsIsLoading,
    isError: domainsIsError,
    error: domainsError,
  } = useSysDomainsQuery();

  const { data: playhubRoomsRs } = useRoomsListQuery();

  const { data: gameGroupsRs } = useSysCfgCrossRoomsQuery(
    {
      config_name: ROOM_CONFIGS_NAMES.GAME_GROUP,
      rooms: playhubRoomsRs?.data?.rooms?.map((item) => item + "_eco") ?? [],
    },
    {
      enabled: !!playhubRoomsRs?.data?.rooms,
    }
  );

  const gameGroupsData = useMemo(() => {
    if (!gameGroupsRs?.data.configs) return {};

    return Object.entries(gameGroupsRs.data.configs).reduce(
      (acc: Record<string, SysCfgRawDto[]>, [key, value]) => ({
        ...acc,
        [trimEnd(key, "_eco")]: value,
      }),
      {},
    );
  }, [gameGroupsRs]);

  const playhubRoomsData = useMemo(() => playhubRoomsRs?.data?.rooms ?? [], [playhubRoomsRs]);

  const [usualRooms, setUsualRooms] = useState(
    mapCommonRoomsToRoomDto(domains?.data ?? [], user)
  );

  useEffect(() => {
    setUsualRooms(mapCommonRoomsToRoomDto(domains?.data ?? [], user));
  }, [user, domains]);

  const usualRoomsToggleColumn = (roomName: string, name: string, value: boolean) => {
    const rooms: RoomDto[] = usualRooms.map((room) => {
      return room.room_name === roomName
        ? {
            room_name: room.room_name,
            configs: room.configs.map((item) => ({ ...item, [name]: value })),
          }
        : room;
    });

    setUsualRooms(rooms);
  };

  const roomsToggleRow = (rn: string, cfg: string, val: boolean) => {
    const rooms: RoomDto[] = usualRooms.map((room) => {
      return room.room_name === rn
        ? {
            room_name: room.room_name,
            configs: room.configs.map((it) =>
              it.name === cfg ? getToggledCfg(it.name, val) : it
            ),
          }
        : room;
    });

    setUsualRooms(rooms);
  };

  const roomsToggleAll = (rn: string, val: boolean) => {
    const rooms: RoomDto[] = usualRooms.map((room) => {
      return room.room_name === rn
        ? {
            room_name: room.room_name,
            configs: room.configs.map((it) => getToggledCfg(it.name, val)),
          }
        : room;
    });

    setUsualRooms(rooms);
  };

  const [serviceRoom, setServiceRoom] = useState(
    mapServiceRoomToRoomDto(domains?.data ?? [], user)
  );

  useEffect(() => {
    setServiceRoom(mapServiceRoomToRoomDto(domains?.data ?? [], user));
  }, [user, domains]);

  const serviceToggleColumn = (name: ColumnName, value: boolean) => {
    setServiceRoom((prevState) => ({
      room_name: prevState.room_name,
      configs: prevState.configs.map((item) => ({ ...item, [name]: value })),
    }));
  };

  const serviceToggleRow = (configName: string, value: boolean) => {
    setServiceRoom({
      room_name: serviceRoom.room_name,
      configs: serviceRoom.configs.map((item) =>
        item.name === configName ? getToggledCfg(item.name, value) : item
      ),
    });
  };

  const serviceToggleAll = (value: boolean) => {
    setServiceRoom({
      room_name: serviceRoom.room_name,
      configs: serviceRoom.configs.map((item) => getToggledCfg(item.name, value)),
    });
  };

  const [playhubRooms, setPlayhubRooms] = useState(
    mapPhRooms(playhubRoomsData, gameGroupsData, user),
  );

  useEffect(() => {
    setPlayhubRooms(mapPhRooms(playhubRoomsData ?? [], gameGroupsData, user));
  }, [gameGroupsData, playhubRoomsData, user]);

  const phToggleColumn = (roomName: string, name: string, value: boolean) => {
    const rooms: PlayhubRoomDto[] = playhubRooms.map((room) => {
      return room.room_name === roomName
        ? {
            room_name: room.room_name,
            groups: room.groups.map((item) => ({ ...item, [name]: value })),
          }
        : room;
    });

    setPlayhubRooms(rooms);
  };

  const phToggleRow = (rn: string, gr: string, val: boolean) => {
    const rooms: PlayhubRoomDto[] = playhubRooms.map((room) => {
      return room.room_name === rn
        ? {
            room_name: room.room_name,
            groups: room.groups.map((it) =>
              it.name === gr ? getToggledGroup(it.name, val) : it
            ),
          }
        : room;
    });

    setPlayhubRooms(rooms);
  };

  const phToggleAll = (rn: string, val: boolean) => {
    const rooms: PlayhubRoomDto[] = playhubRooms.map((room) => {
      return room.room_name === rn
        ? {
            room_name: room.room_name,
            groups: room.groups.map((it) => getToggledGroup(it.name, val)),
          }
        : room;
    });

    setPlayhubRooms(rooms);
  };

  const authGroupsOptions = useMemo(
    () => authGroups?.data?.map((item) => ({ value: item.name, label: item.name })) ?? [],
    [authGroups]
  );

  const formContext = useForm<UpdateUserRequestDto>({
    defaultValues: {
      id: user.id,
      enabled: user.enabled,
      is_owner: user.is_owner,
      group: [...user.group],
      rooms: [...usualRooms],
      service: { ...serviceRoom },
      playhub: [...playhubRooms],
    },
  });

  const isOwner = formContext.watch("is_owner");
  const groups = formContext.watch("group");

  useEffect(() => {
    formContext.setValue("id", user.id);
    formContext.setValue("enabled", user.enabled);
    formContext.setValue("is_owner", user.is_owner);
    formContext.setValue("group", Array.from(user.group));
  }, [formContext, user]);

  useEffect(() => {
    formContext.setValue("rooms", usualRooms);
  }, [formContext, usualRooms]);

  useEffect(() => {
    formContext.setValue("service", serviceRoom);
  }, [formContext, serviceRoom]);

  useEffect(() => {
    formContext.setValue("playhub", playhubRooms);
  }, [formContext, playhubRooms]);

  function handleSave(data: UpdateUserRequestDto) {
    updateUserMutation.mutate(
      {
        updateUser: data,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("userDataUpdated"), { variant: "success" });
          onUpdated && onUpdated();
        },
      }
    );
  }

  if (domainsIsLoading) {
    return <CircularProgress />;
  }

  if (domainsIsError) {
    return (
      <Navigate
        to="/errors/"
        search={{ errorMessage: domainsError.message ?? t("unknownLoadingError") }}
      />
    );
  }

  if (!domains) {
    return <p>{t("roomsNotAvailable")}</p>;
  }

  return (
    <>
      <FormContainer
        formContext={formContext}
        onSuccess={(val) => handleSave(val)}
        FormProps={{
          style: { height: "100%" },
        }}
      >
        <HeaderedFullHeightContainer
          header={
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <SwitchElement
                  label="Is owner"
                  name="is_owner"
                  sx={{ "& .MuiFormControlLabel-label": { color: "text.primary" } }}
                />

                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={updateUserMutation.isPending}
                  startIcon={
                    updateUserMutation.isPending ? <CircularProgress size={18} /> : undefined
                  }
                  style={{ width: "100%", maxWidth: "150px" }}
                >
                  {t("save")}
                </Button>
              </Box>

              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  visibility: isOwner ? "hidden" : undefined,
                }}
              >
                <Tabs
                  value={currentTab}
                  onChange={(_, val) => setCurrentTab(val)}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={t("details")}
                    id="simple-tab-0"
                    aria-controls="simple-tab-panel-0"
                  />
                  <Tab
                    label={t("roomsConfigAccessSettings")}
                    id="simple-tab-1"
                    aria-controls="simple-tab-panel-1"
                  />
                  <Tab
                    label={t("serviceConfigsAccessSettings")}
                    id="simple-tab-2"
                    aria-controls="simple-tab-panel-2"
                  />
                  <Tab
                    label={t("playhubAccessSettings")}
                    id="simple-tab-3"
                    aria-controls="simple-tab-panel-3"
                  />
                </Tabs>
              </Box>
            </>
          }
        >
          <>
            {!isOwner && (
              <>
                {currentTab === 0 && (
                  <Box sx={{ py: 3 }}>
                    <Typography variant="body1">Auth Groups</Typography>
                    <Autocomplete
                      multiple
                      id="auth-groups"
                      options={authGroupsOptions}
                      getOptionLabel={(option) => option.label}
                      value={authGroupsOptions.filter((option) => groups.includes(option.value))}
                      onChange={(event, newValue) => {
                        formContext.setValue(
                          "group",
                          newValue.map((item) => item.value)
                        );
                      }}
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField {...params} label={t("authGroups")} variant="outlined" />
                      )}
                    />
                  </Box>

                )}

                {currentTab === 1 && (
                  <UserRoomsAccessForm
                    rooms={usualRooms}
                    name="rooms"
                    onSelectColumn={(roomName, column) =>
                      usualRoomsToggleColumn(roomName, column, true)
                    }
                    onClearColumn={(roomName, column) =>
                      usualRoomsToggleColumn(roomName, column, false)
                    }
                    onSelectRow={(roomName, cfg) => roomsToggleRow(roomName, cfg, true)}
                    onClearRow={(roomName, column) => roomsToggleRow(roomName, column, false)}
                    onSelectAll={(roomName) => roomsToggleAll(roomName, true)}
                    onClearAll={(roomName) => roomsToggleAll(roomName, false)}
                  />
                )}

                {currentTab === 2 && (
                  <UserRoomAccessForm
                    room={serviceRoom}
                    name="service"
                    onSelectColumn={(column) => serviceToggleColumn(column, true)}
                    onClearColumn={(column) => serviceToggleColumn(column, false)}
                    onSelectRow={(cfg) => serviceToggleRow(cfg, true)}
                    onClearRow={(cfg) => serviceToggleRow(cfg, false)}
                    onSelectAll={() => serviceToggleAll(true)}
                    onClearAll={() => serviceToggleAll(false)}
                  />
                )}

                {currentTab === 3 && (
                  <PhRoomsAccessForm
                    rooms={playhubRooms}
                    name="playhub"
                    onSelectColumn={(rn, column) => phToggleColumn(rn, column, true)}
                    onClearColumn={(rn, column) => phToggleColumn(rn, column, false)}
                    onSelectRow={(rn, cfg) => phToggleRow(rn, cfg, true)}
                    onClearRow={(rn, column) => phToggleRow(rn, column, false)}
                    onSelectAll={(rn) => phToggleAll(rn, true)}
                    onClearAll={(rn) => phToggleAll(rn, false)}
                  />
                )}
              </>
            )}
          </>
        </HeaderedFullHeightContainer>
      </FormContainer>
    </>
  );
};

export default UserForm;
