import { AddOutlined, ContentCopy, DeleteOutlineOutlined, Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysCfgQuery } from "@/api/hooks/sys-cfg-hooks";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import ClusteringPatternCreateModal from "@/components/config-modals/clustering-pattern-edit-modal/clustering-pattern-create-modal";
import { EditedClusteringPattern } from "@/components/config-modals/clustering-pattern-edit-modal/types";
import { useTableSettings } from "@/hooks/use-table-settings";
import { assembleNamesFilter, mapSysCfgToEditClusteringPattern } from "@/lib/cfg-utils";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import {
  useConfigChangeEnabled,
  useDeleteConfigs,
  useSaveConfig,
  useUpdateConfig,
} from "@/module/room-configs-module/hooks/room-configs-hooks";
import { ResultDtoLong, SysCfgRegularDto } from "@/shared/api";
import Checkbox from "@mui/material/Checkbox";
import Popconfirm from "@/components/popcofirm";

const ClusteringPatternConfigTable = ({
  setRowsAmount,
}: {
  setRowsAmount: (amount: number) => void;
}) => {
  const { t } = useTranslation();

  const cfgPerms = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN,
  });

  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch,
  } = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES,
    config_name: ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN,
    sort: [{ key: "created_at", mode: "DESC" }],
  });

  const roomConfigsSortedData = React.useMemo(() => {
    return configsRs?.data.content ?? [];
  }, [configsRs]);

  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  const isOwner = useIsOwnerOrSuperAdmin();

  const saveNewConfig = useSaveConfig({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN,
    onSuccessCallback: () => {
      void refetch();
      closeAddConfig();
    },
  });

  function handleClickSaveNewConfig(config: EditedClusteringPattern) {
    saveNewConfig({
      ...config,
      auth_groups: new Set(config.authGroups),
      enabled: config.enabled ?? false,
    });
  }

  const updateConfig = useUpdateConfig({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN,
    onSuccessCallback: () => {
      closeAddConfig();
      void refetch();
    },
  });

  function handleClickUpdateConfig(name: string, config: EditedClusteringPattern) {
    updateConfig(name, { ...config, auth_groups: new Set(config.authGroups) });
  }

  const handleChangeEnableBulk = useConfigChangeEnabled({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN,
    onSuccessCallback: (table) => {
      void refetch();
      table.resetRowSelection();
    },
  });

  const handleDeleteConfigs = useDeleteConfigs({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN,
    onSuccessCallback: (table, response) => {
      setBulkOperationResult([response.data]);
      void refetch();
      table.resetRowSelection();
    },
  });

  const [modalMode, setModalMode] = useState<"edit" | "add" | undefined>(undefined);
  const [modalInitValues, setModalInitValues] = useState<SysCfgRegularDto | undefined>(
    undefined
  );
  const [editItemName, setEditItemName] = useState<string>("");

  useEffect(() => {
    setRowsAmount(configsRs?.data.size ?? 0);
  }, [configsRs, setRowsAmount]);

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(() => {
    return [
      {
        header: t("clusteringPatternTable.enabled"),
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => <Checkbox checked={row.original.enabled} disabled />,
      },
      {
        header: t("clusteringPatternTable.name"),
        accessorKey: "name",
        filterFn: "contains",
      },
    ];
  }, [t]);

  const { defaultProps, state, columnFilters, grouping, globalFilter } =
    useTableSettings<SysCfgRegularDto>("clusteringPattern");

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    ...defaultProps,
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "name", desc: false }],
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: true,
    enablePagination: true,
    enableSorting: true,
    state: {
      ...state,
      isLoading: configsIsLoading,
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerms.write && (
            <Button
              onClick={() => {
                setModalInitValues(undefined);
                setEditItemName("");
                setModalMode("add");
              }}
            >
              <AddOutlined /> {t("new")}
            </Button>
          )}

          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerms.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> {t("enable")}
                </IconButton>
              )}

              {cfgPerms.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> {t("disable")}
                </IconButton>
              )}

              {cfgPerms.delete && (
                <Popconfirm
                  title={t("deleteItems")}
                  description={t("areYouSureToDeleteItems")}
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                    <DeleteOutlineOutlined /> {t("delete")}
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={ROOM_NAMES.SERVICES}
                  cfgName={ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetch()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton
          onClick={() => {
            setModalInitValues({ ...row.original });
            setModalMode("edit");
            setEditItemName(row.original.name);
          }}
        >
          <Edit />
        </IconButton>

        {cfgPerms.write && (
          <IconButton
            onClick={() => {
              setModalInitValues({
                ...row.original,
                data: { ...row.original.data },
                name: "",
                enabled: false,
              });
              setModalMode("add");
            }}
          >
            <ContentCopy />
          </IconButton>
        )}
      </Box>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  function closeAddConfig() {
    setModalMode(undefined);
    setModalInitValues(undefined);
    setEditItemName("");
  }

  return (
    <>
      <MaterialReactTable table={table} />

      <ClusteringPatternCreateModal
        open={!!modalMode}
        mode={modalMode}
        disabled={!cfgPerms.write}
        initialData={mapSysCfgToEditClusteringPattern(modalInitValues)}
        onSave={(data) => {
          if (modalMode === "add") return handleClickSaveNewConfig(data);
          if (modalMode === "edit") return handleClickUpdateConfig(editItemName, data);
        }}
        onCancel={() => closeAddConfig()}
      />

      {bulkOperationResult && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title={t("operationResult")}
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default ClusteringPatternConfigTable;
