import { Box } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSysDomainQuery } from "@/api/hooks/sys-domain-hooks";
import DeleteDomainConfigTableControl from "@/module/room-management-module/components/delete-domain-config-table-control";
import useConfigsListTable from "@/module/room-management-module/hooks/configs-list-table-hook";
import { NameIdentityItem } from "@/types/commonTypes";

const RoomManagementModule = ({ domain }: { domain: string }) => {
  const { t } = useTranslation();

  const {
    data: domainRs,
    refetch,
    isLoading,
    isRefetching,
    isError,
  } = useSysDomainQuery(domain);

  const configs = useMemo<NameIdentityItem[]>(() => {
    return domainRs?.data.config_names?.map((i) => ({ name: i })) ?? [];
  }, [domainRs]);

  const table = useConfigsListTable(
    {
      domain: domain,
      configs: configs,
      refetchConfigs: () => refetch(),
    },
    {
      muiToolbarAlertBannerProps: isError
        ? { color: "error", children: t("errorLoadingData") }
        : undefined,
      state: {
        isLoading: isLoading,
        showProgressBars: isRefetching,
        showAlertBanner: isError,
      },
      enableRowActions: true,
      positionActionsColumn: "last",
      displayColumnDefOptions: {
        "mrt-row-actions": { header: "", grow: false },
      },
      initialState: {
        pagination: {
          pageIndex: 0,
          pageSize: 50,
        },
      },
      renderRowActions: ({ row }) => (
        <Box>
          <DeleteDomainConfigTableControl
            domain={domain}
            cfgName={row.original.name}
            refetch={() => refetch()}
          />
        </Box>
      ),
    }
  );

  return <MaterialReactTable table={table} />;
};

export default RoomManagementModule;
