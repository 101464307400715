import { Logout } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAtom } from "jotai/index";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useLogout } from "@/auth/hooks";
import SidebarMenuItem from "@/components/sidebar/sidebar-menu-item-type";
import { RoomSelectTextField } from "@/components/sidebar/styled";
import useSidebarMenuItems from "@/components/sidebar/use-sidebar-menu-items";
import { SysDomainRegularDto } from "@/shared/api";
import { appCurrentRoom } from "@/store/store";

const DrawerHeader = styled("div")(() => ({
  flexGrow: 0,
}));

const DrawerContent = styled("div")(() => ({
  flexGrow: 1,
  overflowY: "auto",
}));

const DrawerFooter = styled("div")(() => ({
  flexGrow: 0,
}));

const AppMainSidebar = ({
  domains,
  domainsIsLoading = false,
}: {
  domains: SysDomainRegularDto[];
  domainsIsLoading?: boolean;
}) => {
  const { t } = useTranslation();
  const menuItems = useSidebarMenuItems({ domains });
  const [selectedRoom, setSelectedRoom] = useAtom(appCurrentRoom);
  const logout = useLogout();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const width = useMemo(() => (isCollapsed ? 80 : 250), [isCollapsed]);
  const rooms = useMemo(() => domains.filter((it) => it.is_room) ?? [], [domains]);

  const handleRoomChange = (room: SysDomainRegularDto | null) => {
    if (room != null) setSelectedRoom(room);
  };
  const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const handleCollapseToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <Drawer
      variant="permanent"
      open
      sx={{
        width: width,
        flexGrow: 0,
        transition: "width 0.3s ease",
        "& .MuiDrawer-paper": {
          width: width,
          boxSizing: "border-box",
          position: "static",
          transition: "width 0.3s ease",
        },
      }}
    >
      <DrawerHeader>
        {isCollapsed ? (
          <Box sx={{ p: 1 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", textAlign: "center" }}
              onMouseOver={(e) => handleDropdownOpen(e)}
            >
              {selectedRoom?.name
                .toUpperCase()
                .split("_")
                .map((it) => it[0]) ?? "R"}
            </Typography>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleDropdownClose}
              MenuListProps={{ onMouseLeave: handleDropdownClose }}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              {rooms.map((it) => (
                <MenuItem
                  key={it.name}
                  selected={it.name === selectedRoom?.name}
                  onClick={() => handleRoomChange(it)}
                >
                  {it.name.toLowerCase().split("_").join(" ")}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        ) : (
          <Box sx={{ p: 1 }}>
            <Autocomplete
              loading={domainsIsLoading}
              fullWidth
              size="small"
              options={rooms}
              value={selectedRoom === undefined ? null : selectedRoom}
              onChange={(_e, v) => handleRoomChange(v)}
              getOptionLabel={(option) => option.name.toLowerCase().split("_").join(" ")}
              getOptionKey={(option) => option.name}
              renderInput={(params) => (
                <RoomSelectTextField {...params} placeholder={t("selectRoom")} />
              )}
            />
          </Box>
        )}
      </DrawerHeader>

      <DrawerContent>
        <Box sx={{ p: 1 }}>
          <List component="nav" aria-labelledby="nested-list-subheader">
            {menuItems.map((item) => (
              <SidebarMenuItem key={item.name} item={item} sidebarCollapsed={isCollapsed} />
            ))}
          </List>
        </Box>
      </DrawerContent>

      <DrawerFooter>
        <Box
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isCollapsed ? (
            <IconButton onClick={() => logout()}>
              <Logout />
            </IconButton>
          ) : (
            <Button variant="contained" onClick={() => logout()} endIcon={<Logout />}>
              {t("logout")}
            </Button>
          )}
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "50px",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={handleCollapseToggle}
        >
          <IconButton>{isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </Box>
      </DrawerFooter>
    </Drawer>
  );
};

export default AppMainSidebar;
