import { keepPreviousData } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGetUserByIdQuery } from "@/api/hooks/user-hooks";
import ACenteredSpinner from "@/components/ACenteredSpinner";
import UserFrom from "@/module/user-form-module";
import { UserResponseRegularDto } from "@/shared/api";
import { headerTitle } from "@/store/store";

const UserPage = ({ userId }: { userId: string }) => {
  const { t } = useTranslation();
  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
    error: userError,
    refetch,
  } = useGetUserByIdQuery(
    {
      userId: Number(userId) as number,
    },
    {
      enabled: Boolean(userId),
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
    }
  );

  const user = useMemo<UserResponseRegularDto | undefined>(() => {
    return userData ? { ...userData.data } : undefined;
  }, [userData]);

  const [, setHeaderTitle] = useAtom(headerTitle);
  useEffect(() => {
    setHeaderTitle(`${t("user")}: ${user?.username ?? "Unknown user"}`);
  }, [setHeaderTitle, t, user]);

  if (userIsLoading) {
    return <ACenteredSpinner />;
  }

  if (userIsError) {
    return (
      <p>
        {t("somethingWentWrongWhileGettingUserData", {
          message: userError?.message ?? t("unknownError"),
        })}
      </p>
    );
  }

  if (!user) {
    return <p>{t("thereNoUserWithId", { userId })}</p>;
  }

  return (
    <>
      <UserFrom user={user} onUpdated={() => refetch()} />
    </>
  );
};

export default UserPage;
