import { AddCircleOutline } from "@mui/icons-material";
import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysDomainCreateMutation } from "@/api/hooks/sys-domain-hooks";
import CreateDomainModal from "@/module/rooms-management-module/components/create-domain-modal";
import { CreateSysDomainDto } from "@/shared/api";

const CreateDomainTableControl = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { t } = useTranslation();
  const [createDomain, setCreateDomain] = useState<boolean>(false);
  const mutation = useSysDomainCreateMutation();

  const handleSave = (dto: CreateSysDomainDto) => {
    mutation.mutate(
      {
        name: dto.name,
        isRoom: dto.is_room,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("domainSuccessfullyAdded"), { variant: "success" });
          setCreateDomain(false);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministratorMessage", { message: err.message }),
            { variant: "error" }
          );
          console.error("Add domain failure:", err);
        },
      }
    );
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        startIcon={<AddCircleOutline width={24} />}
        onClick={() => setCreateDomain(true)}
      >
        {t("newDomain")}
      </Button>
      <CreateDomainModal
        open={createDomain}
        onClose={() => setCreateDomain(false)}
        onSave={(dto) => handleSave(dto)}
      />
    </>
  );
};

export default CreateDomainTableControl;
