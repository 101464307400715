/*import { blue, gold, green } from "@ant-design/colors";
import {
  BugOutlined,
  FileUnknownOutlined,
  PauseCircleOutlined,
  RocketOutlined,
} from "@ant-design/icons";*/
import { Box, Button, CircularProgress, Divider, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
/*import { Descriptions, Spin, Tooltip, message } from "antd";*/
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSwitchGsModeMutation, useSwitchTesModeMutation } from "@/api/hooks/command-hooks";
import { usePlatformServicesFromAllRoomsQuery } from "@/api/hooks/platform-service-hooks";
import ASelectPopover from "@/components/ASelectPopover";
import TimeAgo from "@/components/time-ago";
import { getEnumNumberValues, mapExploitationStageToStringName } from "@/lib/common-utils";
import { COMMON_REFETCH_INTERVAL, ExploitationStage } from "@/lib/constants";
import { Route } from "@/routes/dashboard";
import { Route as ErrorPageRoute } from "@/routes/error/index";
import {
  PlatformServiceRegularDto,
  PlatformServiceRegularDtoTypeEnum,
  SwitchGsModeModeEnum,
  SwitchTesModeModeEnum,
} from "@/shared/api";
import RocketOutlinedIcon from "@mui/icons-material/RocketOutlined";
import { BugReportOutlined, DeviceUnknownOutlined, PauseCircleOutlineOutlined } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

const RunningServicesModule = () => {
  const { t } = useTranslation();
  const [refetchInterval, setRefetchInterval] = useState<number>(COMMON_REFETCH_INTERVAL);

  const {
    data: runningInstancesRs,
    isLoading: runningInstancesIsLoading,
    isError: runningInstancesIsError,
    error: runningInstancesError,
  } = usePlatformServicesFromAllRoomsQuery({
    refetchInterval: refetchInterval,
  });

  function startFastRefetch() {
    setRefetchInterval(1000);
    setTimeout(() => {
      setRefetchInterval(COMMON_REFETCH_INTERVAL);
    }, COMMON_REFETCH_INTERVAL); //in 15 sec
  }

  const runningInstances = useMemo(() => {
    return runningInstancesRs?.data ?? [];
  }, [runningInstancesRs]);

  const switchTesModeMutation = useSwitchTesModeMutation();
  const switchGsModeMutation = useSwitchGsModeMutation();

  const [changingExploitationStageService, setChangingExploitationStageService] = useState<
    string | undefined
  >(undefined);
  const [newExploitationStageValue, setNewExploitationStageValue] = useState<
    ExploitationStage | undefined
  >(undefined);
  const navigate = Route.useNavigate();

  useEffect(() => {
    if (runningInstancesIsError) {
      void navigate({
        to: ErrorPageRoute.fullPath,
        search: { errorMessage: runningInstancesError.message },
      });
    }
  }, [navigate, runningInstancesError, runningInstancesIsError]);

  if (runningInstancesIsLoading) {
    return <CircularProgress size="large" />;
  }

  function mapManageableServiceTitle(service: PlatformServiceRegularDto) {
    const icon = (() => {
      switch (service.mode) {
        case ExploitationStage.PRODUCTION:
          return (
            <Tooltip sx={{ position: "relative", top: "4px"}} title={t("production")}>
              <RocketOutlinedIcon style={{ color: "green" }} />
            </Tooltip>
          );
        case ExploitationStage.TEST:
          return (
            <Tooltip sx={{ position: "relative", top: "4px"}} title={t("testing")}>
              <BugReportOutlined style={{ color: "gold" }} />
            </Tooltip>
          );
        case ExploitationStage.UNAVAILABLE:
          return (
            <Tooltip sx={{ position: "relative", top: "4px"}} title={t("unavailable")}>
              <PauseCircleOutlineOutlined style={{ color: "blue" }} />
            </Tooltip>
          );
        default:
          return (
            <Tooltip sx={{ position: "relative", top: "4px"}} title={t("unknownMode")}>
              <DeviceUnknownOutlined />
            </Tooltip>
          );
      }
    })();

    return (
      <>
        {icon} {service.type} {service.room}
      </>
    );
  }

  function getServiceExploitationStageSelectOptions(currentStage: number | undefined) {
    return getEnumNumberValues(ExploitationStage)
      .filter((item) => item !== currentStage)
      .map((item) => ({ label: mapExploitationStageToStringName(item), value: item }));
  }

  function resetSwitchExploitationStageState() {
    setChangingExploitationStageService(undefined);
    setNewExploitationStageValue(undefined);
  }

  // TODO temporal solution while scaling isn't implemented
  function handleSwitchMode(service: PlatformServiceRegularDto, mode: SwitchTesModeModeEnum) {
    startFastRefetch();

    if (service.type === PlatformServiceRegularDtoTypeEnum.Tes)
      handleSwitchTesExploitationStage(service, mode);
    else handleSwitchGsExploitationStage(service, mode);

    function handleSwitchTesExploitationStage(
      service: PlatformServiceRegularDto,
      mode: SwitchTesModeModeEnum,
    ) {
      const id = service.id;

      switchTesModeMutation.mutate(
        {
          domain: service.room,
          mode: mode,
          tesId: id,
        },
        {
          onSuccess: () => {
            resetSwitchExploitationStageState();
            enqueueSnackbar<"success">(t("switchModeHasBeenSuccessfullyCompleted"));
          },
          onError: (err) => {
            enqueueSnackbar<"error">(t("somethingWentWrongWhileSwitchingMode"));
            console.error(`Something went wrong while while switching mode`, err);
          },
        },
      );
    }

    function handleSwitchGsExploitationStage(
      service: PlatformServiceRegularDto,
      mode: SwitchGsModeModeEnum,
    ) {
      const id = service.id;

      switchGsModeMutation.mutate(
        {
          domain: service.room,
          gsId: id,
          mode: mode,
        },
        {
          onSuccess: () => {
            resetSwitchExploitationStageState();
            enqueueSnackbar<"success">(t("switchModeHasBeenSuccessfullyCompleted"));
          },
          onError: (err) => {
            enqueueSnackbar<"error">(t("somethingWentWrongWhileSwitchingMode"));
            console.error(`Something went wrong while while switching mode`, err);
          },
        },
      );
    }
  }

  return (
    <>
      <Stack spacing={2}>
        {runningInstances.map((item) => (
          <Paper key={item.id} elevation={3} sx={{ p: 2 }}>
            <Box sx={{ position: "relative" }}>

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography variant="h6">{mapManageableServiceTitle(item)}</Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("id")}: </Box> {item.id ?? "-"}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("version")}: </Box> {item.version ?? "-"}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("mode")}: </Box> {item.mode ?? "-"}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("room")}: </Box> {item.room}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("serverPort")}: </Box> {item.server_port ?? "-"}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("type")}: </Box> {item.type}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("description")}: </Box> {item.description ?? "-"}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("serverAddress")}: </Box> {item.server_addr ?? "-"}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("uptime")}: </Box> {item.uptime ? <TimeAgo pastDate={item.uptime} /> : "-"}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("numberClients")}: </Box> {item.nclients || 0}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("numberThreads")}: </Box> {item.nthreads || 0}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("processId")}: </Box> {item.pid ?? "-"}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("processRAMConsuming")}: </Box>
                    {item.process_ram_total != null && item.process_ram_free != null
                      ? t("serverRamValue", {
                        free: (item.process_ram_total < 0 ? 0 : item.process_ram_total) - (item.process_ram_free < 0 ? 0 : item.process_ram_free),
                        total: item.process_ram_total < 0 ? 0 : item.process_ram_total,
                      })
                      : t("unknown")}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("serverRAMConsuming")}: </Box>
                    {item.server_ram_total != null && item.server_ram_free != null
                      ? t("serverRamValue", {
                        free: (item.server_ram_total < 0 ? 0 : item.server_ram_total) - (item.server_ram_free < 0 ? 0 : item.server_ram_free),
                        total: item.server_ram_total < 0 ? 0 : item.server_ram_total,
                      })
                      : t("unknown")}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <Box component="span" sx={{ color: "gray" }}>{t("cpuAvarageUsage")}: </Box>
                    <div>
                      {!item.cpu_load_avg ? "0" : Object.entries(item.cpu_load_avg).map(([key, value]) => (
                        <p key={key} style={{ margin: 0 }}>
                          {t("duringKey", { key, value: value === -1 ? "-" : value * 100 + "%" })}
                        </p>
                      ))}
                    </div>
                  </Typography>
                </Grid>
              </Grid>

              <div>
                <ASelectPopover
                  loading={
                    item.type === "TES"
                      ? switchTesModeMutation.isPending
                      : switchGsModeMutation.isPending
                  }
                  containerStyle={{ display: "flex" }}
                  buttonText={t("ok")}
                  disabled={newExploitationStageValue === undefined}
                  onOk={() =>
                    handleSwitchMode(
                      item,
                      newExploitationStageValue as unknown as SwitchTesModeModeEnum,
                    )
                  }
                  popoverProps={{
                    title: t("chooseMode"),
                    open: item.id === changingExploitationStageService,
                    trigger: "click",
                    onOpenChange: (popoverProps) => {
                      if (!popoverProps) {
                        resetSwitchExploitationStageState();
                      }
                    },
                  }}
                  selectProps={{
                    style: { width: 150 },
                    value: newExploitationStageValue,
                    onChange: (value) => setNewExploitationStageValue(value),
                    allowClear: true,
                    options: getServiceExploitationStageSelectOptions(item.mode),
                    placeholder: t("chooseMode"),
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setChangingExploitationStageService(item.id)}
                  >
                    {t("switchMode")}
                  </Button>
                </ASelectPopover>
              </div>
            </Box>
          </Paper>
        ))}
      </Stack>
    </>
  );
};

export default RunningServicesModule;
