
import { useBoardingTablesQuery } from "@/api/hooks/boarding-tables-hooks";
import { COMMON_REFETCH_INTERVAL } from "@/lib/constants";
import BoardingTablesTable from "./boarding-tables-table";

const BoardingTablesModule = () => {
  const tablesQuery = useBoardingTablesQuery({
    refetchInterval: COMMON_REFETCH_INTERVAL,
  });

  return (
    <BoardingTablesTable
      tablesQuery={tablesQuery}
    />
  );
};

export default BoardingTablesModule; 