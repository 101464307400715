import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CheckboxElement, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";


const UpdateNicknameModal = ({
  title,
  onCancel,
  confirmLoading = false,
  onSave,
  isOpen,
}: {
  title?: string;
  confirmLoading?: boolean;
  onCancel: () => void;
  onSave?: (customNickname?: string) => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation();
  const [isRandom, setIsRandom] = useState<boolean>(true);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const formValues = watch();

  const handleSaveClick = (data: any) => {
    onSave && onSave(data.nickname?.trim());
  };

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>
        {title && (
          <Typography variant="h6" style={{ marginTop: 15 }}>
            {title}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        {title && <Divider style={{ marginTop: 10, marginBottom: 30 }} />}
        <form onSubmit={handleSubmit(handleSaveClick)} autoComplete="off" key="addingMode">
          <TextFieldElement
            name="nickname"
            control={control}
            label={t("customNickname")}
            placeholder={t("newCustomNickname")}
            disabled={isRandom}
            rules={{ required: !isRandom }}
            fullWidth
            variant="standard"
            error={!!errors.nickname}
            helperText={errors.nickname?.message ? String(errors.nickname?.message) : " "}
          />
          <CheckboxElement
            name="isRandom"
            control={control}
            label={t("setRandomNicknameFromPool")}
            checked={isRandom}
            onChange={() => {
              setValue("nickname", undefined);
              setIsRandom(!isRandom);
            }}
            style={{ width: 25, height: 25 }}
          />
          <DialogActions>
            <Button onClick={onCancel}>{t("cancel")}</Button>
            <Button type="submit" disabled={confirmLoading}>
              {t("submit")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateNicknameModal;