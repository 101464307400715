import { FormatListNumbered } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CommandJournalTable from "@/module/command-journal-module/command-journal-table";

const CommandJournalAccessControl = () => {
  const { t } = useTranslation();
  const [isCommandJournalOpen, setIsCommandJournalOpen] = useState<boolean>(false);

  return (
    <>
      <Tooltip title={t("commandJournal")}>
        <IconButton
          size="large"
          sx={{ color: "inherit" }}
          onClick={() => setIsCommandJournalOpen(true)}
        >
          <FormatListNumbered />
        </IconButton>
      </Tooltip>

      {isCommandJournalOpen && (
        <CommandJournalTable onClose={() => setIsCommandJournalOpen(false)} />
      )}
    </>
  );
};

export default CommandJournalAccessControl;
