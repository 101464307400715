import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysAccountCreateAccountsMutation } from "@/api/hooks/sys-account-hooks";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import ImportAccountsModal from "@/components/config-modals/import-accounts-modal";
import { ResultDtoSysAccountRegularDto, SysAccountCreateDto } from "@/shared/api";
import { Button } from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

const ImportAccountsTableControl = ({
  roomName,
  onSuccess,
}: {
  roomName: string;
  onSuccess?: (data: ResultDtoSysAccountRegularDto[]) => void;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [operationResult, setOperationResult] = useState<ResultDtoSysAccountRegularDto[]>();

  const { mutate: createAccountsMutate, isPending: createAccountsIsPending } =
    useSysAccountCreateAccountsMutation();

  function handleCreateAccounts(accounts: SysAccountCreateDto[]) {
    createAccountsMutate(
      {
        domain: roomName,
        accounts: accounts,
      },
      {
        onSuccess: (response) => {
          setIsOpen(false);
          setOperationResult(response.data);
          onSuccess && onSuccess(response.data);
        },
        onError: (error) => {
          enqueueSnackbar(t("importAccountsTableControl.errorWhileCreatingElements"), {variant: "error"});
          console.error("Something went wrong while creating elements", error);
        },
      }
    );
  }

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <FileUploadOutlined /> {t("importCsv")}
      </Button>
      {isOpen && (
        <ImportAccountsModal
          title={t("addAccountsBulk")}
          confirmLoading={createAccountsIsPending}
          onSave={(accounts) => handleCreateAccounts(accounts)}
          onCancel={() => setIsOpen(false)}
        />
      )}
      {operationResult != null && (
        <BulkOperationReportModal
          open
          showProblematic
          showSuccess
          title={t("importAccountsTableControl.operationResultTitle")}
          resultList={operationResult}
          onCancel={() => setOperationResult(undefined)}
        />
      )}
    </>
  );
};

export default ImportAccountsTableControl;
