import { Col, Row, Typography } from "antd";
import { useAtom, useAtomValue } from "jotai/index";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getPublicUrl } from "@/lib/common-utils";
import { headerTitle, isDarkMode } from "@/store/store";

const HomePage = () => {
  const { t } = useTranslation();

  const [, setHeaderTitle] = useAtom(headerTitle);
  const isDark = useAtomValue(isDarkMode);

  const logoName = useMemo(() => (isDark ? "main_logo_light.png" : "main_logo.png"), [isDark]);

  useEffect(() => {
    setHeaderTitle(t("home"));
  }, [setHeaderTitle, t]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        align="middle"
        justify="center"
        dir="col"
        style={{ height: "100%" }}
      >
        <Col>
          <Row gutter={[16, 16]} align="middle" justify="center">
            <Col>
              <img
                src={`${getPublicUrl()}/${logoName}`}
                alt="Main logo"
                style={{ width: "100%", maxWidth: "350px" }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]} align="middle" justify="center">
            <Col>
              <Typography.Title level={1}>{t("asiaProjectAdminPanel")}</Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default HomePage;
