import { MaterialReactTable } from "material-react-table";

import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import AiProfileConfigTable from "@/module/room-configs-module/ai-profile-config-table";
import ClusteringPatternModule from "@/module/room-configs-module/clustering-pattern-module/clustering-pattern-module";
import ClusteringTypeConfigTable from "@/module/room-configs-module/clustering-type-config-table";
import CommonConfigTable from "@/module/room-configs-module/common-config-table";
import CompensatorConfigTable from "@/module/room-configs-module/compensator/compensator-config-table";
import RouterConfigModule from "@/module/room-configs-module/router-config-module/router-config-module";
import BoardingPlayerSessionsModule from "@/module/service-configs-module/boarding-player-sessions-module/boarding-player-sessions-module";
import BoardingTablesModule from "@/module/service-configs-module/boarding-tables-module/boarding-tables-module";
import EmailDomainConfigTable from "@/module/service-configs-module/email-domain-module/email-domain-config-table";
import PlayersReporterModule from "@/module/service-configs-module/players-reporter-module/players-reporter-module";
import StatserviceModule from "@/module/service-configs-module/statservice-module/statservice-module";
import TableCacheModule from "@/module/service-configs-module/table-cache-module/table-cache-module";

const MainTable = ({ cfg }: { cfg: string }) => {
  if (!cfg) {
    return <MaterialReactTable columns={[]} data={[]} />;
  }

  switch (cfg) {
    case ROOM_CONFIGS_NAMES.ROUTER:
      return <RouterConfigModule />;
    case ROOM_CONFIGS_NAMES.AI_PROFILE:
      return <AiProfileConfigTable />;
    case ROOM_CONFIGS_NAMES.CLUSTERING_TYPE:
      return <ClusteringTypeConfigTable />;
    case ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN:
      return <ClusteringPatternModule />;
    case ROOM_CONFIGS_NAMES.COMPENSATOR:
      return <CompensatorConfigTable />;
    case ROOM_CONFIGS_NAMES.EMAIL_DOMAIN:
      return <EmailDomainConfigTable />;
    case ROOM_CONFIGS_NAMES.STAT_SERVICE:
      return <StatserviceModule />;
    case ROOM_CONFIGS_NAMES.TABLE_CACHE:
      return <TableCacheModule />;
    case ROOM_CONFIGS_NAMES.PLAYERS_REPORTER:
      return <PlayersReporterModule />;
    case ROOM_CONFIGS_NAMES.BOARDING_TABLE:
      return <BoardingTablesModule />;
    case ROOM_CONFIGS_NAMES.BOARDING_PLAYER_SESSIONS:
      return <BoardingPlayerSessionsModule />;
    default:
      return <CommonConfigTable room={ROOM_NAMES.SERVICES} cfg={cfg} />;
  }
};

export default MainTable;
