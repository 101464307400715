import { createTheme, responsiveFontSizes } from "@mui/material";

declare module "@mui/material/styles" {
  // interface Palette {
  //   mrtTable: Palette["primary"];
  // }

  interface PaletteOptions {
    mrtTable?: PaletteOptions["primary"];
  }
}

const regularTheme = createTheme({
  palette: {
    mode: "light",
  },
});

export default responsiveFontSizes(regularTheme);
