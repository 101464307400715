/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// May contain unused imports in some cases
// @ts-ignore
import { DefaultGameLimit } from "./default-game-limit";
// May contain unused imports in some cases
// @ts-ignore
import { FillingRule } from "./filling-rule";
// May contain unused imports in some cases
// @ts-ignore
import { Player } from "./player";
// May contain unused imports in some cases
// @ts-ignore
import { Rule } from "./rule";

/**
 *
 * @export
 * @interface BoardingTable
 */
export interface BoardingTable {
  /**
   *
   * @type {string}
   * @memberof BoardingTable
   */
  room: string;
  /**
   *
   * @type {string}
   * @memberof BoardingTable
   */
  tid: string;
  /**
   *
   * @type {string}
   * @memberof BoardingTable
   */
  gameType: string;
  /**
   *
   * @type {string}
   * @memberof BoardingTable
   */
  gameGroup: string;
  /**
   *
   * @type {string}
   * @memberof BoardingTable
   */
  aid: string;
  /**
   *
   * @type {string}
   * @memberof BoardingTable
   */
  cid: string;
  /**
   *
   * @type {number}
   * @memberof BoardingTable
   */
  limit: number;
  /**
   *
   * @type {FillingRule}
   * @memberof BoardingTable
   */
  fillingRule: FillingRule;
  /**
   *
   * @type {Array<Player>}
   * @memberof BoardingTable
   */
  players: Array<Player>;
  /**
   *
   * @type {number}
   * @memberof BoardingTable
   */
  lastBoardingTimestamp: number;
  /**
   *
   * @type {number}
   * @memberof BoardingTable
   */
  lastExitTime: number;
  /**
   *
   * @type {number}
   * @memberof BoardingTable
   */
  minHandsAtProfit: number;
  /**
   *
   * @type {number}
   * @memberof BoardingTable
   */
  minHandsAfterAllin: number;
  /**
   *
   * @type {number}
   * @memberof BoardingTable
   */
  tableLeftTimeLimit: number;
  /**
   *
   * @type {string}
   * @memberof BoardingTable
   */
  boardingMode: BoardingTableBoardingModeEnum;
  /**
   *
   * @type {Rule}
   * @memberof BoardingTable
   */
  rule?: Rule;
  /**
   *
   * @type {DefaultGameLimit}
   * @memberof BoardingTable
   */
  defaultRule?: DefaultGameLimit;
}

export const BoardingTableBoardingModeEnum = {
  Manual: "MANUAL",
  Auto: "AUTO",
} as const;

export type BoardingTableBoardingModeEnum =
  (typeof BoardingTableBoardingModeEnum)[keyof typeof BoardingTableBoardingModeEnum];
