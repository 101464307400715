import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Navigate } from "@tanstack/react-router";
import { useAtom } from "jotai/index";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import React, { useEffect, useMemo } from "react";

import { useMeQuery } from "@/api/hooks/auth-hooks";
import { useSysDomainsQuery } from "@/api/hooks/sys-domain-hooks";
import ACenteredSpinner from "@/components/ACenteredSpinner";
import CommandJournalAccessControl from "@/components/command-journal-access-control";
import { LocaleSwitcher } from "@/components/locale-switcher";
import AppMainSidebar from "@/components/sidebar/app-main-sidebar";
import ThemeModeSwitcher from "@/components/theme-mode-switcher";
import { Route } from "@/routes/__root";
import { appCurrentRoom, headerTitle, isDarkMode, meStore } from "@/store/store";

const Layout = styled("div")(() => ({
  display: "flex",
  width: "100%",
  height: "100vh",
  minHeight: "100vh",
  maxHeight: "100vh",
  overflow: "hidden",
}));

const Content = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  minHeight: "100vh",
  maxHeight: "100vh",
  overflow: "hidden",
  flexGrow: 1,
}));

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: 16,
  overflow: "scroll",
  backgroundColor: theme.palette.background.paper,
}));

const CommonTemplate = ({ children }: { children: React.JSX.Element }) => {
  const { data: meRs } = useMeQuery();
  const [isDark, setIsDark] = useAtom(isDarkMode);

  const {
    data: domainsRs,
    isLoading: domainsIsLoading,
    isError,
    error,
  } = useSysDomainsQuery(false, {
    refetchOnWindowFocus: false,
  });
  const params: { [key: string]: string } = Route.useParams();
  const [currentRoom, setCurrentRoom] = useAtom(appCurrentRoom);
  const [, setMe] = useAtom(meStore);
  const [header] = useAtom(headerTitle);

  useEffect(() => {
    if (meRs?.data) {
      setMe(meRs.data);
    }
  }, [meRs, setMe]);

  useEffect(() => {
    if (currentRoom !== undefined) return;

    const roomParam = params.room;

    if (domainsIsLoading || !domainsRs) return;

    const domains = domainsRs.data;

    if (isError) {
      setCurrentRoom(null);
      return;
    }

    if (!roomParam) {
      if (domains.length === 1) {
        setCurrentRoom(domains[0]);
      } else {
        setCurrentRoom(null);
      }
      return;
    }

    const room = domains.find((it) => it.name === roomParam);

    if (!room) {
      setCurrentRoom(null);
      return;
    }

    setCurrentRoom(room);
  }, [currentRoom, domainsIsLoading, domainsRs, isError, params, setCurrentRoom]);

  return (
    <>
      <Layout>
        <AppMainSidebar domains={domainsRs?.data ?? []} />
        <Content>
          <AppBar position="static">
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">{header}</Typography>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CommandJournalAccessControl />
                <ThemeModeSwitcher
                  checked={isDark}
                  onChange={(e) => setIsDark(e.target.checked)}
                />
                <LocaleSwitcher />
              </Box>
            </Toolbar>
          </AppBar>
          <Main>
            {isError ? (
              <Navigate
                to="/error/"
                search={{
                  errorMessage: error
                    ? error.message
                    : "unknown error, contact an administrator",
                }}
              />
            ) : currentRoom === undefined ? (
              <ACenteredSpinner />
            ) : (
              children
            )}
          </Main>
        </Content>
      </Layout>
    </>
  );
};

export default CommonTemplate;
