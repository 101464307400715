import { ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createHashHistory, createRouter } from "@tanstack/react-router";
import { ConfigProvider } from "antd";
import { theme as antdTheme } from "antd";
import { useAtom } from "jotai/index";
import { SnackbarProvider } from "notistack";
import React, { Suspense, useEffect, useMemo } from "react";

import { routeTree } from "@/routeTree.gen";
import { isDarkMode } from "@/store/store";
import dark from "@/theme/dark";
import regular from "@/theme/regular";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const hashHistory = createHashHistory();
const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultPreload: "intent",
  defaultPreloadStaleTime: 0,
  history: hashHistory,
});

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      );

const App = () => {
  const [isDark] = useAtom(isDarkMode);
  const theme = useMemo(() => (isDark ? dark : regular), [isDark]);

  useEffect(() => {
    console.log("theme changed");
  }, [theme]);

  return (
    <SnackbarProvider>
      <ConfigProvider
        theme={{ algorithm: isDark ? antdTheme.darkAlgorithm : antdTheme.defaultAlgorithm }}
      >
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
              <Suspense>
                <TanStackRouterDevtools router={router} />
              </Suspense>
            </QueryClientProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </ConfigProvider>
    </SnackbarProvider>
  );
};

export default App;
