import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Form, FormInstance, Space } from "antd";
import { useTranslation } from "react-i18next";

import SpecificControl from "./specific-control";
import { Path } from "./types/base-types";
import { SimpleDynamicObjectArrayType } from "./types/wrapper-types";
import {
  calculateWrapperInitialValues,
  computeWrapperLabel,
  computeWrapperTitle,
} from "./util";

const SimpleDynamicObjectArray = ({
  control,
  parentPath = [],
  disabled = false,
  form,
}: {
  control: SimpleDynamicObjectArrayType;
  parentPath?: Path;
  disabled?: boolean;
  form: FormInstance;
}) => {
  const { t } = useTranslation();

  const content = (
    <>
      <Form.Item label={computeWrapperLabel(control, control.label)} labelCol={{ span: 24 }}>
        {computeWrapperTitle(control.label)}
        <Form.List name={[...parentPath, ...control.path]}>
          {(subFields, subOpt) => (
            <Space direction="vertical">
              {subFields.map((subField) => (
                <Space
                  key={subField.key}
                  // align="end"
                  direction="horizontal"
                >
                  {control.elements.map((elem, index) => (
                    <SpecificControl
                      key={index}
                      control={elem}
                      parentPath={[subField.name]}
                      form={form}
                    />
                  ))}
                  <CloseOutlined
                    style={{ marginTop: 13 }}
                    onClick={() => !disabled && subOpt.remove(subField.name)}
                  />
                </Space>
              ))}
              <Button
                type="dashed"
                disabled={disabled}
                onClick={() => subOpt.add(calculateWrapperInitialValues(control))}
                block
              >
                {t("addSubItems")}
              </Button>
            </Space>
          )}
        </Form.List>
      </Form.Item>
    </>
  );

  if (!control.path) {
    return <>{t("simpleDynamicObjectArrayHasIncorrectPath", { path: control.path })}</>;
  }

  if (control.path.length < 1) {
    return <>{t("simpleDynamicObjectArrayPathMustHaveAtLeastOnePathElement")}</>;
  }

  if (typeof control.path[control.path.length - 1] !== "string") {
    return <>{t("lastPathElementSimpleDynamicObjectArrayMustBeStringValue")}</>;
  }

  return (
    <>
      {!control.noWrapper ? (
        <Card type="inner" title={control.label?.text}>
          <Space direction="vertical">{content}</Space>
        </Card>
      ) : (
        content
      )}
    </>
  );
};

export default SimpleDynamicObjectArray;
