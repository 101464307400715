import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";



import { dataFieldName } from "@/components/config-modals/clusterizer-edit-modal/constants";


const RequestReplayModal = ({
  title,
  onCancel,
  confirmLoading = false,
  onSave,
  isOpen,
}: {
  title?: string;
  confirmLoading?: boolean;
  onCancel: () => void;
  onSave: (hid: string, tid: string) => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleSaveClick = (data: any) => {
    if (!onSave) return;
    onSave(data[dataFieldName]?.hid, data[dataFieldName]?.tid);
  };

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>
        {title && (
          <Typography variant="h6" style={{ marginTop: 15 }}>
            {title}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        {title && <Divider style={{ marginTop: 10, marginBottom: 30 }} />}
        <form
          onSubmit={handleSubmit(handleSaveClick)}
          autoComplete="off"
          key="requestReplyForm"
        >
          <Controller
            name={`${dataFieldName}.hid`}
            control={control}
            rules={{ required: t("hidRequired") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={{ mb: 5 }}
                {...field}
                label={t("hid")}
                placeholder={t("hid")}
                fullWidth
                type="number"
                error={!!error?.message}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name={`${dataFieldName}.tid`}
            control={control}
            rules={{ required: t("tidRequired") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("tid")}
                placeholder={t("tid")}
                fullWidth
                type="number"
                error={!!error?.message}
                helperText={error?.message}
              />
            )}
          />
          <DialogActions>
            <Button onClick={onCancel}>{t("cancel")}</Button>
            <Button type="submit" disabled={confirmLoading}>
              {t("submit")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RequestReplayModal;