import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { KeyFilter, SwitchGsModeModeEnum, SwitchTesModeModeEnum } from "@/shared/api";
import { RequiredError } from "@/shared/api/base";
import { useApi } from "@/shared/api/use-api";

export const useRunAccountsMutation = () => {
  const { commandApi } = useApi();
  type Options = {
    domain: string;
    tesId: string;
    filters?: Set<KeyFilter>;
  };

  const mutationFn = ({ domain, tesId, filters }: Options) => {
    return commandApi.runAccounts(domain, tesId, { filters });
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useMigrateSessionsMutation = () => {
  const { commandApi } = useApi();

  type Options = {
    domain: string;
    gsId: string;
    filters?: Set<KeyFilter>;
  };

  const mutationFn = ({ domain, gsId, filters }: Options) => {
    return commandApi.migrateAccounts(domain, gsId, { filters });
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useStopSessionsMutation = () => {
  const { commandApi } = useApi();

  type Options = {
    domain: string;
    filters?: Set<KeyFilter>;
  };

  const mutationFn = ({ domain, filters }: Options) => {
    return commandApi.stopAccounts(domain, { filters });
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useJoinClubsMutation = () => {
  const { commandApi } = useApi();

  type Options = {
    domain: string;
    clubs: Set<string>;
    filters?: Set<KeyFilter>;
  };

  const mutationFn = ({ domain, clubs, filters }: Options) => {
    return commandApi.joinAccountsToClubs(domain, { clubs, filters: { filters } });
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useLeaveClubsMutation = () => {
  const { commandApi } = useApi();

  type Options = {
    domain: string;
    clubs: Set<string>;
    filters?: Set<KeyFilter>;
  };

  const mutationFn = ({ domain, clubs, filters }: Options) => {
    return commandApi.accountsLeaveClubs(domain, { clubs, filters: { filters } });
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useRequestReplayMutation = () => {
  const { commandApi } = useApi();

  type Options = {
    domain: string;
    sessionId: string;
    tid: string;
    hid: number;
  };

  const mutationFn = ({ domain, sessionId, tid, hid }: Options) => {
    return commandApi.requestReplay(domain, sessionId, { tid, hid });
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useUpdateNicknameMutation = () => {
  const { commandApi } = useApi();

  type Options = {
    domain: string;
    sessionId: string;
    newNickname?: string;
  };

  const mutationFn = ({ domain, sessionId, newNickname }: Options) => {
    return commandApi.updateAccountNickname(domain, sessionId, { new_nickname: newNickname });
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useUpdateNicknamesMutation = () => {
  const { commandApi } = useApi();

  type Options = {
    domain: string;
    filters?: Set<KeyFilter>;
  };

  const mutationFn = ({ domain, filters }: Options) => {
    return commandApi.updateAccountsNicknames(domain, { filters });
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useUpdateAvatarMutation = () => {
  const { commandApi } = useApi();

  type Options = {
    domain: string;
    sessionId: string;
    avatar?: File;
  };

  const mutationFn = ({ domain, sessionId, avatar }: Options) => {
    return commandApi.updateAccountAvatar(domain, sessionId, avatar ? { avatar } : undefined);
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useUpdateAvatarsMutation = () => {
  const { commandApi } = useApi();

  type Options = {
    domain: string;
    filters?: Set<KeyFilter>;
  };

  const mutationFn = ({ domain, filters }: Options) => {
    return commandApi.updateAccountsAvatars(domain, { filters });
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useSwitchTesModeMutation = () => {
  const { commandApi } = useApi();

  type Options = {
    domain: string;
    mode: SwitchTesModeModeEnum;
    tesId: string;
  };

  const mutationFn = ({ domain, mode, tesId }: Options) => {
    return commandApi.switchTesMode(domain, tesId, mode);
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useSwitchGsModeMutation = () => {
  const { commandApi } = useApi();

  type Options = {
    domain: string;
    mode: SwitchGsModeModeEnum;
    gsId: string;
  };

  const mutationFn = ({ domain, mode, gsId }: Options) => {
    return commandApi.switchGsMode(domain, gsId, mode);
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};
