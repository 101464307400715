import { Divider, Form, Input, Modal, Radio, Select, Typography, message } from "antd";
import { useAtom } from "jotai/index";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { isOwner } from "@/lib/auth-predicates";
import { isValidPort, trimSplitCsvRecord } from "@/lib/common-utils";
import { meStore } from "@/store/store";

type AddingMode = "one" | "list" | "csv";
type CsvDelimiter = "," | ";";

const socks5AddressRegExp = /^socks5:\/\/\S+:\S+@\S+:\d+$/gm;
const anySymbolBesidesSpaceSymbolsRegExp = /^\S+$/;
const entireStringMatchingIpAddressRegExp =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

const ProxyAddModal = ({
  open,
  title,
  confirmLoading = false,
  onCancel,
  onSave,
}: {
  open: boolean;
  title?: string;
  confirmLoading?: boolean;
  onCancel?: () => void;
  onSave?: (authGroups: string[], value: string[]) => void;
}) => {
  const { t } = useTranslation();
  const [me] = useAtom(meStore);

  const [addingMode, setAddingMode] = useState<AddingMode>("one");
  const [csvDelimiter, setCsvDelimiter] = useState<CsvDelimiter>(",");

  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);

  const { data: authGroupsRs } = useAuthGroupsQuery();

  useEffect(() => {
    if (authGroupsRs?.data.length === 1 && !isOwner(me)) {
      form.setFieldValue(
        "authGroups",
        authGroupsRs.data.map((it) => it.name)
      );
    }
  }, [authGroupsRs, form, me]);

  const authGroupsOptions = useMemo(() => {
    return (
      authGroupsRs?.data
        .map((i) => ({ label: i.name, value: i.name }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [authGroupsRs?.data]);

  function handleChangeAddingMode(value: AddingMode) {
    setAddingMode(value);
  }

  function handleChangeDelimiter(value: CsvDelimiter) {
    setCsvDelimiter(value);
  }

  function handleSaveClick() {
    form
      .validateFields({ validateOnly: false })
      .then(() => {
        const authGroups: string[] = formValues["authGroups"] ?? [];
        let value: string = formValues[addingMode];

        if (addingMode === "csv") value = convertCsvInputToProxyAddress(value);

        const match = value.match(socks5AddressRegExp);

        if (match === null) {
          message.error(t("somethingWentWrongWhileSavingConfig")).then();
          console.error(
            "Something went wrong while saving config, " +
              "array of matching by regexp pattern is null or undefined"
          );
          return;
        }

        const regExpMatchesArray: string[] = match;

        if (onSave) onSave(authGroups, regExpMatchesArray);
      })
      .catch((err) => console.error(err));
  }

  function proxyListValidator(value: any) {
    if (!value || !value.trim()) return Promise.reject(new Error(t("proxyAddressesRequired")));

    if (typeof value !== "string")
      return Promise.reject(new Error(t("proxyAddressMustBeString")));

    if (!value.trim()) {
      return Promise.reject(new Error(t("proxyAddressesRequired")));
    }

    if (socks5AddressRegExp.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(
      t("noStatementsMatchPattern", {
        pattern: socks5AddressRegExp,
      })
    );
  }

  function proxyCsvValidator(value: any) {
    if (!value) {
      return Promise.reject(new Error(t("proxiesRequired")));
    }

    if (typeof value !== "string") {
      return Promise.reject(new Error(t("proxiesMustBeString")));
    }

    if (!value.trim()) {
      return Promise.reject(new Error(t("proxiesRequired")));
    }

    const records = value.split("\n");

    for (const record of records) {
      const validationMessage = validateProxyCsvRecord(record);

      if (validationMessage !== undefined)
        return Promise.reject(
          new Error(
            t("invalidRecordInInput", {
              validationMessage,
              record,
            })
          )
        );
    }

    return Promise.resolve();
  }

  function validateProxyCsvRecord(record: string): string | undefined {
    const values = trimSplitCsvRecord(record, csvDelimiter);

    if (values.length != 4) {
      return t("recordMustHaveValues");
    }

    if (!anySymbolBesidesSpaceSymbolsRegExp.test(values[0])) {
      return t("usernameNotValid");
    }

    if (!anySymbolBesidesSpaceSymbolsRegExp.test(values[1])) {
      return t("passwordNotValid");
    }

    if (!entireStringMatchingIpAddressRegExp.test(values[2])) {
      return t("ipAddressNotValid");
    }

    if (!isValidPort(values[3])) {
      return t("portNotValid");
    }

    return undefined;
  }

  function convertCsvInputToProxyAddress(input: string) {
    const records = input.split("\n");
    return records.map((item) => convertCsvRecordToProxyAddress(item)).join("\n");
  }

  function convertCsvRecordToProxyAddress(record: string): string {
    const values = trimSplitCsvRecord(record, csvDelimiter);
    return `socks5://${values[0]}:${values[1]}@${values[2]}:${values[3]}`;
  }

  return (
    <>
      <Modal
        open={open}
        okText="Add"
        onCancel={() => onCancel && onCancel()}
        maskClosable={false}
        width={600}
        confirmLoading={confirmLoading}
        onOk={() => handleSaveClick()}
        title={
          title && (
            <Typography.Title level={3} style={{ marginTop: 15 }}>
              {title}
            </Typography.Title>
          )
        }
      >
        {title && <Divider style={{ marginTop: 10, marginBottom: 30 }} />}

        <Form size="large" className="a-common-form">
          <Form.Item label={t("chooseAddingType")}>
            <Radio.Group
              onChange={(e) => handleChangeAddingMode(e.target.value)}
              value={addingMode}
            >
              <Radio.Button value="one">{t("one")}</Radio.Button>
              <Radio.Button value="list">{t("list")}</Radio.Button>
              <Radio.Button value="csv">{t("csv")}</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>

        <Divider />

        <Form
          size="large"
          className="a-common-form"
          form={form}
          autoComplete="off"
          key={addingMode}
        >
          <Form.Item
            name="authGroups"
            label={t("authGroups")}
            rules={[{ required: !isOwner(me) }]}
          >
            <Select
              options={authGroupsOptions}
              style={{ width: "100%" }}
              mode="multiple"
              placeholder={t("authGroups")}
              disabled={authGroupsRs?.data.length === 1 && !isOwner(me)}
            />
          </Form.Item>

          {addingMode === "one" && (
            <Form.Item
              name="one"
              label={t("proxyAddress")}
              rules={[{ required: true }, { pattern: socks5AddressRegExp }]}
            >
              <Input placeholder={t("typeFullProxyAddress")} />
            </Form.Item>
          )}

          {addingMode === "list" && (
            <Form.Item
              name="list"
              label={t("proxyAddresses")}
              rules={[
                {
                  required: true,
                  validator: (_, value) => proxyListValidator(value),
                },
              ]}
            >
              <Input.TextArea
                rows={30}
                placeholder="socks5://{username}:{password}@{ip}:{port}"
              />
            </Form.Item>
          )}

          {addingMode === "csv" && (
            <>
              <Form.Item label={t("delimiter")}>
                <Radio.Group
                  onChange={(e) => handleChangeDelimiter(e.target.value)}
                  value={csvDelimiter}
                >
                  <Radio.Button value=",">,</Radio.Button>
                  <Radio.Button value=";">;</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="csv"
                label={t("proxies")}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => proxyCsvValidator(value),
                  },
                ]}
              >
                <Input.TextArea
                  rows={30}
                  placeholder={`username${csvDelimiter}password${csvDelimiter}ip${csvDelimiter}port`}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ProxyAddModal;
