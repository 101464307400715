import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SysCfgRegularDto } from "@/shared/api";

export const useEmailDomainTableColumns = () => {
  const { t } = useTranslation();

  return useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        header: t("emailDomainTable.name"),
        accessorKey: "name",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
    ],
    [t]
  );
};
