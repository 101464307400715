import { AxiosError, AxiosResponse } from "axios";
import { enqueueSnackbar } from "notistack";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  useSysAccountCreateAccountsMutation,
  useSysAccountGenerateAccountsByNumberMutation,
} from "@/api/hooks/sys-account-hooks";
import { innerStateOfConfigIsNotSatNotify } from "@/lib/notify";
import {
  GenerateAccountsDto,
  ResultDtoSysAccountRegularDto,
  SysAccountCreateDto,
} from "@/shared/api";

type HookOptions = {
  roomName: string;
  successCallback?: (response?: AxiosResponse<ResultDtoSysAccountRegularDto[]>) => void;
};

export const useSaveNewAccount = ({ roomName, successCallback = undefined }: HookOptions) => {
  const { t } = useTranslation();

  const mutation = useSysAccountCreateAccountsMutation();

  const callback = useCallback(
    (data: SysAccountCreateDto) => {
      if (!data) {
        innerStateOfConfigIsNotSatNotify();
        return;
      }

      mutation.mutate(
        {
          domain: roomName,
          accounts: [data],
        },
        {
          onSuccess: (response) => {
            successCallback && successCallback(response);
          },
          onError: (err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            console.error("save account failure: ", err);
          },
        }
      );
    },
    [mutation, roomName, successCallback, t]
  );

  return {
    ...mutation,
    mutate: callback,
  };
};

export const useGenerateNewAccounts = ({
  roomName,
  successCallback = undefined,
}: HookOptions) => {
  const { t } = useTranslation();
  const mutation = useSysAccountGenerateAccountsByNumberMutation();

  const callback = useCallback(
    (data: GenerateAccountsDto) => {
      if (!data) {
        innerStateOfConfigIsNotSatNotify();
        return;
      }

      mutation.mutate(
        {
          domain: roomName,
          generateAccountsBuNumberDto: data,
        },
        {
          onSuccess: (response) => {
            enqueueSnackbar(t("newAccountsSuccessfullyGenerated"), { variant: "success" });
            successCallback && successCallback();
          },
          onError: (err) => {
            enqueueSnackbar(
              (err as unknown as AxiosError<{ message: string }>).response?.data?.message ??
                t("somethingWentWrongWhileGenerationNewConfigs"),
              { variant: "error" }
            );
            console.error("generate account failure: ", err);
          },
        }
      );
    },
    [mutation, roomName, successCallback, t]
  );

  return {
    ...mutation,
    mutate: callback,
  };
};
