import { zodResolver } from "@hookform/resolvers/zod";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useAtom } from "jotai/index";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { CheckboxElement, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import CustomSelectOption from "@/components/custom-select-option";
import { isOwner } from "@/lib/auth-predicates";
import { SysCfgCreateRequestDto, SysCfgRegularDto } from "@/shared/api";
import { meStore } from "@/store/store";
import { MakeOptional } from "@/types/commonTypes";

interface ScheduleData {
  since: string,
  until: string,
  days: ("Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday")[];
}

const ScheduleModal = ({
  isOpen,
  mode,
  onCancel,
  onSave,
  defaultValues,
  confirmLoading,
  disabled = false,
}: {
  isOpen: boolean;
  mode: "add" | "edit";
  onCancel: () => void;
  onSave: (config: SysCfgCreateRequestDto) => void;
  defaultValues?: MakeOptional<SysCfgRegularDto, "id" | "created_at" | "updated_at">;
  confirmLoading?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [me] = useAtom(meStore);

  const schema = useMemo(() => {
    return z
      .object({
        name: z.string().min(1, t("fieldRequired")),
        auth_groups: isOwner(me)
          ? z.array(z.string())
          : z.array(z.string()).min(1, t("mustContainsGroup")),
        enabled: z.boolean().default(true),
        since: z.string().regex(/^([01]\d|2[0-3]):([0-5]\d|60)$/, {
          message: "Invalid time format. Expected format is HH:MM, where HH is 00-24 and MM is 00-60.",
        }),
        until: z.string().regex(/^([01]\d|2[0-3]):([0-5]\d|60)$/, {
          message: "Invalid time format. Expected format is HH:MM, where HH is 00-24 and MM is 00-60.",
        }),
        days: z.array(z.enum(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]))
          .min(1, t("fieldRequired")),
      })
  }, [me, t]);

  type Form = z.infer<typeof schema>;

  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery({
    enabled: isOpen,
  });

  const authGroups = useMemo(() => {
    return authGroupsRs?.data ?? [];
  }, [authGroupsRs]);

  const initData = useMemo<Partial<Form>>(() => {
    let groups = defaultValues ? Array.from(defaultValues.auth_groups ?? []) : [];

    if (authGroups.length === 1 && !isOwner(me)) {
      groups = authGroups.map((it) => it.name);
    }

    const data = defaultValues?.data as ScheduleData | undefined;

    return {
      name: defaultValues?.name || "",
      auth_groups: groups,
      enabled: defaultValues?.enabled ?? true,
      since: data?.since ?? "00:00",
      until: data?.until ?? "00:00",
      days: data?.days ?? [],
    };
  }, [defaultValues, authGroups, me]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    mode: "onChange",
    disabled: disabled,
    resolver: zodResolver(schema),
    defaultValues: initData,
  });

  const submit = handleSubmit((data) => {
    const configData: ScheduleData = {
      since: data.since,
      until: data.until,
      days: data.days,
    };

    onSave({
      name: data.name,
      auth_groups: new Set(data.auth_groups),
      enabled: data.enabled,
      data: configData,
    });
  });

  useEffect(() => {
    reset(initData);
  }, [initData, isOpen, reset]);

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="md" fullWidth>
      <DialogTitle>
        {mode === "add" ? t("addNewConfig") : t("editConfig", { name: initData.name })}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ paddingTop: "16px" }}>
          <TextFieldElement
            control={control}
            required
            name="name"
            label={t("name")}
            error={!!errors.name}
            helperText={errors.name?.message ?? " "}
            variant="outlined"
            fullWidth
            // disabled={mode === "edit"}
            InputProps={{
              disabled: mode === "edit",
              // readOnly: mode === "edit",
            }}
          />

          <Controller
            control={control}
            name="auth_groups"
            rules={{
              required: !isOwner(me) ? "field is required" : undefined,
            }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                multiple
                disabled={disabled || (authGroupsRs?.data.length === 1 && !isOwner(me))}
                loading={authGroupsIsLoading}
                value={field.value}
                onChange={(_, data) => field.onChange(data)}
                options={
                  authGroupsRs?.data?.map((i) => i.name).sort((a, b) => a.localeCompare(b)) ??
                  []
                }
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <CustomSelectOption label={option} selected={selected} props={props} />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={!isOwner(me) ? t("authGroupsRequired") : t("authGroups")}
                    placeholder={t("authGroups")}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />

          {mode === "add" && (
            <CheckboxElement
              control={control}
              name="enabled"
              label={t("enabled")}
              disabled={disabled}
            />
          )}

          <Stack direction="row" spacing={2}>
            <TextFieldElement
              control={control}
              name="since"
              label={t("since")}
              fullWidth
              error={!!errors.since}
              helperText={errors.since?.message}
            />
            <TextFieldElement
              control={control}
              name="until"
              label={t("until")}
              fullWidth
              error={!!errors.until}
              helperText={errors.until?.message}
            />
          </Stack>

          <Controller
            control={control}
            name="days"
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                multiple
                disabled={disabled}
                value={field.value}
                onChange={(_, data) => field.onChange(data)}
                options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <CustomSelectOption label={option} selected={selected} props={props} />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("days")}
                    placeholder={t("days")}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {t("cancel")}
        </Button>

        <Button
          variant="contained"
          disabled={disabled || confirmLoading}
          startIcon={confirmLoading ? <CircularProgress size={20} /> : undefined}
          onClick={submit}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleModal;

