import { Box } from "@mui/material";
import { MRT_TableInstance } from "material-react-table";
import React from "react";

import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import ChangeEnabledConfigsTableControl from "@/components/change-enabled-configs-table-control";
import DeleteConfigsTableControl from "@/components/delete-configs-table-control";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import CopyAccountTableControl from "@/module/accounts-module/components/copy-account-table-control";
import CreateAccountTableControl from "@/module/accounts-module/components/create-account-table-control";
import EditAccountTableControl from "@/module/accounts-module/components/edit-account-table-control";
import EditAccountsTableControl from "@/module/accounts-module/components/edit-accounts-table-control";
import ExportAccountsCsvTableControl from "@/module/accounts-module/components/export-accounts-csv-table-control";
import GenerateAccountsTableControl from "@/module/accounts-module/components/generate-accounts-table-control";
import ImportAccountsTableControl from "@/module/accounts-module/components/import-accounts-table-control";
import RunAccountsTableControl from "@/module/accounts-module/components/run-accounts-table-control";
import { KeyFilter, SysAccountRegularDto } from "@/shared/api";

const AccountsTopToolbar = ({
  roomName,
  table,
  filters,
  refetchAccounts,
  totalElementsCount,
}: {
  table: MRT_TableInstance<SysAccountRegularDto>;
  roomName: string;
  filters: Set<KeyFilter>;
  refetchAccounts: () => void;
  totalElementsCount: number;
}) => {
  const accountPerms = useCfgPerm({ roomName: roomName, cfgName: ROOM_CONFIGS_NAMES.ACCOUNT });
  const isOwner = useIsOwnerOrSuperAdmin();

  return (
    <>
      <div style={{ display: "flex" }}>
        {accountPerms.write && (
          <Box sx={{ mr: 5, display: "grid", gap: 1, gridTemplateColumns: "repeat(3, 1fr)" }}>
            <CreateAccountTableControl
              roomName={roomName}
              onSuccess={() => refetchAccounts()}
            />
            <ImportAccountsTableControl
              roomName={roomName}
              onSuccess={() => refetchAccounts()}
            />
            <GenerateAccountsTableControl
              roomName={roomName}
              onSuccess={() => refetchAccounts()}
            />
          </Box>
        )}

        {accountPerms.execute && (
          <RunAccountsTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            onSuccessRun={() => refetchAccounts()}
            totalElementsCount={totalElementsCount}
          />
        )}

        {accountPerms.execute && (
          <ChangeEnabledConfigsTableControl
            roomName={roomName}
            table={table}
            enabled
            filters={filters}
            onSuccess={() => refetchAccounts()}
            totalElementsCount={totalElementsCount}
          />
        )}

        {accountPerms.execute && (
          <ChangeEnabledConfigsTableControl
            roomName={roomName}
            table={table}
            enabled={false}
            filters={filters}
            onSuccess={() => refetchAccounts()}
            totalElementsCount={totalElementsCount}
          />
        )}

        {table.getSelectedRowModel().rows.length === 1 && (
          <EditAccountTableControl
            disabled={!accountPerms.write}
            roomName={roomName}
            table={table}
            onSuccess={() => refetchAccounts()}
            totalElementsCount={totalElementsCount}
          />
        )}

        {accountPerms.write && table.getSelectedRowModel().rows.length !== 1 && (
          <EditAccountsTableControl
            title="Edit accounts"
            roomName={roomName}
            table={table}
            filters={filters}
            onSuccess={() => refetchAccounts()}
            totalElementsCount={totalElementsCount}
          />
        )}

        {accountPerms.delete && (
          <DeleteConfigsTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            onSuccessDelete={() => refetchAccounts()}
            totalElementsCount={totalElementsCount}
          />
        )}

        {accountPerms.read && (
          <ExportAccountsCsvTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
          />
        )}

        {accountPerms.write && (
          <CopyAccountTableControl
            roomName={roomName}
            table={table}
            onSuccess={() => refetchAccounts()}
          />
        )}

        {isOwner && (
          <ApplyAuthGroupsTableControl
            domain={roomName}
            cfgName={ROOM_CONFIGS_NAMES.ACCOUNT}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
            onSuccess={() => refetchAccounts()}
          />
        )}
      </div>
    </>
  );
};

export default AccountsTopToolbar;
