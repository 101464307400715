import { OnChangeFn, SortingState, Updater, VisibilityState } from "@tanstack/table-core";
import { ColumnFiltersState } from "@tanstack/table-core/src/features/Filters";
import { GroupingState } from "@tanstack/table-core/src/features/Grouping";
import { ColumnOrderState } from "@tanstack/table-core/src/features/Ordering";
import { PaginationState } from "@tanstack/table-core/src/features/Pagination";
import {
  MRT_PaginationState,
  MRT_RowData,
  MRT_SortingState,
  MRT_TableOptions,
} from "material-react-table";
import { MRT_Localization_ZH_HANS } from "material-react-table/locales/zh-Hans";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export type PersistentTablesSettings = {
  [key: string]: PersistentTableSettings | undefined;
};

export interface PersistentTableSettings {
  columnVisibility?: VisibilityState;
  columnOrder?: ColumnOrderState;
}

const itemName = "tablesSettings";

export const useTablePersistentSettings = ({
  tableName,
  initialColumnVisibilityState = {},
  initialColumnOrderState = [],
}: {
  tableName: string;
  initialColumnVisibilityState?: VisibilityState;
  initialColumnOrderState?: ColumnOrderState;
}) => {
  const [storageTablesSettings, setStorageTablesSettings] = useState<PersistentTablesSettings>(
    () => {
      const item = localStorage.getItem(itemName);
      return item ? JSON.parse(item) : {};
    }
  );
  useEffect(
    () => localStorage.setItem(itemName, JSON.stringify(storageTablesSettings)),
    [storageTablesSettings]
  );

  const onColumnVisibilityChange: OnChangeFn<VisibilityState> = useCallback(
    (updater: Updater<VisibilityState>) =>
      setStorageTablesSettings((prev) => {
        const tblSettings = prev[tableName] ?? {};
        const newVisibility =
          typeof updater === "function"
            ? updater(tblSettings.columnVisibility ?? {})
            : updater;

        return { ...prev, [tableName]: { ...tblSettings, columnVisibility: newVisibility } };
      }),
    [tableName]
  );

  const onColumnOrderChange: OnChangeFn<ColumnOrderState> = useCallback(
    (updater: Updater<ColumnOrderState>) => {
      setStorageTablesSettings((prev) => {
        const tblSettings = prev[tableName] ?? {};
        const newOrder =
          typeof updater === "function" ? updater(tblSettings.columnOrder ?? []) : updater;

        return { ...prev, [tableName]: { ...tblSettings, columnOrder: newOrder } };
      });
    },
    [tableName]
  );

  const finalTableSettings = useMemo<PersistentTableSettings>(() => {
    const storageSettings = storageTablesSettings[tableName] ?? {};
    const storageColumnOrder = storageSettings.columnOrder ?? [];

    const resultOrder = [
      ...storageColumnOrder,
      ...initialColumnOrderState.filter((item) => !storageColumnOrder.includes(item)),
    ];
    const resultVisibility = {
      ...initialColumnVisibilityState,
      ...storageSettings?.columnVisibility,
    };

    return {
      columnOrder: [...resultOrder],
      columnVisibility: { ...resultVisibility },
    };
  }, [
    initialColumnOrderState,
    initialColumnVisibilityState,
    storageTablesSettings,
    tableName,
  ]);

  return useMemo(
    () => ({
      state: finalTableSettings,
      onColumnVisibilityChange,
      onColumnOrderChange,
    }),
    [onColumnOrderChange, onColumnVisibilityChange, finalTableSettings]
  );
};

// TODO вынести обработчики и состояние пагинации, фильтров, сортировки в отдельный хук
export const useTableSettings = <T extends MRT_RowData>(name: string) => {
  const { i18n } = useTranslation();
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [grouping, setGrouping] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(undefined);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });

  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const loadTablesSettingsFromLocalStorage = () => {
    const item = localStorage.getItem("tablesSettings");
    return item ? JSON.parse(item) : {};
  };

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() =>
    loadTablesSettingsFromLocalStorage()
  );

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting));
  }, [allTablesUserSetting]);

  const onColumnOrderChange = useCallback(
    (newColumnOrder: Updater<ColumnOrderState>) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        [name]: {
          ...allTablesUserSetting[name],
          columnOrder: newColumnOrder as string[],
        },
      });
    },
    [allTablesUserSetting, name]
  );

  const onColumnVisibilityChange = useCallback(
    (updaterFn: Updater<VisibilityState>) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev[name]?.columnVisibility);
        return {
          ...prev,
          [name]: { ...prev[name], columnVisibility: newVisibility },
        };
      });
    },
    [name]
  );

  const onPaginationChange = useCallback((value: Updater<PaginationState>) => {
    setPagination(value);
  }, []);

  const onSortingChange = useCallback((value: Updater<SortingState>) => {
    setSorting(value);
  }, []);

  const onColumnFiltersChange = useCallback((value: Updater<ColumnFiltersState>) => {
    setColumnFilters(value);
  }, []);

  const onGroupingChange = useCallback((value: Updater<GroupingState>) => {
    setGrouping(value);
  }, []);

  const onGlobalFilterChange = useCallback((value: Updater<any>) => {
    setGlobalFilter(value);
  }, []);

  const defaultProps: Partial<MRT_TableOptions<T>> = useMemo(() => {
    return {
      defaultColumn: {
        minSize: 20, //allow columns to get smaller than default
        maxSize: 40, //allow columns to get larger than default
        size: 40, //make columns wider by default
      },
      muiTablePaperProps: {
        sx: {
          height: "100%",
        },
      },
      muiTableContainerProps: ({ table }) => {
        const offsetHeight =
          (table.refs.topToolbarRef.current?.offsetHeight ?? 0) +
          (table.refs.bottomToolbarRef.current?.offsetHeight ?? 0);
        return {
          sx: {
            maxHeight: `clamp(350px, calc(100% - ${offsetHeight}px), 9999px)`,
            height: "100%",
          },
        };
      },
      initialState: {
        pagination: { pageSize: 20, pageIndex: 0 },
        density: "compact",
        //sorting: [{id: "priority", desc: false}],
      },
      enableColumnDragging: false,
      enableColumnFilterModes: true,
      enablePagination: true,
      enableStickyHeader: true,
      enableSorting: true,
      autoResetPageIndex: false,
      enableGlobalFilter: false,
      onPaginationChange,
      onSortingChange,
      onColumnOrderChange,
      onColumnVisibilityChange,
      localization: i18n.language === "cn" ? MRT_Localization_ZH_HANS : undefined,
      onColumnFiltersChange,
      onGroupingChange,
      onGlobalFilterChange,
    };
  }, [
    onPaginationChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnVisibilityChange,
    i18n.language,
    onColumnFiltersChange,
    onGroupingChange,
    onGlobalFilterChange,
  ]);

  const columnVisibility = allTablesUserSetting?.[name]?.columnVisibility;
  const columnOrder = allTablesUserSetting?.[name]?.columnOrder;

  const state = useMemo(() => {
    return {
      pagination,
      sorting,
      grouping,
      globalFilter,
      columnFilters,
      columnVisibility: columnVisibility ?? {},
      columnOrder: columnOrder ?? [],
    };
  }, [
    columnFilters,
    globalFilter,
    grouping,
    columnVisibility,
    columnOrder,
    pagination,
    sorting,
  ]);

  return {
    onColumnOrderChange,
    onColumnVisibilityChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    sorting,
    columnVisibility: columnVisibility ?? {},
    defaultProps,
    globalFilter,
    grouping,
    columnFilters,
    columnOrder: columnOrder ?? [],
    state,
  };
};
