import { zodResolver } from "@hookform/resolvers/zod";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/webpack-resolver";
import { useAtom } from "jotai/index";
import React, { useEffect, useMemo, useRef } from "react";
import AceEditor from "react-ace";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AutocompleteElement, CheckboxElement, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import {
  beforeSendNormalizers,
  formInitState,
} from "@/components/config-modals/clustering-pattern-edit-modal/constants";
import {
  EditClusteringPattern,
  EditedClusteringPattern,
} from "@/components/config-modals/clustering-pattern-edit-modal/types";
import { meStore } from "@/store/store";

const ClusteringPatternCreateModal = ({
  open,
  mode = "add",
  initialData,
  onSave,
  onCancel,
  confirmLoading = false,
  disabled = false,
}: {
  open: boolean;
  mode?: "add" | "edit";
  initialData?: EditClusteringPattern;
  onSave?: (data: EditedClusteringPattern) => void;
  onCancel?: () => void;
  confirmLoading?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [me] = useAtom(meStore);

  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery();

  const authGroupsOptions = useMemo(() => {
    return authGroupsRs?.data.map((i) => i.name).sort((a, b) => a.localeCompare(b));
  }, [authGroupsRs?.data]);

  const schema = z.object({
    name: z.string().min(1, { message: t("fieldRequired") }),
    authGroups: z.array(z.string()).nonempty(t("fieldRequired")),
    enabled: z.boolean(),
    data: z.object({
      category: z.string().optional(),
      pids: z
        .array(z.string())
        .refine((arr) => arr.every((str) => /^-?\d+$/.test(str)), "Inputs must be digits")
        .optional(),
      script: z
        .string()
        .refine((value) => validateEditorContent(value), "Fix errors in editor")
        .optional(),
    }),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<EditedClusteringPattern>({
    mode: "all",
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    const defaultValues = initialData
      ? {
          ...initialData,
          enabled: true,
          authGroups: Array.from(initialData.auth_groups) || [],
        }
      : { ...formInitState };

    reset(defaultValues);
  }, [initialData, open, reset]);

  const onSubmit: SubmitHandler<EditedClusteringPattern> = (data) => {
    let result = data;
    beforeSendNormalizers.forEach((normalizer) => (result = normalizer(result)));
    onSave && onSave(result);
  };

  const editorRef = useRef(null);

  const validateEditorContent = (value: any) => {
    console.log("as2");
    // @ts-expect-error valid
    const errors = editorRef.current?.editor.getSession().getAnnotations();
    console.log(errors);
    if (errors && errors.length > 0) {
      return false;
    }
    return true;
  };

  return (
    <Dialog open={open} onClose={() => onCancel && onCancel()}>
      <DialogTitle>
        {mode === "add" ? t("createClusteringPattern") : t("editClusteringPattern")}
        <IconButton
          aria-label="close"
          onClick={() => onCancel && onCancel()}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "500px",
          }}
        >
          <TextFieldElement
            name="name"
            control={control}
            label={t("name")}
            placeholder={t("name")}
            fullWidth
            error={!!errors.name?.message}
            helperText={errors.name?.message}
          />

          <AutocompleteElement
            control={control}
            loading={authGroupsIsLoading}
            name="authGroups"
            multiple
            options={authGroupsOptions ?? []}
            label={t("authGroups")}
            textFieldProps={{
              error: !!errors.authGroups,
              helperText: errors.authGroups?.message ?? " ",
              variant: "standard",
            }}
          />

          {mode === "add" && (
            <CheckboxElement
              control={control}
              name="enabled"
              label={t("enabled")}
              disabled={disabled}
            />
          )}

          <TextFieldElement
            name="data.category"
            control={control}
            label={t("category")}
            placeholder={t("category")}
            fullWidth
            error={!!errors.data?.category}
            helperText={errors.data?.category ? String(errors.data?.category) : " "}
          />

          <Controller
            name="data.pids"
            control={control}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={value}
                disabled={disabled}
                onChange={(_event, newValue) => {
                  onChange(newValue);
                  setValue("data.pids", newValue); // Update the value in react-hook-form
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("pids")}
                    placeholder={t("addTag")}
                    inputRef={ref}
                    onBlur={onBlur} // Ensure onBlur is called to update the form state
                    error={!!error?.message}
                    helperText={error?.message}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} key={index} />
                  ))
                }
              />
            )}
          />

          <Controller
            control={control}
            name="data.script"
            render={({ field, formState: { errors } }) => (
              <>
                <FormControl error={!!errors.data?.script}>
                  <p>{t("data")}</p>

                  <AceEditor
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    readOnly={disabled}
                    ref={editorRef}
                    mode="lua"
                    theme="dracula"
                    width="100%"
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      tabSize: 2,
                    }}
                  />

                  <FormHelperText>{errors.data?.script?.message ?? " "}</FormHelperText>
                </FormControl>
              </>
            )}
          />

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="button" onClick={() => onCancel && onCancel()}>
              {t("cancel")}
            </Button>
            <Button variant="contained" type="submit" disabled={confirmLoading}>
              {t("submit")}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ClusteringPatternCreateModal;
