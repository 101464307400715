/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { BoardingPlayerSession } from "../models";
// @ts-ignore
import { BoardingPlayerSessionDto } from "../models";

/**
 * BoardingPlayerSessionsApi - axios parameter creator
 * @export
 */
export const BoardingPlayerSessionsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Получить все сессии игроков посадки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBoardingPlayerSessions: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/boardings-player-sessions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить плоский список всех сессий игроков посадки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBoardingPlayerSessionsFlattened: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/boardings-player-sessions/flattened`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить все сессии игроков посадки с информацией о комнате
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBoardingPlayerSessionsWithRoom: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/boardings-player-sessions/with-room`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить сессии игрока посадки по room_pid
     * @param {string} roomPid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBoardingPlayerSession: async (
      roomPid: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomPid' is not null or undefined
      assertParamExists("getBoardingPlayerSession", "roomPid", roomPid);
      const localVarPath = `/api/v2/boardings-player-sessions/{roomPid}`.replace(
        `{${"roomPid"}}`,
        encodeURIComponent(String(roomPid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить сессии игроков посадки по списку room_pid
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBoardingPlayerSessions: async (
      requestBody: Set<string>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists("getBoardingPlayerSessions", "requestBody", requestBody);
      const localVarPath = `/api/v2/boardings-player-sessions/batch`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BoardingPlayerSessionsApi - functional programming interface
 * @export
 */
export const BoardingPlayerSessionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BoardingPlayerSessionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Получить все сессии игроков посадки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllBoardingPlayerSessions(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<{ [key: string]: Array<BoardingPlayerSession> }>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBoardingPlayerSessions(
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BoardingPlayerSessionsApi.getAllBoardingPlayerSessions"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Получить плоский список всех сессий игроков посадки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllBoardingPlayerSessionsFlattened(
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoardingPlayerSession>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllBoardingPlayerSessionsFlattened(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap[
          "BoardingPlayerSessionsApi.getAllBoardingPlayerSessionsFlattened"
        ]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Получить все сессии игроков посадки с информацией о комнате
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllBoardingPlayerSessionsWithRoom(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BoardingPlayerSessionDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllBoardingPlayerSessionsWithRoom(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BoardingPlayerSessionsApi.getAllBoardingPlayerSessionsWithRoom"]?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Получить сессии игрока посадки по room_pid
     * @param {string} roomPid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBoardingPlayerSession(
      roomPid: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoardingPlayerSession>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardingPlayerSession(
        roomPid,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BoardingPlayerSessionsApi.getBoardingPlayerSession"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Получить сессии игроков посадки по списку room_pid
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBoardingPlayerSessions(
      requestBody: Set<string>,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<{ [key: string]: Array<BoardingPlayerSession> }>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardingPlayerSessions(
        requestBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BoardingPlayerSessionsApi.getBoardingPlayerSessions"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * BoardingPlayerSessionsApi - factory interface
 * @export
 */
export const BoardingPlayerSessionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BoardingPlayerSessionsApiFp(configuration);
  return {
    /**
     *
     * @summary Получить все сессии игроков посадки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBoardingPlayerSessions(
      options?: any
    ): AxiosPromise<{ [key: string]: Array<BoardingPlayerSession> }> {
      return localVarFp
        .getAllBoardingPlayerSessions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить плоский список всех сессий игроков посадки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBoardingPlayerSessionsFlattened(
      options?: any
    ): AxiosPromise<Array<BoardingPlayerSession>> {
      return localVarFp
        .getAllBoardingPlayerSessionsFlattened(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить все сессии игроков посадки с информацией о комнате
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBoardingPlayerSessionsWithRoom(
      options?: any
    ): AxiosPromise<Array<BoardingPlayerSessionDto>> {
      return localVarFp
        .getAllBoardingPlayerSessionsWithRoom(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить сессии игрока посадки по room_pid
     * @param {string} roomPid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBoardingPlayerSession(
      roomPid: string,
      options?: any
    ): AxiosPromise<Array<BoardingPlayerSession>> {
      return localVarFp
        .getBoardingPlayerSession(roomPid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить сессии игроков посадки по списку room_pid
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBoardingPlayerSessions(
      requestBody: Set<string>,
      options?: any
    ): AxiosPromise<{ [key: string]: Array<BoardingPlayerSession> }> {
      return localVarFp
        .getBoardingPlayerSessions(requestBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BoardingPlayerSessionsApi - object-oriented interface
 * @export
 * @class BoardingPlayerSessionsApi
 * @extends {BaseAPI}
 */
export class BoardingPlayerSessionsApi extends BaseAPI {
  /**
   *
   * @summary Получить все сессии игроков посадки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardingPlayerSessionsApi
   */
  public getAllBoardingPlayerSessions(options?: RawAxiosRequestConfig) {
    return BoardingPlayerSessionsApiFp(this.configuration)
      .getAllBoardingPlayerSessions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить плоский список всех сессий игроков посадки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardingPlayerSessionsApi
   */
  public getAllBoardingPlayerSessionsFlattened(options?: RawAxiosRequestConfig) {
    return BoardingPlayerSessionsApiFp(this.configuration)
      .getAllBoardingPlayerSessionsFlattened(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить все сессии игроков посадки с информацией о комнате
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardingPlayerSessionsApi
   */
  public getAllBoardingPlayerSessionsWithRoom(options?: RawAxiosRequestConfig) {
    return BoardingPlayerSessionsApiFp(this.configuration)
      .getAllBoardingPlayerSessionsWithRoom(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить сессии игрока посадки по room_pid
   * @param {string} roomPid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardingPlayerSessionsApi
   */
  public getBoardingPlayerSession(roomPid: string, options?: RawAxiosRequestConfig) {
    return BoardingPlayerSessionsApiFp(this.configuration)
      .getBoardingPlayerSession(roomPid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить сессии игроков посадки по списку room_pid
   * @param {Set<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardingPlayerSessionsApi
   */
  public getBoardingPlayerSessions(requestBody: Set<string>, options?: RawAxiosRequestConfig) {
    return BoardingPlayerSessionsApiFp(this.configuration)
      .getBoardingPlayerSessions(requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
