import * as React from "react";
import { Button, Popover, Autocomplete, TextField, CircularProgress, Typography } from "@mui/material";

interface ASelectPopoverProps {
  containerStyle?: React.CSSProperties;
  children?: React.ReactNode;
  buttonText?: string;
  disabled?: boolean;
  onOk?: () => void;
  loading?: boolean;
  popoverProps?: {
    title?: string;
    open: boolean;
    trigger?: string;
    onOpenChange?: (open: boolean) => void;
  };
  selectProps?: {
    style?: React.CSSProperties;
    value?: any;
    onChange?: (value: any) => void;
    allowClear?: boolean;
    options?: { value: any; label: string }[];
    placeholder?: string;
    loading?: boolean;
  };
}

const ASelectPopover: React.FC<ASelectPopoverProps> = ({
                                                         containerStyle,
                                                         children,
                                                         buttonText = "Submit",
                                                         disabled,
                                                         onOk,
                                                         loading,
                                                         popoverProps,
                                                         selectProps,
                                                       }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    popoverProps?.onOpenChange?.(false);
  };

  return (
    <>
      <div onClick={handleClick} style={{ display: "inline-block" }}>
        {children}
      </div>
      <Popover
        open={popoverProps?.open ?? Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ style: { padding: 12, minWidth: 250, maxWidth: 300 } }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 8, ...containerStyle }}>

          <Typography variant="subtitle2" gutterBottom>
            {popoverProps?.title}
          </Typography>

          <Autocomplete
            fullWidth
            options={selectProps?.options || []}
            getOptionLabel={(option) => option.label}
            value={selectProps?.options?.find(opt => opt.value === selectProps?.value) || null}
            onChange={(_, newValue) => selectProps?.onChange?.(newValue?.value)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={selectProps?.placeholder || "Choose a test instance"}
                variant="outlined"
                size="small"
                disabled={selectProps?.loading}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {selectProps?.loading ? <CircularProgress size={18} style={{ marginRight: 8 }} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          {onOk && (
            <Button
              variant="contained"
              color="primary"
              disabled={disabled || loading}
              onClick={onOk}
              style={{ height: 36 }}
            >
              {loading ? <CircularProgress size={20} color="inherit" /> : "OK"}
            </Button>
          )}
        </div>
      </Popover>
    </>
  );
};

export default ASelectPopover;
