import { Outlet, createRootRoute } from "@tanstack/react-router";
import * as React from "react";

import CommonTemplate from "@/template/common-template";

export const Route = createRootRoute({
  component: () => (
    <CommonTemplate>
      <Outlet />
    </CommonTemplate>
  ),
});
