import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import {
  CreateUserRequestDto,
  UpdateUserRequestDto,
  UserResponseRegularDto,
} from "@/shared/api";
import { RequiredError } from "@/shared/api/base";
import { useApi } from "@/shared/api/use-api";

export const useGetUserByUsernameQuery = (
  {
    username,
  }: {
    username: string;
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<UserResponseRegularDto>>, "queryKey">
) => {
  const { userApi } = useApi();
  return useQuery({
    queryKey: [`/user/${username}`],
    queryFn: ({ signal }) => userApi.getUserByUsername(username, { signal }),
    ...queryOptions,
  });
};

export const useGetUserByIdQuery = (
  {
    userId,
  }: {
    userId: number;
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<UserResponseRegularDto>>, "queryKey">
) => {
  const { userApi } = useApi();
  return useQuery({
    queryKey: [`/user/${userId}`],
    queryFn: ({ signal }) => userApi.getUserById(userId, { signal }),
    ...queryOptions,
  });
};

export const useUsersQuery = (
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<UserResponseRegularDto[]>>, "queryKey">
) => {
  const { userApi } = useApi();

  return useQuery({
    queryKey: [`/user`],
    queryFn: ({ signal }) => userApi.getAllUsers({ signal }),
    ...queryOptions,
  });
};

export const useCreateUserMutation = () => {
  const { userApi } = useApi();
  type Options = { request: CreateUserRequestDto };

  const mutationFn = ({ request }: Options) => {
    return userApi.createUser(request);
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useUpdateUserMutation = () => {
  const { userApi } = useApi();
  type Options = { updateUser: UpdateUserRequestDto };

  const mutationFn = ({ updateUser }: Options) => {
    return userApi.updateUser(updateUser);
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useRevokeTokensMutation = () => {
  const { userApi } = useApi();

  type Options = {
    userIds: number[];
  };

  const mutationFn = ({ userIds }: Options) => {
    return userApi.revokeTokens({
      user_ids: new Set<number>(userIds),
    });
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useChangeUserPasswordMutation = () => {
  const { userApi } = useApi();

  type Options = {
    userId: number;
    password: string;
  };

  const mutationFn = ({ userId, password }: Options) => {
    return userApi.changePassword({ id: userId, password: password });
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};

export const useDeleteUsersMutation = () => {
  const { userApi } = useApi();

  type Options = {
    ids: number[];
  };

  const mutationFn = ({ ids }: Options) => userApi.deleteUsers({ ids });

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};
