import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysDomainDeleteMutation } from "@/api/hooks/sys-domain-hooks";
import DeleteDomainModal from "@/module/rooms-management-module/components/delete-domain-modal";

const DeleteDomainTableControl = ({
  domain,
  refetch,
}: {
  domain: string;
  refetch: () => void;
}) => {
  const mutation = useSysDomainDeleteMutation();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleDelete = (withData = false) => {
    mutation.mutate(
      {
        domain: domain,
        withData: withData,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("domainSuccessfullyCreated", { domain }), { variant: "success" });
          setIsOpen(false);
          refetch();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministratorMessage", { message: err.message }),
            { variant: "error" }
          );
          console.error("Delete domain failure:", err);
        },
      }
    );
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconButton onClick={() => setIsOpen(true)} color="error">
        <Delete />
      </IconButton>
      <DeleteDomainModal
        open={isOpen}
        domainName={domain}
        onClose={() => setIsOpen(false)}
        onConfirm={(_, withData) => handleDelete(withData)}
      />
    </div>
  );
};

export default DeleteDomainTableControl;
