import { zodResolver } from "@hookform/resolvers/zod";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { SysCfgApplyAuthGroupsRequestDto } from "@/shared/api";

type FormData = Omit<SysCfgApplyAuthGroupsRequestDto, "filters">;

interface ApplyAuthGroupsModalProps extends Omit<DialogProps, "children" | "onSubmit"> {
  onClose: () => void;
  onSave: (data: FormData) => void;
  authGroups: string[];
  authGroupsIsLoading?: boolean;
  disabled?: boolean;
  confirmLoading?: boolean;
}

const ApplyAuthGroupsModal = ({
  onSave,
  onClose,
  authGroups,
  authGroupsIsLoading = false,
  disabled = false,
  confirmLoading = false,
  open,
  ...dialogProps
}: ApplyAuthGroupsModalProps) => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      z.object({
        auth_groups: z.array(z.string()),
      }),
    []
  );

  const { control, handleSubmit, reset } = useForm<FormData>({
    mode: "onChange",
    defaultValues: { auth_groups: [] },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    reset({ auth_groups: [] });
  }, [open, reset]);

  const submit = handleSubmit((data) => onSave(data));
  const handleSave = () => void submit();

  return (
    <Dialog open={open} {...dialogProps}>
      <DialogTitle>{t("applyAuthGroups")}</DialogTitle>
      <DialogContent>
        <Controller
          control={control}
          name="auth_groups"
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              multiple
              disabled={disabled}
              loading={authGroupsIsLoading}
              value={field.value}
              onChange={(_, data) => field.onChange(data)}
              options={authGroups}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("authGroups")}
                  placeholder={t("authGroups")}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button sx={{ width: "100px" }} variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>

        <Button
          sx={{ width: "100px" }}
          variant="contained"
          disabled={disabled || confirmLoading}
          startIcon={confirmLoading ? <CircularProgress size={20} /> : undefined}
          onClick={handleSave}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyAuthGroupsModal;
