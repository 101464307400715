import { CloudUpload as UploadIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Input,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const AvatarConfigAddModal = ({
  title,
  confirmLoading = false,
  onCancel,
  onSave,
}: {
  title?: string;
  confirmLoading?: boolean;
  onCancel?: () => void;
  onSave?: (zipArchive: File) => void;
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;

    if (selectedFile) {
      const isZip =
        selectedFile.type === "application/zip" ||
        selectedFile.type === "application/x-zip-compressed";
      if (!isZip) {
        enqueueSnackbar(t("uploadOnlyZipArchive"), { variant: "error" });
        return false;
      }

      const isLt10Mb = selectedFile.size / 1024 / 1024 < 10;
      if (!isLt10Mb) {
        enqueueSnackbar(t("zipMustSmaller"), { variant: "error" });
        return false;
      }

      setFile(selectedFile);
    }
  };

  const handleSaveClick = () => {
    if (!file) {
      enqueueSnackbar(t("fileIsNotSat"), { variant: "error" });
      return;
    }

    if (onSave) onSave(file);
  };

  return (
    <>
      <Dialog open onClose={() => onCancel && onCancel()} fullWidth maxWidth="sm">
        <DialogTitle>
          {title && (
            <Typography variant="h5" component="div">
              {title}
            </Typography>
          )}
        </DialogTitle>

        {title && <Divider />}

        <DialogContent>
          <Typography variant="body1">{t("zipArchiveWithLogos")}</Typography>
          <Box mt={2}>
            <Input
              id="file-input"
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: ".zip" }}
              fullWidth
            />
            {file && (
              <Typography variant="body2" color="textSecondary" mt={1}>
                {file.name}
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary" mt={2}>
              Upload zip archive with jpeg, jpg or png files. Archive should be less than 10 mb
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onCancel && onCancel()} color="secondary">
            {t("cancel")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            startIcon={confirmLoading ? <CircularProgress size={24} /> : <UploadIcon />}
            disabled={confirmLoading}
          >
            {confirmLoading ? t("loading") : t("submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AvatarConfigAddModal;
