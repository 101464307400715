import { Grid, CircularProgress, Typography } from "@mui/material";

const ACenteredSpinner = ({ tip }: { tip?: string }) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item>
        <div style={{ width: "300px", textAlign: "center" }}>
          <CircularProgress size={40} />
          {tip && <Typography variant="body2" style={{ marginTop: "10px" }}>{tip}</Typography>}
        </div>
      </Grid>
    </Grid>
  );
};

export default ACenteredSpinner;