import { useNavigate } from "@tanstack/react-router";
import { useAtom } from "jotai/index";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Route } from "@/routes/accounts/$room.$type";
import { appCurrentRoom, headerTitle } from "@/store/store";

import AccountsModule from "../module/accounts-module/accounts-module";

const AccountsPage = () => {
  const { t } = useTranslation();
  const { type } = Route.useParams();
  const [currentRoom] = useAtom(appCurrentRoom);
  const navigate = useNavigate();
  const [, setHeaderTitle] = useAtom(headerTitle);

  useEffect(() => {
    if (!currentRoom) {
      void navigate({ to: "/" });
    } else {
      void navigate({ to: `/accounts/${currentRoom.name}/${type}` });
    }
  }, [currentRoom, navigate, type]);

  useEffect(() => {
    setHeaderTitle(t("accounts"));
  }, [setHeaderTitle, t]);

  return (
    <>
      <AccountsModule />
    </>
  );
};

export default AccountsPage;
