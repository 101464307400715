import { AddCircleOutline } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { keepPreviousData } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { enqueueSnackbar } from "notistack";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUsersQuery,
} from "@/api/hooks/user-hooks";
import CreateUserModal from "@/components/create-user-modal";
import HeaderedFullHeightContainer from "@/components/headered-full-height-container";
import { COMMON_REFETCH_INTERVAL, SUPER_ADMIN_NAME } from "@/lib/constants";
import UsersListTable from "@/module/users-list-module/users-list-table";
import { CreateUserRequestDto, UpdateUserRequestDto } from "@/shared/api";
import { meStore } from "@/store/store";

const UsersListModule = () => {
  const [me] = useAtom(meStore);
  const { t } = useTranslation();

  const {
    data: usersRs,
    isLoading,
    isRefetching,
    refetch,
  } = useUsersQuery({
    placeholderData: keepPreviousData,
    staleTime: 0,
    refetchInterval: COMMON_REFETCH_INTERVAL,
    enabled: !!me,
  });

  const users = useMemo(() => {
    return (
      usersRs?.data?.filter(
        (item) => me?.username === SUPER_ADMIN_NAME || item.username !== SUPER_ADMIN_NAME
      ) ?? []
    );
  }, [me, usersRs?.data]);

  const [createUser, setCreateUser] = useState<boolean>(false);
  const { mutate: createUserMutate, isPending: createUserIsPending } = useCreateUserMutation();
  const { mutate: updateUser, isPending: updateUserIsPending } = useUpdateUserMutation();

  const handleUpdate = (data: UpdateUserRequestDto) => {
    updateUser(
      { updateUser: data },
      {
        onSuccess: () => {
          refetch().then();
        },
      }
    );
  };

  const onCreateUserHandler = (data: CreateUserRequestDto) => {
    createUserMutate(
      {
        request: data,
      },
      {
        onSuccess: () => {
          setCreateUser(false);
          enqueueSnackbar(t("userCreated"), { variant: "success" });
          refetch().then();
        },
        onError: (err) => {
          console.error(err);
          enqueueSnackbar(
            t("errorCreatingUser", {
              error: err.message,
            }),
            { variant: "error" }
          );
        },
      }
    );
  };

  return (
    <>
      <HeaderedFullHeightContainer
        header={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutline width={24} />}
            onClick={() => setCreateUser(true)}
            sx={{ mb: 2 }}
          >
            {t("createUser")}
          </Button>
        }
      >
        <UsersListTable
          users={users}
          usersIsLoading={isLoading}
          userIsRefetching={isRefetching || updateUserIsPending}
          onUpdate={(data) => handleUpdate(data)}
          onDeleted={() => refetch()}
        />
      </HeaderedFullHeightContainer>

      <CreateUserModal
        open={createUser}
        onClose={() => setCreateUser(false)}
        onSave={(data) => onCreateUserHandler(data)}
        isLoading={createUserIsPending}
      />
    </>
  );
};

export default UsersListModule;
