import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import ErrorPage from "@/pages/error-page";

const schema = z.object({
  errorMessage: z.string().optional(),
});

export const Route = createFileRoute("/error/")({
  component: Component,
  validateSearch: (opts) => schema.parse(opts),
});

function Component() {
  const { errorMessage } = Route.useSearch();
  return <ErrorPage errorMessage={errorMessage} />;
}
