import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { MRT_TableInstance } from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysAccountUpdateMutation } from "@/api/hooks/sys-account-hooks";
import SysAccountWriteModal from "@/module/accounts-module/components/sys-account-write-modal";
import { SysAccountRegularDto, SysAccountUpdateDto } from "@/shared/api";

const EditServiceAccountTableControl = ({
  roomName,
  table,
  onSuccess,
  totalElementsCount,
  disabled = false,
}: {
  roomName: string;
  table: MRT_TableInstance<SysAccountRegularDto>;
  onSuccess?: () => void;
  totalElementsCount: number;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [account, setAccount] = useState<SysAccountRegularDto>();

  const { mutate: updateAccountMutate, isPending: updateAccountIsPending } =
    useSysAccountUpdateMutation();

  function handleClickUpdateAccount(name: string, account: SysAccountUpdateDto) {
    updateAccountMutate(
      {
        domain: roomName,
        accountName: name,
        account: account,
      },
      {
        onSuccess: () => {
          setIsOpen(false);
          enqueueSnackbar(t("dataSuccessfullyUpdated"), { variant: "success" });
          onSuccess && onSuccess();
        },
        onError: (err: any) => {
          enqueueSnackbar(t("operationFailureContactAdministrator"), { variant: "error" });
          console.error("update account failure:", err);
        },
      }
    );
  }

  function handleEditClick() {
    setAccount(table.getSelectedRowModel().rows[0]?.original);
    setIsOpen(true);
  }

  return (
    <>
      <IconButton
        size="small"
        style={{ fontSize: 12 }}
        color="warning"
        disabled={table.getSelectedRowModel().rows.length !== 1}
        onClick={handleEditClick}
      >
        <Edit /> {" "}
        {t("editLength", {
          length:
            table.getSelectedRowModel().rows.length > 0
              ? table.getSelectedRowModel().rows.length
              : totalElementsCount,
        })}
      </IconButton>

      <SysAccountWriteModal
        disabled={disabled}
        mode="edit"
        accountType="service"
        isOpen={isOpen}
        roomName={roomName}
        defaultValues={account}
        onSave={(name, account) => handleClickUpdateAccount(name, account)}
        onClose={() => setIsOpen(false)}
        confirmLoading={updateAccountIsPending}
      />
    </>
  );
};

export default EditServiceAccountTableControl;
