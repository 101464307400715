import { zodResolver } from "@hookform/resolvers/zod";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useMemo } from "react";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const CreateDomainConfigModal = ({
  open,
  onClose,
  onSave,
  isLoading = false,
}: {
  open: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      z.object({
        name: z
          .string()
          .min(1, t("fieldRequired"))
          // eslint-disable-next-line no-control-regex
          .regex(/^(?!system\.)(?!.*\.system\.)[A-Za-z_][^\x00$]*$/, t("startWithRegexp")),
      }),
    [t, t]
  );

  type SchemaType = z.infer<typeof schema>;

  const form = useForm<SchemaType>({
    mode: "onChange",
    defaultValues: { name: "" },
    resolver: zodResolver(schema),
  });

  const onCloseHandler = () => {
    form.reset();
    onClose();
  };

  useEffect(() => {
    form.reset();
  }, [open, form]);

  return (
    <Dialog open={open} onClose={onCloseHandler} fullWidth maxWidth="sm">
      <DialogTitle title={t("createDomainConfig")}>{t("createDomainConfig")}</DialogTitle>
      <DialogContent style={{ paddingTop: "5px" }}>
        <FormContainer formContext={form} onSuccess={(val) => onSave(val.name)}>
          <Stack spacing={2}>
            <TextFieldElement name="name" label={t("configName")} required fullWidth />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={18} />}
            >
              {t("create")}
            </Button>
          </Stack>
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
};

export default CreateDomainConfigModal;
