import { Box, IconButton } from "@mui/material";
import { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { supportedLngs } from "@/i18n";

export const LocaleSwitcher: FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <Box display="flex" gap="8px">
      {supportedLngs.map((language) => {
        if (language === i18n.language) {
          return null;
        }

        const handleLanguageChange = (e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          void i18n.changeLanguage(language);
        };

        return (
          <IconButton
            key={language}
            onClick={handleLanguageChange}
            sx={{
              color: "inherit",
              // fontWeight: 700,
              cursor: "pointer",
            }}
          >
            {t(`localeSwitcher.${language}`, { locale: language })}
          </IconButton>
        );
      })}
    </Box>
  );
};
