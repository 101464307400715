import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectRoomFormControl = ({
  rooms,
  selectedRoom,
  setSelectedRoom,
}: {
  rooms: string[];
  selectedRoom: string;
  setSelectedRoom: (selectedRoom: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <FormControl sx={{ width: "100%", maxWidth: "320px", my: 2 }} variant="standard">
      <InputLabel id="select_room_label">{t("room")}</InputLabel>
      <Select
        labelId="select_room_label"
        id="select_room"
        onChange={(e) => setSelectedRoom(e.target.value)}
        value={selectedRoom}
      >
        {rooms.map((room) => (
          <MenuItem key={room} value={room}>
            {" "}
            {room}{" "}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectRoomFormControl;
