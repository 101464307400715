import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { JsonViewer } from "@textea/json-viewer";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useCommandJournalQuery } from "@/api/hooks/command-journal-hooks";
import { useMuiTableToolbarAlertBannerProps } from "@/hooks/use-mui-table-toolbar-alert-banner-props";
import { useTableSettings } from "@/hooks/use-table-settings";
import { CommandJournalRegularDto } from "@/shared/api";

const CommandJournalTable = ({ onClose }: { onClose?: () => void }) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });

  const {
    data: commandJournalResponse,
    isError: isCommandJournalError,
    isLoading: isCommandJournalLoading,
    isRefetching: isCommandJournalRefetching,
    error: commandJournalError,
  } = useCommandJournalQuery({
    pagination,
  });

  const { defaultProps } = useTableSettings<CommandJournalRegularDto>("commandJournal");

  const columns = useMemo<MRT_ColumnDef<CommandJournalRegularDto>[]>(
    () => [
      {
        id: "username",
        header: t("username"),
        accessorFn: (row) => row.username,
        filterFn: "contains",
      },
      {
        id: "action_subject_id",
        header: t("subjectIdentifier"),
        accessorFn: (row) => row.action_subject_id,
        filterFn: "contains",
      },
      {
        id: "broker_topic",
        header: t("brokerTopic"),
        accessorFn: (row) => row.broker_topic,
      },
      {
        header: t("commandType"),
        accessorFn: (row) => mapCommandType(row.type),
        filterFn: "contains",
      },
      {
        header: t("commandStatus"),
        accessorFn: (row) => mapCommandStatus(row),
        filterFn: "contains",
      },
      {
        header: t("commandBody"),
        accessorFn: (row) => JSON.stringify(row.body, null, 2),
        filterFn: "contains",
        Cell: ({ row: { original } }) => (
          <JsonViewer
            style={{ maxWidth: "120px" }}
            rootName="body"
            displayDataTypes={false}
            value={original.body && JSON.parse(original.body)?.body}
            defaultInspectDepth={0}
          />
        ),
      },
      {
        id: "created_at",
        header: t("executionTime"),
        accessorFn: (row) => new Date(row.created_at).toLocaleString(),
        filterFn: "contains",
      },
      {
        id: "command_response_body",
        header: t("responseBody"),
        accessorFn: (row) => JSON.stringify(row.response_body, null, 2),
        filterFn: "contains",
        Cell: ({ row: { original } }) => (
          <JsonViewer
            rootName="body"
            displayDataTypes={false}
            value={original.response_body && JSON.parse(original.response_body)}
            defaultInspectDepth={0}
          />
        ),
      },
      {
        header: t("responseMessage"),
        accessorFn: (row) => row.error_message ?? "-",
        filterFn: "contains",
      },
      {
        id: "response_received_at",
        header: t("responseTime"),
        accessorFn: (row) =>
          row.response_received_at ? new Date(row.response_received_at).toLocaleString() : "-",
      },
    ],
    [t]
  );
  const muiToolbarAlertBannerProps = useMuiTableToolbarAlertBannerProps(
    Boolean(isCommandJournalError),
    commandJournalError
  );

  const table = useMaterialReactTable({
    data: (commandJournalResponse?.data.content ??
      []) as unknown as CommandJournalRegularDto[],
    columns: columns,
    ...defaultProps,
    //selectAllMode: "all",
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
      isFullScreen: true,
    },
    muiToolbarAlertBannerProps,
    state: {
      pagination,
      isLoading: isCommandJournalLoading,
      showProgressBars: isCommandJournalRefetching,
    },
    rowCount: commandJournalResponse?.data.totalElements,
    pageCount: commandJournalResponse?.data.totalPages,
    enableSelectAll: false,
    enableRowSelection: false,
    enableGrouping: false,
    enableColumnDragging: false,
    enableColumnFilterModes: false,

    renderToolbarInternalActions: () => (
      <>
        <IconButton onClick={() => onClose && onClose()}>
          <Close />
        </IconButton>
      </>
    ),
    enableHiding: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableGlobalFilter: false,

    enablePagination: true,
    enableStickyHeader: true,
    enableSorting: false,
    autoResetPageIndex: false,
    manualPagination: true,
    onPaginationChange: setPagination,
  });

  return <MaterialReactTable table={table} />;
};

export default CommandJournalTable;

function mapCommandStatus(journalRecord: CommandJournalRegularDto): string {
  if (journalRecord.status === -1) return "FAILED";
  if (journalRecord.status === 1) return "SUCCESS";

  if (journalRecord.status === 0) {
    const expired = journalRecord.created_at + journalRecord.response_wait_time;

    if (Date.now() > expired) {
      return "RESPONSE_NOT_RECEIVED_IN_TIME";
    }

    return "COMMAND_SENT";
  }

  return "UNKNOWN STATUS";
}

function mapCommandType(commandType: number | undefined): string {
  if (commandType === 1) return "UPDATE NICKNAME";
  if (commandType === 2) return "UPDATE AVATAR";
  if (commandType === 3) return "RUN ACCOUNT";
  if (commandType === 4) return "STOP ACCOUNT";
  if (commandType === 5) return "MIGRATE ACCOUNT";
  if (commandType === 6) return "JOIN CLUB";
  if (commandType === 7) return "LEAVE CLUB";
  if (commandType === 8) return "REQUEST REPLAY";
  if (commandType === 9) return "SWITCH GS MODE";
  if (commandType === 10) return "SWITCH TS MODE";

  return "UNKNOWN COMMAND TYPE";
}
