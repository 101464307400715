import { zodResolver } from "@hookform/resolvers/zod";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/webpack-resolver";
import React, { useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { EmailDomainForm } from "@/components/config-modals/email-domain-edit-modal/types";
import { SysCfgCreateRequestDto } from "@/shared/api";

export default function EmailDomainCreateModal({
  open,
  onSave,
  onCancel,
}: {
  open: boolean;
  mode?: string;
  initialData?: EmailDomainForm;
  onSave?: (data: SysCfgCreateRequestDto[]) => void;
  onCancel?: () => void;
  confirmLoading?: boolean;
}) {
  const { t } = useTranslation();

  const schema = z.object({
    names: z
      .string()
      .transform((val) => val.split("\n").map((str) => str.trim()))
      .refine(
        (arr) =>
          arr.every((str) => /^[a-zA-Z0-9-]*\.[a-zA-Z0-9-]+$/.test(str) && str.length <= 20),
        {
          message: t("eachDomainValidation"),
        }
      ),
    authGroups: z.array(z.string()).nonempty(t("fieldRequired")),
  });

  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery({
    enabled: open,
  });

  const authGroupsOptions = useMemo(() => {
    return authGroupsRs?.data.map((it) => it.name).sort((a, b) => a.localeCompare(b)) ?? [];
  }, [authGroupsRs?.data]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailDomainForm>({
    mode: "onTouched",
    resolver: zodResolver(schema),
    //defaultValues: defaultValues
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const onSubmit: SubmitHandler<EmailDomainForm> = (data) => {
    const result = data.names.map((name) => {
      return {
        auth_groups: new Set(data.authGroups),
        name: name,
        enabled: true,
      };
    });

    if (!onSave) return;
    onSave(result);

    onCancel && onCancel(); //purifying form and close dialog
  };

  return (
    <>
      <Dialog open={open} key="email-domain-modal">
        <DialogTitle>
          {t("addEmailDomains")}
          <IconButton
            aria-label="close"
            onClick={() => onCancel && onCancel()}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px" /* Расстояние между элементами */,
              width: "500px" /* Ширина формы */,
            }}
          >
            <AutocompleteElement
              control={control}
              loading={authGroupsIsLoading}
              name="authGroups"
              multiple
              options={authGroupsOptions ?? []}
              label={t("authGroups")}
              textFieldProps={{
                error: !!errors.authGroups,
                helperText: errors.authGroups?.message ?? " ",
                variant: "standard",
              }}
            />

            <TextFieldElement
              name="names"
              control={control}
              label={t("domains")}
              placeholder={t("writeEachDomainOnNewLine")}
              multiline
              error={!!errors.name?.message}
              helperText={errors.name?.message}
              minRows={7}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => onCancel && onCancel()}
              >
                {t("cancel")}
              </Button>
              <Button variant="contained" type="submit">
                {t("submit")}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
