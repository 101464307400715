import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useBoardingTablesQuery } from "@/api/hooks/boarding-tables-hooks";
import { JsonViewerModal } from "@/components/json-viewer-modal";
import { useMuiTableToolbarAlertBannerProps } from "@/hooks/use-mui-table-toolbar-alert-banner-props";
import { useTableSettings } from "@/hooks/use-table-settings";
import { BoardingTable } from "@/shared/api";
import { useBoardingTablesColumns } from "./boarding-tables-columns";

const BoardingTablesTable = ({
  setRowsAmount,  
  tablesQuery,
}: {
  setRowsAmount?: (amount: number) => void;
  tablesQuery: ReturnType<typeof useBoardingTablesQuery>;
}) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<BoardingTable | null>(null);
  const columns = useBoardingTablesColumns();
  const { state, defaultProps } = useTableSettings<BoardingTable>("boardingTablesTable");

  const {
    data: tablesResponse,
    isError: isDataLoadingError,
    isLoading: isDataLoading,
    isRefetching: isDataRefetching,
  } = tablesQuery;

  const muiToolbarAlertBannerProps = useMuiTableToolbarAlertBannerProps(
    isDataLoadingError,
    tablesResponse?.statusText
  );

  useEffect(() => {
    setRowsAmount?.(tablesResponse?.data.length ?? 0);
  }, [tablesResponse, setRowsAmount]);

  const table = useMaterialReactTable({
    data: tablesResponse?.data ?? [],
    muiToolbarAlertBannerProps,
    columns: columns,
    rowCount: tablesResponse?.data.length ?? 0,
    state: {
      ...state,
      isLoading: isDataLoading,
      showProgressBars: isDataRefetching,
    },
    ...defaultProps,
    selectAllMode: "page",
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => setSelectedRow(row.original),
      sx: { cursor: 'pointer' },
    }),
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <JsonViewerModal
        open={!!selectedRow}
        onClose={() => setSelectedRow(null)}
        data={selectedRow}
        title={t('boardingTables.jsonView')}
      />
    </>
  );
};

export default BoardingTablesTable; 