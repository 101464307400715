import { Box } from "@mui/material";
import { ReactNode, useRef } from "react";

const HeaderedFullHeightContainer = ({
  header,
  children,
}: {
  header: ReactNode;
  children: ReactNode;
}) => {
  const headerRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box sx={{ height: "100%" }}>
      <Box ref={headerRef}>{header}</Box>

      <Box sx={{ height: `calc(100% - ${headerRef.current?.clientHeight ?? 0}px)` }}>
        {children}
      </Box>
    </Box>
  );
};

export default HeaderedFullHeightContainer;
