import { Navigate, createFileRoute } from "@tanstack/react-router";
import { useAtom } from "jotai/index";

import { isSuperAdmin } from "@/lib/auth-predicates";
import RoomsManagementPage from "@/pages/rooms-management-page";
import { meStore } from "@/store/store";

export const Route = createFileRoute("/room-management/")({
  component: Component,
});

function Component() {
  const [me] = useAtom(meStore);

  if (!isSuperAdmin(me)) {
    return <Navigate to="/" />;
  }

  return <RoomsManagementPage />;
}
