import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { AutocompleteElement, CheckboxElement, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { ACC_TP } from "@/components/config-modals/router-edit-modal/constants";
import { trimSplitCsvRecord } from "@/lib/common-utils";
import { GenerateAccountsDto, SysAccountGenerateDto } from "@/shared/api";

const schema = z.object({
  amount: z.number().int().min(1).max(1000).optional(),
  authGroups: z.array(z.string()),
  accounts: z.string().optional(),
});

interface IGenerateAccountsFormData {
  enableGenerateByAmount: boolean;
  amount: number;
  authGroups: string[];
  accounts: string;
}

type CsvDelimiter = "," | ";";

const SysAccountEditModal = ({
  isOpen = false,
  onSave,
  onClose,
  confirmLoading = false,
}: {
  mode: "add" | "edit";
  accountType: "service" | "regular";
  isOpen?: boolean;
  roomName: string;
  onSave: (accountGenerateByNumberDto: GenerateAccountsDto) => void;
  onClose: () => void;
  confirmLoading?: boolean;
}) => {
  const { t } = useTranslation();
  const csvDelimiter = ",";

  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery({
    enabled: isOpen,
  });

  const authGroups = useMemo(() => {
    return authGroupsRs?.data ?? [];
  }, [authGroupsRs]);

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IGenerateAccountsFormData>({
    mode: "onChange",
    defaultValues: { ...{ amount: 0 } },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    reset({ ...{ amount: 0 } });
  }, [isOpen, reset]);

  function convertCsvInputToAccountsCreateObj(input: string) {
    const records = input.split("\n");

    return records.map((item) => convertCsvRecordToAccountJson(item));
  }

  function convertCsvRecordToAccountJson(record: string): SysAccountGenerateDto {
    const values = trimSplitCsvRecord(record, csvDelimiter);

    return {
      name: values[0],
      authGroups: new Set(getValues("authGroups")),
      password: values[1],
      walletCode: values[2],
      gameGroup: values[3],
      proxy: values[4],
      osType: values[5] as unknown as number,
      accountType: ACC_TP.MANAGER,
    };
  }

  const submit = handleSubmit((data) => {
    const accounts = data.accounts
      ? convertCsvInputToAccountsCreateObj(data.accounts)
      : undefined;
    onSave({
      authGroups: new Set(data.authGroups),
      amount: data.amount,
      accounts: accounts,
    });
  });

  const handleSave = () => {
    void submit();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("generateAccounts")}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ paddingTop: "5px", width: "400px" }}>
          <AutocompleteElement
            name="authGroups"
            loading={authGroupsIsLoading}
            control={control}
            label={t("authGroups")}
            required
            options={authGroups.map((it) => it.name)}
            multiple
            textFieldProps={{
              error: !!errors.authGroups,
              helperText: errors.authGroups?.message ?? " ",
              variant: "standard",
            }}
          />

          <div style={{ display: "flex", alignItems: "start" }}>
            <CheckboxElement
              control={control}
              name="enableGenerateByAmount"
              label={t("generateByAmount")}
            />

            <TextFieldElement
              name="amount"
              control={control}
              label=""
              type="number"
              disabled={!watch("enableGenerateByAmount")}
              inputProps={{ pattern: "\\d*", style: { marginTop: "5px" } }}
              error={!!errors.amount}
              helperText={errors.amount?.message ?? " "}
              variant="standard"
              placeholder="aaa"
            />
          </div>

          <TextFieldElement
            name="accounts"
            control={control}
            label={t("accounts")}
            disabled={watch("enableGenerateByAmount")}
            placeholder={t("separateByCommaFormat")}
            multiline
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button sx={{ width: "100px" }} variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>

        <Button
          sx={{ width: "100px" }}
          variant="contained"
          disabled={confirmLoading}
          startIcon={confirmLoading ? <CircularProgress size={20} /> : undefined}
          onClick={handleSave}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SysAccountEditModal;
