import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TimeAgo = ({ pastDate }: { pastDate: number }) => {
  const { t } = useTranslation();
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const past = new Date(pastDate);
      const ms = now.getTime() - past.getTime();
      const seconds = Math.floor((ms / 1000) % 60);
      const minutes = Math.floor((ms / 1000 / 60) % 60);
      const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
      const days = Math.floor((ms / (1000 * 60 * 60 * 24)) % 30);
      const months = Math.floor((ms / (1000 * 60 * 60 * 24 * 30)) % 12);
      const years = Math.floor(ms / (1000 * 60 * 60 * 24 * 365));

      setTimeAgo(`
        ${years !== 0 ? years + ` ${t("years")} ` : ""}
        ${months !== 0 ? months + ` ${t("moths")} ` : ""}
        ${days !== 0 ? days + ` ${t("days")} ` : ""}
        ${hours !== 0 ? hours + ` ${t("hours")} ` : ""}
        ${minutes !== 0 ? minutes + ` ${t("minutes")} ` : ""}
        ${seconds} ${t("secondsAgo")}
      `);
    }, 1000);

    return () => clearInterval(timer);
  }, [pastDate, t]);

  return <div>{timeAgo}</div>;
};

export default TimeAgo;
