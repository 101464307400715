import { zodResolver } from "@hookform/resolvers/zod";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/webpack-resolver";
import { useAtom } from "jotai/index";
import React, { useEffect, useMemo, useRef } from "react";
import AceEditor from "react-ace";
import { Controller, useForm } from "react-hook-form";
import { CheckboxElement, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import CustomSelectOption from "@/components/custom-select-option";
import { isOwner } from "@/lib/auth-predicates";
import { formatJson } from "@/lib/common-utils";
import { SysCfgCreateRequestDto, SysCfgRegularDto } from "@/shared/api";
import { meStore } from "@/store/store";
import { MakeOptional } from "@/types/commonTypes";

const CommonConfigWriteModal = ({
  isOpen,
  mode,
  onCancel,
  onSave,
  defaultValues,
  confirmLoading,
  disabled = false,
}: {
  isOpen: boolean;
  mode: "add" | "edit";
  onCancel: () => void;
  onSave: (config: SysCfgCreateRequestDto) => void;
  defaultValues?: MakeOptional<SysCfgRegularDto, "id" | "created_at" | "updated_at">;
  confirmLoading?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [me] = useAtom(meStore);

  const schema = useMemo(() => {
    return z.object({
      name: z.string().min(1),
      auth_groups: isOwner(me)
        ? z.array(z.string())
        : z.array(z.string()).min(1, t("mustContainsGroup")),
      enabled: z.boolean().default(true),
      data: z.string().refine(
        (data) => {
          try {
            const parsed = JSON.parse(data);
            return typeof parsed === "object" && parsed !== null && !Array.isArray(parsed);
          } catch {
            return false;
          }
        },
        { message: t("invalidJson") }
      ),
    });
  }, [me, t]);

  type Form = z.infer<typeof schema>;

  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery({
    enabled: isOpen,
  });

  const authGroups = useMemo(() => {
    return authGroupsRs?.data ?? [];
  }, [authGroupsRs]);

  const initData = useMemo<Partial<Form>>(() => {
    let groups = defaultValues ? Array.from(defaultValues.auth_groups ?? []) : [];

    if (authGroups.length === 1 && !isOwner(me)) {
      groups = authGroups.map((it) => it.name);
    }

    return defaultValues
      ? {
          ...defaultValues,
          auth_groups: groups,
          data: defaultValues.data ? formatJson(JSON.stringify(defaultValues.data)) : "{}",
        }
      : {
          auth_groups: groups,
          enabled: true,
          data: "{}",
        };
  }, [defaultValues, authGroups, me]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    mode: "onChange",
    disabled: disabled,
    resolver: zodResolver(schema),
    defaultValues: initData,
  });

  const submit = handleSubmit((data) =>
    onSave({
      ...data,
      auth_groups: new Set(data.auth_groups),
      data: JSON.parse(data.data),
    })
  );

  useEffect(() => {
    reset({ ...initData });
  }, [initData, isOpen, reset]);

  const aceRef = useRef(null);

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="lg">
      <DialogTitle>
        {mode === "add" ? t("addNewConfig") : t("editConfig", { name: initData.name })}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ paddingTop: "5px", width: "600px" }}>
          <TextFieldElement
            control={control}
            required
            name="name"
            label={t("name")}
            error={!!errors.name}
            helperText={errors.name?.message ?? " "}
            variant="standard"
            InputProps={{
              readOnly: mode === "edit",
            }}
          />

          <Controller
            control={control}
            name="auth_groups"
            rules={{
              required: !isOwner(me) ? "field is required" : undefined,
            }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                multiple
                disabled={disabled || (authGroupsRs?.data.length === 1 && !isOwner(me))}
                loading={authGroupsIsLoading}
                value={field.value}
                onChange={(_, data) => field.onChange(data)}
                options={
                  authGroupsRs?.data?.map((i) => i.name).sort((a, b) => a.localeCompare(b)) ??
                  []
                }
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <CustomSelectOption label={option} selected={selected} props={props} />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={!isOwner(me) ? t("authGroupsRequired") : t("authGroups")}
                    placeholder={t("authGroups")}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />

          {mode === "add" && (
            <CheckboxElement
              control={control}
              name="enabled"
              label={t("enabled")}
              disabled={disabled}
            />
          )}

          <Controller
            control={control}
            name="data"
            render={({ field }) => (
              <>
                <FormControl error={!!errors.data}>
                  <p>{t("data")}</p>
                  <AceEditor
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    readOnly={disabled}
                    defaultValue={initData.data}
                    ref={aceRef}
                    mode="json"
                    theme="dracula"
                    width="100%"
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      tabSize: 2,
                    }}
                  />
                  <FormHelperText>{errors.data?.message ?? " "}</FormHelperText>
                </FormControl>
              </>
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ width: "100px" }}
          variant="outlined"
          onClick={() => {
            onCancel();
          }}
        >
          {t("cancel")}
        </Button>

        <Button
          sx={{ width: "100px" }}
          variant="contained"
          disabled={disabled || confirmLoading}
          startIcon={confirmLoading ? <CircularProgress size={20} /> : undefined}
          onClick={submit}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommonConfigWriteModal;
