import { useBoardingPlayerSessionsQuery } from "@/api/hooks/boarding-player-sessions-hooks";
import { COMMON_REFETCH_INTERVAL } from "@/lib/constants";
import BoardingPlayerSessionsTable from "./boarding-player-sessions-table";

const BoardingPlayerSessionsModule = () => {
  const sessionsQuery = useBoardingPlayerSessionsQuery({
    refetchInterval: COMMON_REFETCH_INTERVAL,
  });

  return (
    <BoardingPlayerSessionsTable
      sessionsQuery={sessionsQuery}
    />
  );
};

export default BoardingPlayerSessionsModule; 