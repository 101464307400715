import { MaterialReactTable } from "material-react-table";
import React from "react";


import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import ProxyConfigTable from "@/module/room-configs-module/proxy-config-table";
import GameGroupConfigTable from "@/module/room-configs-module/game-group-config-table";
import NameConfigTable from "@/module/room-configs-module/name-config-table";
import AvatarConfigTable from "@/module/room-configs-module/avatar-config-table";
import PlayerGroupConfigTable from "@/module/room-configs-module/player-group-config-table";
import CmsAggregatorConfigTable from "@/module/room-configs-module/cms-aggregator-config-table";
import CommonConfigTable from "@/module/room-configs-module/common-config-table";
import UsernameConfigTable from "@/module/room-configs-module/username-config-table/username-config-table";
import ScheduleConfigTable from "@/module/room-configs-module/schedule-config-table";

const MainTable = ({
  room,
  cfg,
} : {
  room: string;
  cfg: string;
}) => {
  if (!room || !cfg) return <MaterialReactTable columns={[]} data={[]} />;

  switch (cfg) {
    case ROOM_CONFIGS_NAMES.PROXY:
      return <ProxyConfigTable room={room}/>;
    case ROOM_CONFIGS_NAMES.GAME_GROUP:
      return <GameGroupConfigTable room={room}/>;
    case ROOM_CONFIGS_NAMES.NICKNAME:
      return <NameConfigTable room={room}/>;
    case ROOM_CONFIGS_NAMES.AVATAR:
      return <AvatarConfigTable room={room}/>;
    case ROOM_CONFIGS_NAMES.PLAYER_GROUP:
      return <PlayerGroupConfigTable room={room} />;
    case ROOM_CONFIGS_NAMES.SCHEDULE:
      return <ScheduleConfigTable room={room} />;
    case ROOM_CONFIGS_NAMES.CMS_AGGREGATOR:
      return <CmsAggregatorConfigTable room={room} />;
    case ROOM_CONFIGS_NAMES.USERNAME:
      return <UsernameConfigTable room={room} />;
    default:
      return <CommonConfigTable room={room} cfg={cfg} />;
  }
};

export default MainTable;
