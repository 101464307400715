import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { BoardingTable } from "@/shared/api";
import { useApi } from "@/shared/api/use-api";

export const useBoardingTablesQuery = (
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<BoardingTable[]>>, "queryKey">
) => {
  const { boardingTablesApi } = useApi();

  return useQuery({
    queryKey: ["/boarding-tables"],
    queryFn: ({ signal }) => boardingTablesApi.getAllBoardingTables({ signal }),
    ...queryOptions,
  });
};

export const useBoardingTableByIdQuery = (
  {
    tableId,
  }: {
    tableId: string;
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<BoardingTable>>, "queryKey">
) => {
  const { boardingTablesApi } = useApi();

  return useQuery({
    queryKey: [`/boarding-tables/${tableId}`],
    queryFn: ({ signal }) => boardingTablesApi.getBoardingTable(tableId, { signal }),
    ...queryOptions,
  });
};

export const useBoardingTablesByIdsQuery = (
  {
    tableIds,
  }: {
    tableIds: Array<string>;
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<BoardingTable[]>>, "queryKey">
) => {
  const { boardingTablesApi } = useApi();

  return useQuery({
    queryKey: ["/boarding-tables/batch", tableIds],
    queryFn: ({ signal }) => boardingTablesApi.getBoardingTables(new Set(tableIds), { signal }),
    ...queryOptions,
  });
}; 