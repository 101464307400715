import { Power, PowerOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { AxiosResponse } from "axios";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import React from "react";
import { useTranslation } from "react-i18next";

import { useSysCfgChangeEnabledMutation } from "@/api/hooks/sys-cfg-hooks";
import { assembleParticularFilter } from "@/lib/cfg-utils";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { KeyFilter } from "@/shared/api";
import { NameIdentityItem } from "@/types/commonTypes";
import { enqueueSnackbar } from "notistack";
import Popconfirm from "@/components/popcofirm";

const ChangeEnabledConfigsTableControl = <T extends NameIdentityItem>({
  roomName,
  table,
  enabled,
  filters,
  onSuccess,
  totalElementsCount,
}: {
  roomName: string;
  table: MRT_TableInstance<T>;
  enabled: boolean;
  filters: Set<KeyFilter>;
  onSuccess?: (rowsCount: number) => void;
  totalElementsCount: number;
}) => {
  const { t } = useTranslation();
  const mutation = useSysCfgChangeEnabledMutation();

  function handleMutationAction(rows: MRT_Row<T>[]) {
    if (rows.length > 0) {
      mutateParticular(rows);
    } else {
      mutateAll();
    }
  }

  function mutateAll() {
    callMutation(filters);
  }

  function mutateParticular(rows: MRT_Row<T>[]) {
    callMutation(assembleParticularFilter(rows));
  }

  function callMutation(filters: Set<KeyFilter>) {
    const op = enabled ? "enabling" : "disabling";
    const fop = enabled ? "enabled" : "disabled";
    mutation.mutate(
      {
        domain: roomName,
        cfgName: ROOM_CONFIGS_NAMES.ACCOUNT,
        enabled,
        filters,
      },
      {
        onSuccess: (rs: AxiosResponse<number>) => {
          enqueueSnackbar(`${rs.data} configs successfully ${fop}`, { variant: "success" });
          onSuccess && onSuccess(rs.data);
        },
        onError: (err) => {
          console.error(`configs ${op} error`, err);
          enqueueSnackbar(`Something went wrong during ${op}`, { variant: "error" });
        },
      }
    );
  }

  return (
    <Popconfirm
      title={enabled ? t("enableItems") : t("disableItems")}
      description={enabled ? t("areSureEnableItems") : t("areSureDisableItems")}
      onConfirm={() => handleMutationAction(table.getSelectedRowModel().rows)}
    >
      <IconButton size="small" style={{ fontSize: 12 }} color={enabled ? "success" : "error"}>
        {enabled ? (
          <>
            <Power />{" "}
            {t("enableLength", {
              length:
                table.getSelectedRowModel().rows.length > 0
                  ? table.getSelectedRowModel().rows.length
                  : totalElementsCount,
            })}
          </>
        ) : (
          <>
            <PowerOff />{" "}
            {t("disableLength", {
              length:
                table.getSelectedRowModel().rows.length > 0
                  ? table.getSelectedRowModel().rows.length
                  : totalElementsCount,
            })}
          </>
        )}
      </IconButton>
    </Popconfirm>
  );
};

export default ChangeEnabledConfigsTableControl;
