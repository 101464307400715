import { UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { MRT_PaginationState } from "material-react-table";

import {
  KeyFilter,
  KeySort,
  PageResponseSysAccountSessionRegularDto,
  QueryDto,
} from "@/shared/api";
import { useApi } from "@/shared/api/use-api";

export const useSessionsQuery = (
  {
    domain,
    filters = new Set<KeyFilter>(),
    sort = [],
    pagination,
  }: {
    domain: string;
    filters?: Set<KeyFilter>;
    sort?: KeySort[];
    pagination?: MRT_PaginationState;
  },
  queryOptions?: Omit<
    UseQueryOptions<AxiosResponse<PageResponseSysAccountSessionRegularDto>>,
    "queryKey"
  >
) => {
  const { sysAccountSessionsApi } = useApi();

  return useQuery({
    queryKey: [`/sys-data/${domain}/session`, filters, sort, pagination],
    queryFn: ({ signal }) => {
      const query: QueryDto = {
        filters: filters,
        page: pagination
          ? { page_number: pagination.pageIndex, page_size: pagination.pageSize }
          : undefined,
        sort: sort,
      };
      return sysAccountSessionsApi.getAllSessions(domain, query, { signal });
    },
    placeholderData: keepPreviousData,
    ...queryOptions,
  });
};
