import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useCfgPerm } from "@/auth/hooks";
import AppFullPageTabs from "@/components/app-full-page-tabs";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { ReportChatTable } from "@/module/service-configs-module/players-reporter-module/chats/report-chat-table";
import { ReportRuleTable } from "@/module/service-configs-module/players-reporter-module/rules/report-rule-table";
import { Route } from "@/routes/service-config/$configName";

const PlayersReporterModule = () => {
  const { t } = useTranslation();
  const navigate = Route.useNavigate();
  const { playersReporterTab: tabValue } = Route.useSearch();

  const chatsPerms = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_CHAT,
  });

  const rulesPerm = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_RULE,
  });

  const setTabValue = (value: number) => {
    void navigate({ search: { playersReporterTab: value } });
  };

  const [chatsAmount, setChatsAmount] = useState<number>(0);
  const [rulesAmount, setRulesAmount] = useState<number>(0);

  return (
    <AppFullPageTabs
      onTabChange={(val) => setTabValue(val)}
      defaultValue={tabValue}
      items={[
        {
          value: 0,
          label: t("chatsAmount", { amount: chatsAmount }),
          render: () => <ReportChatTable setRowsAmount={(val) => setChatsAmount(val)} />,
          condition: () => rulesPerm.read,
        },
        {
          value: 1,
          label: t("rulesAmount", { amount: rulesAmount }),
          render: () => <ReportRuleTable setRowsAmount={(val) => setRulesAmount(val)} />,
          condition: () => chatsPerms.read,
        },
      ]}
    />
  );
};

export default PlayersReporterModule;
