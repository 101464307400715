import { Divider, Grid, Typography } from "@mui/material";

import JsonFormManagementModule from "@/module/json-form-management-module/json-form-management-module";
import { useTranslation } from "react-i18next";

const JsonFormManagementPage = () => {

  const {t} = useTranslation();

  return (
    <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
      <Grid item>
{/*
        <Typography.Title level={2}>{t("roomManagement")}</Typography.Title>
*/}
        <Typography  variant="h2">{t("jsonFormManagement")}</Typography>
      </Grid>
      <Grid item>
        <Divider style={{ marginTop: 0 }} />
      </Grid>
      <Grid item>
        <JsonFormManagementModule />
      </Grid>
    </Grid>
  );
};

export default JsonFormManagementPage;
