import { MRT_ColumnDef, MRT_Row, MRT_RowData } from "material-react-table";
import { CheckboxElement } from "react-hook-form-mui";

import SelectAllColumnAction from "@/components/select-all-column-action";

interface CreateCheckboxColumnProps<T extends MRT_RowData, RT extends string> {
  columnName: RT;
  headerText: string;
  formInputName: (row: MRT_Row<T>) => string;
  onSelectColumn?: (column: RT) => void;
  onClearColumn?: (column: RT) => void;
}

export const createCheckboxColumn = <T extends MRT_RowData, RT extends string = string>({
  columnName,
  headerText,
  formInputName,
  onSelectColumn,
  onClearColumn,
}: CreateCheckboxColumnProps<T, RT>): MRT_ColumnDef<T> => ({
  header: headerText,
  accessorFn: (row: T) => `${row[columnName]}`,
  renderColumnActionsMenuItems: ({ internalColumnMenuItems, closeMenu }) => [
    ...SelectAllColumnAction({
      value: columnName,
      onSelect: onSelectColumn,
      onClear: onClearColumn,
      closeMenu,
    }),
    ...internalColumnMenuItems,
  ],
  Cell: ({ row }) => (
    <CheckboxElement
      name={formInputName(row)}
      checked={row.original[columnName]}
    />
  ),
});
