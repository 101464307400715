import { UseQueryOptions, useQuery, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { JsonFormRegularDto, KeyFilter } from "@/shared/api";
import { useApi } from "@/shared/api/use-api";
import { RequiredError } from "@/shared/api/base";

export const useJsonFormByFiltersQuery = (
  {
    keyFilters,
  }: {
    keyFilters?: KeyFilter[];
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<JsonFormRegularDto[]>>, "queryKey">
) => {
  const { jsonFormApi } = useApi();

  return useQuery({
    queryKey: ["/json-form/get-by-filters", keyFilters],
    queryFn: ({ signal }) =>
      jsonFormApi.getAllJsonFromsByFilters(
        { filters: keyFilters ? new Set(keyFilters) : undefined },
        { signal }
      ),
    ...queryOptions,
  });
};

export const useJsonFormRawJson = (
  {
    keyFilters,
  }: {
    keyFilters?: KeyFilter[];
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<string[]>>, "queryKey">
) => {
  const { jsonFormApi } = useApi();

  return useQuery({
    queryKey: ["/json-form/get-by-filters-row", keyFilters],
    queryFn: ({ signal }) =>
      jsonFormApi.getAllJsonFromsByFiltersRawJson(
        { filters: keyFilters ? new Set(keyFilters) : undefined },
        { signal }
      ),
    ...queryOptions,
  });
};

export const useJsonFormUpdateMutation = () => {
  const { jsonFormApi } = useApi();

  type Options = {
    id: string;
    body: string;
  };

  return useMutation<AxiosResponse<number>, RequiredError, Options>({
    mutationFn: ({ id, body }: Options) =>
      jsonFormApi.patchJsonForm(id, body),
  });
};