import { Navigate, createFileRoute } from '@tanstack/react-router'
import { useAtom } from 'jotai/index'

import { isSuperAdmin } from '@/lib/auth-predicates'
import { meStore } from '@/store/store'
import JsonFormManagementPage from "@/pages/json-form-management-page";

export const Route = createFileRoute('/json-form-management/')({
  component: Component,
})

function Component() {
  const [me] = useAtom(meStore)

  if (!isSuperAdmin(me)) {
    return <Navigate to="/" />
  }

  return <JsonFormManagementPage />
}
