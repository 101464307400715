import { zodResolver } from "@hookform/resolvers/zod";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useAtom } from "jotai/index";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { CheckboxElement, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { useSysCfgQuery } from "@/api/hooks/sys-cfg-hooks";
import CustomSelectOption from "@/components/custom-select-option";
import { isOwner } from "@/lib/auth-predicates";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { SysCfgCreateRequestDto, SysCfgRegularDto } from "@/shared/api";
import { meStore } from "@/store/store";
import { MakeOptional } from "@/types/commonTypes";

interface PlayerGroupConfigData {
  vpip: {
    min: number;
    max: number;
  };
  pfr: {
    min: number;
    max: number;
  };
  hands: {
    min: number;
    max: number;
  };
  ai_profiles: string[];
}

const PlayerGroupConfigModal = ({
  isOpen,
  mode,
  onCancel,
  onSave,
  defaultValues,
  confirmLoading,
  disabled = false,
}: {
  isOpen: boolean;
  mode: "add" | "edit";
  onCancel: () => void;
  onSave: (config: SysCfgCreateRequestDto) => void;
  defaultValues?: MakeOptional<SysCfgRegularDto, "id" | "created_at" | "updated_at">;
  confirmLoading?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [me] = useAtom(meStore);

  const { data: aiProfilesRs } = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES,
    config_name: ROOM_CONFIGS_NAMES.AI_PROFILE,
  });

  const aiProfiles = useMemo<string[]>(() => {
    return aiProfilesRs?.data.content.map((item) => item.name) ?? [];
  }, [aiProfilesRs]);

  const schema = useMemo(() => {
    return z
      .object({
        name: z.string().min(1, t("fieldRequired")),
        auth_groups: isOwner(me)
          ? z.array(z.string())
          : z.array(z.string()).min(1, t("mustContainsGroup")),
        enabled: z.boolean().default(true),
        vpip_min: z.coerce.number().min(0).max(100),
        vpip_max: z.coerce.number().min(0).max(100),
        pfr_min: z.coerce.number().min(0).max(100),
        pfr_max: z.coerce.number().min(0).max(100),
        hands_min: z.coerce.number().min(0),
        hands_max: z.coerce.number().min(0),
        ai_profiles: z.array(z.string()).default([]),
      })
      .refine((data) => data.vpip_min <= data.vpip_max, {
        message: t("minMustBeLessThanMax"),
        path: ["vpip_min"],
      })
      .refine((data) => data.pfr_min <= data.pfr_max, {
        message: t("minMustBeLessThanMax"),
        path: ["pfr_min"],
      })
      .refine((data) => data.hands_min <= data.hands_max, {
        message: t("minMustBeLessThanMax"),
        path: ["hands_min"],
      });
  }, [me, t]);

  type Form = z.infer<typeof schema>;

  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery({
    enabled: isOpen,
  });

  const authGroups = useMemo(() => {
    return authGroupsRs?.data ?? [];
  }, [authGroupsRs]);

  const initData = useMemo<Partial<Form>>(() => {
    let groups = defaultValues ? Array.from(defaultValues.auth_groups ?? []) : [];

    if (authGroups.length === 1 && !isOwner(me)) {
      groups = authGroups.map((it) => it.name);
    }

    const data = defaultValues?.data as PlayerGroupConfigData | undefined;

    return {
      name: defaultValues?.name || "",
      auth_groups: groups,
      enabled: defaultValues?.enabled ?? true,
      vpip_min: data?.vpip?.min ?? 0,
      vpip_max: data?.vpip?.max ?? 100,
      pfr_min: data?.pfr?.min ?? 0,
      pfr_max: data?.pfr?.max ?? 100,
      hands_min: data?.hands?.min ?? 0,
      hands_max: data?.hands?.max ?? 10000,
      ai_profiles: data?.ai_profiles ?? [],
    };
  }, [defaultValues, authGroups, me]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    mode: "onChange",
    disabled: disabled,
    resolver: zodResolver(schema),
    defaultValues: initData,
  });

  const submit = handleSubmit((data) => {
    const configData: PlayerGroupConfigData = {
      vpip: {
        min: data.vpip_min,
        max: data.vpip_max,
      },
      pfr: {
        min: data.pfr_min,
        max: data.pfr_max,
      },
      hands: {
        min: data.hands_min,
        max: data.hands_max,
      },
      ai_profiles: data.ai_profiles,
    };

    onSave({
      name: data.name,
      auth_groups: new Set(data.auth_groups),
      enabled: data.enabled,
      data: configData,
    });
  });

  useEffect(() => {
    reset(initData);
  }, [initData, isOpen, reset]);

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="md" fullWidth>
      <DialogTitle>
        {mode === "add" ? t("addNewConfig") : t("editConfig", { name: initData.name })}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ paddingTop: "16px" }}>
          <TextFieldElement
            control={control}
            required
            name="name"
            label={t("name")}
            error={!!errors.name}
            helperText={errors.name?.message ?? " "}
            variant="outlined"
            fullWidth
            // disabled={mode === "edit"}
            InputProps={{
              disabled: mode === "edit",
              // readOnly: mode === "edit",
            }}
          />

          <Controller
            control={control}
            name="auth_groups"
            rules={{
              required: !isOwner(me) ? "field is required" : undefined,
            }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                multiple
                disabled={disabled || (authGroupsRs?.data.length === 1 && !isOwner(me))}
                loading={authGroupsIsLoading}
                value={field.value}
                onChange={(_, data) => field.onChange(data)}
                options={
                  authGroupsRs?.data?.map((i) => i.name).sort((a, b) => a.localeCompare(b)) ??
                  []
                }
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <CustomSelectOption label={option} selected={selected} props={props} />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={!isOwner(me) ? t("authGroupsRequired") : t("authGroups")}
                    placeholder={t("authGroups")}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />

          {mode === "add" && (
            <CheckboxElement
              control={control}
              name="enabled"
              label={t("enabled")}
              disabled={disabled}
            />
          )}

          <Stack direction="row" spacing={2}>
            <TextFieldElement
              control={control}
              name="vpip_min"
              label={t("playerGroupTable.vpipMin")}
              type="number"
              fullWidth
              error={!!errors.vpip_min}
              helperText={errors.vpip_min?.message}
              InputProps={{ inputProps: { min: 0, max: 100 } }}
            />
            <TextFieldElement
              control={control}
              name="vpip_max"
              label={t("playerGroupTable.vpipMax")}
              type="number"
              fullWidth
              error={!!errors.vpip_max}
              helperText={errors.vpip_max?.message}
              InputProps={{ inputProps: { min: 0, max: 100 } }}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextFieldElement
              control={control}
              name="pfr_min"
              label={t("playerGroupTable.pfrMin")}
              type="number"
              fullWidth
              error={!!errors.pfr_min}
              helperText={errors.pfr_min?.message}
              InputProps={{ inputProps: { min: 0, max: 100 } }}
            />
            <TextFieldElement
              control={control}
              name="pfr_max"
              label={t("playerGroupTable.pfrMax")}
              type="number"
              fullWidth
              error={!!errors.pfr_max}
              helperText={errors.pfr_max?.message}
              InputProps={{ inputProps: { min: 0, max: 100 } }}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextFieldElement
              control={control}
              name="hands_min"
              label={t("playerGroupTable.handsMin")}
              type="number"
              fullWidth
              error={!!errors.hands_min}
              helperText={errors.hands_min?.message}
              InputProps={{ inputProps: { min: 0 } }}
            />
            <TextFieldElement
              control={control}
              name="hands_max"
              label={t("playerGroupTable.handsMax")}
              type="number"
              fullWidth
              error={!!errors.hands_max}
              helperText={errors.hands_max?.message}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Stack>

          <Controller
            control={control}
            name="ai_profiles"
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                disabled={disabled}
                value={field.value}
                onChange={(_, data) => field.onChange(data)}
                options={aiProfiles}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <CustomSelectOption label={option} selected={selected} props={props} />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("playerGroupTable.aiProfiles")}
                    placeholder={t("playerGroupTable.aiProfiles")}
                  />
                )}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {t("cancel")}
        </Button>

        <Button
          variant="contained"
          disabled={disabled || confirmLoading}
          startIcon={confirmLoading ? <CircularProgress size={20} /> : undefined}
          onClick={submit}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlayerGroupConfigModal;

