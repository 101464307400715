import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useAtom } from "jotai/index";
import React, { useEffect, useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { useSysCfgRawQuery } from "@/api/hooks/sys-cfg-hooks";
import { formInitState } from "@/components/config-modals/players-reporter/report-rule/constants";
import {
  EditReportRuleType,
  EditedReportRuleType,
} from "@/components/config-modals/players-reporter/report-rule/types";
import CustomSelectOption from "@/components/custom-select-option";
import { isOwner } from "@/lib/auth-predicates";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { meStore } from "@/store/store";

export default function ReportRuleEditModal({
  open,
  mode,
  initialData,
  onSave,
  onCancel,
  disabled = false,
}: {
  open: boolean;
  mode?: string;
  initialData?: EditReportRuleType;
  onSave?: (data: EditedReportRuleType) => void;
  onCancel?: () => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const { data: authGroupsRs } = useAuthGroupsQuery();
  const [me] = useAtom(meStore);

  const defaultValues = useMemo<EditedReportRuleType>(() => {
    let authGroups = initialData?.auth_groups ? Array.from(initialData.auth_groups) : [];

    if (authGroupsRs?.data.length === 1 && !isOwner(me)) {
      authGroups = authGroupsRs.data.map((it) => it.name);
    }

    return initialData
      ? {
          ...initialData,
          enabled: true,
          authGroups: authGroups,
        }
      : {
          ...formInitState,
          authGroups: authGroups,
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, /* authGroupsRs,*/ me]); //TODO: fix authGroupsRs. Because of authGroupsRs default values got broken by tab switching


  const { data: clusteringPatternsRs, refetch } = useSysCfgRawQuery(
    {
      domain: ROOM_NAMES.SERVICES,
      config_name: ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN,
    },
    {
      enabled: open,
      refetchOnMount: true,
    }
  );

  const {
    register,
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<EditedReportRuleType>({
    mode: "all",
    disabled: disabled,
    defaultValues: { ...defaultValues },
  });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, open, reset]);

  const onSubmit: SubmitHandler<EditedReportRuleType> = (data) => {
    data.name =
      data.name && data.name !== ""
        ? data.name
        : "clustering_type_".concat(Date.now().toString()); //TODO: move to Back-end

    if (!onSave) return;
    onSave(data);

    onCancel && onCancel(); //purifying form and close dialog
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          return 0;
        }}
        key="Report chat modal"
      >
        <DialogTitle>{mode === "add" ? t("addReportRule") : t("editReportRule")}</DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "500px",
            }}
          >
            <TextField
              key="dialog-rc"
              {...register("name", { required: t("fieldRequired") })}
              label={t("name")}
              placeholder={t("name")}
              variant="standard"
              error={!!errors.name}
              helperText={!!errors.name && errors.name.message}
              InputProps={{
                readOnly: mode === "edit",
              }}
            />

            <Controller
              name="authGroups"
              control={control}
              rules={{
                required: !isOwner(me) ? t("fieldRequired") : undefined,
              }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  options={authGroupsRs?.data.map((it) => it.name) ?? []}
                  value={value}
                  disabled={disabled || (authGroupsRs?.data.length === 1 && !isOwner(me))}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    setValue("authGroups", newValue); // Update the value in react-hook-form
                  }}
                  renderOption={(props, option, { selected }) => (
                    <CustomSelectOption label={option} selected={selected} props={props} />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t("authGroups")}
                      placeholder={t("authGroups")}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <TextField
              key="dialog-rr-display-name"
              {...register("data.display_name", {
                required: t("fieldRequired"),
              })}
              label={t("displayName")}
              placeholder={t("displayName")}
              variant="standard"
              error={!!errors.data?.display_name}
              helperText={!!errors.data?.display_name && errors.data.display_name.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-rr-amount"
              {...register("data.amount", {
                required: t("fieldRequired"),
                valueAsNumber: true,
                max: {
                  value: 50000000,
                  message: t("numberShouldBeLessThan", { value: 50000000 }),
                },
                min: {
                  value: 0,
                  message: t("numberShouldBeHigherThan", { value: 0 }),
                },
              })}
              label={t("amount")}
              placeholder={t("amount")}
              variant="standard"
              error={!!errors.data?.amount}
              helperText={!!errors.data?.amount && errors.data.amount.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-rr-frequency"
              {...register("data.frequency", {
                required: t("fieldRequired"),
                valueAsNumber: true,
                max: {
                  value: 50000000,
                  message: t("numberShouldBeLessThan", { value: 50000000 }),
                },
                min: {
                  value: 0,
                  message: t("numberShouldBeHigherThan", { value: 0 }),
                },
              })}
              label={t("frequency")}
              placeholder={t("frequency")}
              variant="standard"
              error={!!errors.data?.frequency}
              helperText={!!errors.data?.frequency && errors.data.frequency.message}
              disabled={disabled}
            />

            <Controller
              name="data.pattern"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  // freeSolo
                  disabled={disabled}
                  options={clusteringPatternsRs?.data ?? []}
                  getOptionLabel={(option) => option.name}
                  value={clusteringPatternsRs?.data?.filter((option) =>
                    value.includes(option.name)
                  )}
                  onChange={(_event, newValue) => {
                    const newIds = newValue.map((item) => item.name);
                    onChange(newIds);
                    setValue("data.pattern", newIds);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("pattern")}
                      placeholder={t("addTag")}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      onFocus={() => refetch()}
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.limits"
              control={control}
              rules={{
                required: t("fieldRequired"),
                validate: (v) =>
                  v.filter((a) => isNaN(a)).length === 0 || t("enterOnlyNumber"),
                pattern: {
                  value: /^[0-9]+$/,
                  message: t("enterOnlyNumber"),
                },
              }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={value}
                  disabled={disabled}
                  onChange={(_event, newValue) => {
                    onChange(newValue);

                    setValue(
                      "data.limits",
                      newValue.map((v) => parseInt(v.toString()))
                    ); // Update the value in react-hook-form
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("limits")}
                      placeholder={t("addTag")}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                      // inputProps={{inputMode:"numeric", pattern:"[0-9]*", title: "Enter only digits"}}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" disabled={disabled}>
                {t("submit")}
              </Button>
              <Button type="button" onClick={() => onCancel && onCancel()}>
                {t("cancel")}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
