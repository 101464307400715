import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { MRT_PaginationState } from "material-react-table";

import {
  AvatarServiceResponseDto,
  KeyFilter,
  KeySort,
  PageResponseSysCfgRegularDto,
  ResultDtoLong,
  ResultDtoSysCfgRegularDto,
  SysCfgCreateRequestDto,
  SysCfgCrossRoomRsDto,
  SysCfgListRawDto,
  SysCfgRawDto,
  SysCfgRegularDto,
  SysCfgUpdateRequestDto,
} from "@/shared/api";
import { RequiredError } from "@/shared/api/base";
import { useApi } from "@/shared/api/use-api";

export const useSysCfgQuery = (
  {
    domain,
    config_name,
    sort,
    filters = new Set<KeyFilter>(),
    pagination,
  }: {
    domain: string;
    config_name: string;
    sort?: Array<KeySort>;
    filters?: Set<KeyFilter>;
    pagination?: MRT_PaginationState;
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PageResponseSysCfgRegularDto>>, "queryKey">
) => {
  const { sysCfgApi } = useApi();

  return useQuery({
    queryKey: [`/sys-cfg/${domain}/${config_name}/find-all`],
    queryFn: ({ signal }) =>
      sysCfgApi.getAllCfgs(
        domain,
        config_name,
        {
          sort,
          filters,
          page: pagination
            ? { page_number: pagination.pageIndex, page_size: pagination.pageSize }
            : undefined,
        },
        { signal }
      ),
    ...queryOptions,
  });
};

export const useSysCfgCrossRoomsQuery = (
  {
    config_name,
    rooms,
  }: {
    config_name: string;
    rooms: string[];
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<SysCfgCrossRoomRsDto>>, "queryKey">
) => {
  const { sysCfgApi } = useApi();

  return useQuery({
    queryKey: [`/sys-cfg/cross-rooms/find-all`],
    queryFn: ({ signal }) => sysCfgApi.getAllCfgsRawCrossRoom(config_name, { rooms }, { signal }),
    ...queryOptions,
  });
};

export const useSysCfgRawQuery = (
  {
    domain,
    config_name,
  }: {
    domain: string;
    config_name: string;
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<SysCfgRawDto[]>>, "queryKey">
) => {
  const { sysCfgApi } = useApi();

  return useQuery({
    queryKey: [`/sys-cfg/${domain}/${config_name}/find-all/raw`],
    queryFn: ({ signal }) => sysCfgApi.getAllCfgsRow(domain, config_name, { signal }),
    ...queryOptions,
  });
};

export const useSysCfgCombinedQuery = (
  {
    domain,
    config_names,
  }: {
    domain: string;
    config_names: Array<string>;
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<SysCfgListRawDto[]>>, "queryKey">
) => {
  const { sysCfgApi } = useApi();

  return useQuery({
    queryKey: [`/sys-cfg/${domain}/find-all/combined/raw`, config_names],
    // staleTime: 1000 * 60,
    queryFn: ({ signal }) =>
      sysCfgApi.getAllCfgsRawCombined(domain, { config_names }, { signal }),
    ...queryOptions,
  });
};

//looking for a particular config from all rooms
export const useSysCfgInRoomsQuery = (
  {
    configName,
  }: {
    configName: string;
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<SysCfgRawDto[]>>, "queryKey">
) => {
  const { sysCfgApi } = useApi();

  return useQuery({
    queryKey: [`/multi-room/${configName}/find-all/raw`, configName],
    // staleTime: 1000 * 60,
    queryFn: ({ signal }) => sysCfgApi.getAllCfgsRawMultiRoom(configName, { signal }),
    ...queryOptions,
  });
};

export const useSysCfgCreateMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    cfgName: string;
    body: SysCfgCreateRequestDto;
  };

  return useMutation<AxiosResponse<SysCfgRegularDto>, RequiredError, Options>({
    mutationFn: ({ domain, cfgName, body }: Options) =>
      sysCfgApi.createCfg(domain, cfgName, body),
  });
};

export const useSysCfgsCreateMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    cfgName: string;
    body: SysCfgCreateRequestDto[];
  };

  return useMutation({
    mutationFn: ({ domain, cfgName, body }: Options) =>
      sysCfgApi.createCfgs(domain, cfgName, body),
  });
};

export const useUsernamesCreateMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    body: Set<string>;
  };

  return useMutation({
    mutationFn: ({ domain, body }: Options) => sysCfgApi.uploadUsernames(domain, body),
  });
};

export const useSysCfgAddNicknamesMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    nicknames: string[];
  };

  return useMutation<AxiosResponse<AvatarServiceResponseDto>, RequiredError, Options>({
    mutationFn: ({ domain, nicknames }: Options) =>
      sysCfgApi.addNicknames(domain, new Set(nicknames)),
  });
};

export const useSysCfgAddAvatarsMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    file: File;
  };

  return useMutation<AxiosResponse<AvatarServiceResponseDto>, RequiredError, Options>({
    mutationFn: ({ domain, file }: Options) => sysCfgApi.addAvatars(domain, file),
  });
};

export const useSysCfgUpdateMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    cfgName: string;
    name: string;
    body: SysCfgUpdateRequestDto;
  };

  return useMutation<AxiosResponse<SysCfgRegularDto>, RequiredError, Options>({
    mutationFn: ({ domain, cfgName, name, body }: Options) =>
      sysCfgApi.updateConfig(domain, cfgName, name, body),
  });
};

export const useSysCfgDeleteMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    cfgName: string;
    filters?: Set<KeyFilter>;
  };

  return useMutation<AxiosResponse<ResultDtoLong>, RequiredError, Options>({
    mutationFn: ({ domain, cfgName, filters }: Options) =>
      sysCfgApi.deleteCfg(domain, cfgName, { filters }),
  });
};

export const useSysCfgChangeEnabledMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    cfgName: string;
    enabled: boolean;
    filters?: Set<KeyFilter>;
  };

  return useMutation<AxiosResponse<number>, RequiredError, Options>({
    mutationFn: ({ domain, cfgName, enabled, filters }: Options) =>
      sysCfgApi.changeCfgEnabled(domain, cfgName, enabled, { filters }),
  });
};

export const useSysCfgEditDataMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    cfgName: string;
    filters?: Set<KeyFilter>;
    updates: { [key: string]: object };
  };

  return useMutation<AxiosResponse<number>, RequiredError, Options>({
    mutationFn: ({ domain, cfgName, filters, updates }: Options) =>
      sysCfgApi.patchCfgData(domain, cfgName, { filters, updates }),
  });
};

export const useSysCfgAddProxiesMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    authGroups: Array<string>;
    connectionStrings: Array<string>;
  };

  return useMutation<AxiosResponse<ResultDtoSysCfgRegularDto[]>, RequiredError, Options>({
    mutationFn: ({ domain, connectionStrings, authGroups }: Options) =>
      sysCfgApi.addProxies(domain, { connectionStrings, authGroups }),
  });
};

export const useSysCfgApplyGroupsMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    cfgName: string;
    filters?: Set<KeyFilter>;
    authGroups: Array<string>;
  };

  return useMutation<AxiosResponse<number>, RequiredError, Options>({
    mutationFn: ({ domain, cfgName, filters, authGroups: auth_groups }: Options) =>
      sysCfgApi.applyAuthGroups(domain, cfgName, { filters, auth_groups }),
  });
};

export const useSysCfgReplaceRouterInstanceMutation = () => {
  const { sysCfgApi } = useApi();

  type Options = {
    domain: string;
    oldInstance: string;
    newInstance: string;
  };

  return useMutation<AxiosResponse<number>, RequiredError, Options>({
    mutationFn: ({ domain, oldInstance, newInstance }: Options) =>
      sysCfgApi.replaceRouterRuleInstance(domain, {
        old_instance: oldInstance,
        new_instance: newInstance,
      }),
  });
};
