import { ClearAll, DoneAll } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface SelectClearAllControlProps {
  onSelectAll?: () => void;
  onClearAll?: () => void;
  disabled?: boolean;
}

const SelectClearAllControl = ({ onSelectAll, onClearAll, disabled }: SelectClearAllControlProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("enableAll")}>
        <IconButton size="small" onClick={() => onSelectAll && onSelectAll()} disabled={disabled}>
          <DoneAll />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("clearAll")}>
        <IconButton size="small" onClick={() => onClearAll && onClearAll()} disabled={disabled}>
          <ClearAll />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default SelectClearAllControl; 