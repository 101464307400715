import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppFullPageTabs from "@/components/app-full-page-tabs";
import RouterInstanceTable from "@/module/room-configs-module/router-config-module/instance-table/router-instance-table";
import RouterRuleTable from "@/module/room-configs-module/router-config-module/rule-table/router-rule-table";
import { Route } from "@/routes/service-config/$configName";

const RouterConfigModule = () => {
  const { t } = useTranslation();

  const navigate = Route.useNavigate();
  const { routerTab: tabValue } = Route.useSearch();

  const setTabValue = (value: number) => {
    void navigate({ search: { routerTab: value } });
  };

  const [rulesAmount, setRulesAmount] = useState<number>(0);
  const [instanceAmount, setInstanceAmount] = useState<number>(0);

  return (
    <AppFullPageTabs
      onTabChange={(val) => setTabValue(val)}
      defaultValue={tabValue}
      items={[
        {
          value: 0,
          label: t("rulesAmount", { amount: rulesAmount }),
          render: () => <RouterRuleTable setRowsAmount={(val) => setRulesAmount(val)} />,
        },
        {
          value: 1,
          label: t("instancesAmount", { amount: instanceAmount }),
          render: () => (
            <RouterInstanceTable setRowsAmount={(val) => setInstanceAmount(val)} />
          ),
        },
      ]}
    />
  );
};

export default RouterConfigModule;
