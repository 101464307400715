import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppFullPageTabs from "@/components/app-full-page-tabs";
import ClusteringPatternConfigTable from "@/module/room-configs-module/clustering-pattern-module/pattern-table/clustering-pattern-config-table";
import ClusteringPatternResultsTable from "@/module/room-configs-module/clustering-pattern-module/result-table/clustering-pattern-results-table";
import { Route } from "@/routes/service-config/$configName";

const ClusteringPatternModule = () => {
  const { t } = useTranslation();
  const navigate = Route.useNavigate();
  const { clusteringPatternTab: tabValue } = Route.useSearch();

  const setTabValue = (value: number) => {
    void navigate({ search: { clusteringPatternTab: value } });
  };

  const [patternsAmount, setPatternsAmount] = useState<number>(0);
  const [resultsAmount, setResultsAmount] = useState<number>(0);

  return (
    <AppFullPageTabs
      onTabChange={(value) => setTabValue(value)}
      defaultValue={tabValue}
      items={[
        {
          value: 0,
          label: t("patternsAmount", { amount: patternsAmount }),
          render: () => (
            <ClusteringPatternConfigTable
              setRowsAmount={(amount) => setPatternsAmount(amount)}
            />
          ),
        },
        {
          value: 1,
          label: t("resultsAmount", { amount: resultsAmount }),
          render: () => (
            <ClusteringPatternResultsTable
              setRowsAmount={(amount) => setResultsAmount(amount)}
            />
          ),
        },
      ]}
    />
  );
};

export default ClusteringPatternModule;
