import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { AxiosResponse } from "axios";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import React from "react";
import { useTranslation } from "react-i18next";

import { useSysCfgEditDataMutation } from "@/api/hooks/sys-cfg-hooks";
import BulkEditModal from "@/components/BulkEditModal/bulk-edit-modal";
import { Input } from "@/components/BulkEditModal/types";
import { assembleParticularFilter } from "@/lib/cfg-utils";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { KeyFilter } from "@/shared/api";
import { BulkEditFormFieldValue, NameIdentityItem } from "@/types/commonTypes";
import { enqueueSnackbar } from "notistack";

const ConfigsEditTableControl = <T extends NameIdentityItem>({
  title,
  roomName,
  table,
  filters,
  fields,
  onSuccess,
  isOpen,
  setIsOpen,
  totalElementsCount,
}: {
  title?: string;
  roomName: string;
  table: MRT_TableInstance<T>;
  filters: Set<KeyFilter>;
  fields: Input[];
  onSuccess?: (rowsCount: number) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  totalElementsCount: number;
}) => {
  const { t } = useTranslation();
  const mutation = useSysCfgEditDataMutation();

  function handleMutationAction(
    rows: MRT_Row<T>[],
    data: Map<string, BulkEditFormFieldValue>
  ) {
    if (rows.length > 0) {
      mutateParticular(rows, data);
    } else {
      mutateAll(data);
    }
  }

  function mutateAll(data: Map<string, BulkEditFormFieldValue>) {
    callMutation(filters, data);
  }

  function mutateParticular(rows: MRT_Row<T>[], data: Map<string, BulkEditFormFieldValue>) {
    callMutation(assembleParticularFilter(rows), data);
  }

  function callMutation(filters: Set<KeyFilter>, data: Map<string, BulkEditFormFieldValue>) {
    mutation.mutate(
      {
        domain: roomName,
        cfgName: ROOM_CONFIGS_NAMES.ACCOUNT,
        filters,
        updates: Object.fromEntries(data) as unknown as { [key: string]: object },
      },
      {
        onSuccess: (rs: AxiosResponse<number>) => {
          enqueueSnackbar(t("configsSuccessfullyUpdated", { data: rs.data }), { variant: "success" });
          onSuccess && onSuccess(rs.data);
        },
        onError: (err) => {
          console.error("configs update error", err);
          enqueueSnackbar(t("somethingWentWrongDuringUpdatingData"), { variant: "error" });
        },
      }
    );
  }

  return (
    <>
      <IconButton
        size="small"
        style={{ fontSize: 12 }}
        color="warning"
        onClick={() => setIsOpen(true)}
      >
        <Edit />{" "}
        {t("editLength", {
          length:
            table.getSelectedRowModel().rows.length > 0
              ? table.getSelectedRowModel().rows.length
              : totalElementsCount,
        })}
      </IconButton>

      <BulkEditModal
        title={title}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={(data) => handleMutationAction(table.getSelectedRowModel().rows, data)}
        isLoading={mutation.isPending}
        fields={fields}
        fullWidth
        maxWidth="sm"
        editElementsCount={
          table.getSelectedRowModel().rows.length > 0
            ? table.getSelectedRowModel().rows.length
            : totalElementsCount
        }
      />
    </>
  );
};

export default ConfigsEditTableControl;
