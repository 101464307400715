import { MRT_TableInstance } from "material-react-table";
import { KeyFilter, SysAccountSessionRegularDto } from "@/shared/api";
import StopSessionsTableControl from "@/module/accounts-module/components/stop-sessions-table-control";
import JoinClubsTableControl from "@/module/accounts-module/components/join-clubs-table-control";
import LeaveClubsTableControl from "@/module/accounts-module/components/leave-clubs-table-control";
import {useCfgPerm} from "@/auth/hooks";
import {ROOM_CONFIGS_NAMES} from "@/lib/constants";


const ServiceSessionsTopToolbar = (
  {
    roomName,
    table,
    filters,
    onSuccess,
    totalElementsCount
  } : {
    roomName: string;
    table: MRT_TableInstance<SysAccountSessionRegularDto>;
    filters: Set<KeyFilter>;
    onSuccess?: () => void;
    totalElementsCount: number;
  }
) => {
  const accountPerms = useCfgPerm({roomName: roomName, cfgName: ROOM_CONFIGS_NAMES.ACCOUNT});

  return (
    <>
      <div style={{ display: "flex" }}>
        {accountPerms.execute && (
          <StopSessionsTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
            onSuccess={() => onSuccess && onSuccess()}
          />
        )}

        {accountPerms.execute && (
          <JoinClubsTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
            onSuccess={() => onSuccess && onSuccess()}
          />
        )}

        {accountPerms.execute && (
          <LeaveClubsTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
            onSuccess={() => onSuccess && onSuccess()}
          />
        )}
      </div>
    </>
  )
}



export default ServiceSessionsTopToolbar