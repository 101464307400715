import { Grid } from "@mui/material";
import { useAtom } from "jotai/index";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import AuthGroupsModule from "@/module/auth-groups-module";
import { headerTitle } from "@/store/store";

const AuthGroupsPage = () => {
  const { t } = useTranslation();

  const [, setHeaderTitle] = useAtom(headerTitle);
  useEffect(() => {
    setHeaderTitle(t("authGroups"));
  }, [setHeaderTitle, t]);

  return <AuthGroupsModule />;
};

export default AuthGroupsPage;
