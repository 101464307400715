import { ListItemButton, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RoomSelectTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    // color: theme.palette.sidebar.lightText,
    // border: "1px solid",
    // borderColor: theme.palette.sidebar.contrastText
  },
  "& .MuiTextField-root": {
    // color: theme.palette.sidebar.contrastText,
  },
  "& .MuiAutocomplete-popupIndicator": {
    // color: theme.palette.sidebar.contrastText,
  },
  "& .MuiAutocomplete-clearIndicator": {
    // color: theme.palette.sidebar.contrastText,
  },
}));

export const MenuListItemButton = styled(ListItemButton)(({ theme }) => ({
  "&:hover": {
    // color: theme.palette.sidebar.lightText
  },
  "&.Mui-selected": {
    // backgroundColor: theme.palette.primary.main,
    // color: "#fff"
  },
  "&.Mui-selected:hover": {
    // backgroundColor: theme.palette.primary.main,
    // color: "#fff"
  },
}));

export const MenuListSubItemButton = styled(ListItemButton)(({ theme }) => ({
  "&:hover": {
    // color: theme.palette.sidebar.lightText
  },
  "&.Mui-selected": {
    // backgroundColor: theme.palette.primary.dark,
    // borderRadius: "10px",
    // margin: "0 10px",
    // color: "#fff"
  },
  "&.Mui-selected:hover": {
    // backgroundColor: theme.palette.primary.dark,
    // color: "#fff"
  },
}));
