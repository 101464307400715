import { AddOutlined, ContentCopy, DeleteOutlineOutlined, Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysCfgQuery, useSysCfgsCreateMutation } from "@/api/hooks/sys-cfg-hooks";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import ReportChatEditModal from "@/components/config-modals/players-reporter/report-chat/report-chat-edit-modal";
import { EditedReportChatType } from "@/components/config-modals/players-reporter/report-chat/types";
import { useTableSettings } from "@/hooks/use-table-settings";
import { assembleNamesFilter, mapSysCfgToEditReportChat } from "@/lib/cfg-utils";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import {
  useConfigChangeEnabled,
  useDeleteConfigs,
  useUpdateConfig,
} from "@/module/room-configs-module/hooks/room-configs-hooks";
import { useReporterChatTableColumns } from "@/module/service-configs-module/players-reporter-module/chats/report-chat-table-columns";
import { ResultDtoLong, SysCfgCreateRequestDto, SysCfgRegularDto } from "@/shared/api";
import Popconfirm from "@/components/popcofirm";

export const ReportChatTable = ({
  setRowsAmount,
}: {
  setRowsAmount: (amount: number) => void;
}) => {
  const { t } = useTranslation();
  const { mutate: createCfgsMutate } = useSysCfgsCreateMutation();
  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  const cfgPerm = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_CHAT,
  });

  const isOwner = useIsOwnerOrSuperAdmin();

  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch: refetchConfigs,
  } = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES,
    config_name: ROOM_CONFIGS_NAMES.REPORT_CHAT,
  });

  useEffect(() => {
    setRowsAmount(configsRs?.data.content.length ?? 0);
  }, [configsRs, setRowsAmount]);

  function handleClickSaveNewConfig(config: SysCfgCreateRequestDto[]) {
    createCfgsMutate(
      {
        domain: ROOM_NAMES.SERVICES,
        cfgName: ROOM_CONFIGS_NAMES.REPORT_CHAT,
        body: config.map((item) => ({ ...item, authGroups: item.auth_groups })),
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("elementsCreated"), { variant: "success" });
          setModalInitValues(undefined);
          setModalMode(undefined);
          setEditItemName("");
          void refetchConfigs();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministratorMessage", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Adding config failure", err);
        },
      }
    );
  }

  const updateConfig = useUpdateConfig({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_CHAT,
    onSuccessCallback: () => {
      void refetchConfigs();
    },
  });

  function handleClickUpdateConfig(name: string, config: EditedReportChatType) {
    updateConfig(name, { ...config, auth_groups: new Set(config.authGroups) });
  }

  const handleChangeEnableBulk = useConfigChangeEnabled({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_CHAT,
    onSuccessCallback: (table) => {
      void refetchConfigs();
      table.resetRowSelection();
    },
  });

  const handleDeleteConfigs = useDeleteConfigs({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_CHAT,
    onSuccessCallback: (table, response) => {
      setBulkOperationResult([response.data]);
      void refetchConfigs();
      table.resetRowSelection();
    },
  });

  const [modalMode, setModalMode] = useState<"edit" | "add" | undefined>(undefined);
  const [modalInitValues, setModalInitValues] = useState<SysCfgRegularDto | undefined>(
    undefined
  );
  const [editItemName, setEditItemName] = useState<string>("");
  const { defaultProps, state, columnFilters, grouping, globalFilter } =
    useTableSettings<SysCfgRegularDto>("reportChat");

  const columns = useReporterChatTableColumns();

  const table = useMaterialReactTable({
    data: configsRs?.data.content ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    ...defaultProps,
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "name", desc: false }],
    },
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: true,
    enablePagination: false,
    enableSorting: true,
    state: {
      ...state,
      isLoading: configsIsLoading,
    },
    muiTableBodyCellProps: {
      sx: {
        whiteSpace: "pre",
      },
    },

    renderTopToolbarCustomActions: () => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button
              onClick={() => {
                setModalInitValues(undefined);
                setModalMode("add");
              }}
            >
              <AddOutlined />
              {t("new")}
            </Button>
          )}
          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> {t("enable")}
                </IconButton>
              )}
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> {t("disalbe")}
                </IconButton>
              )}
              {cfgPerm.delete && (
                <Popconfirm
                  title={t("deleteItems")}
                  description={t("deleteItemsConfirm")}
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                    <DeleteOutlineOutlined /> {t("delete")}
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={ROOM_NAMES.SERVICES}
                  cfgName={ROOM_CONFIGS_NAMES.REPORT_CHAT}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetchConfigs()}
                />
              )}
            </div>
          )}
        </div>
      );
    },

    renderRowActions: ({ row }) => (
      <Box>
        {cfgPerm.write && (
          <>
            <IconButton
              onClick={() => {
                setModalInitValues({ ...row.original });
                setModalMode("edit");
                setEditItemName(row.original.name);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                setModalInitValues({
                  ...row.original,
                  data: { ...row.original.data },
                  name: "",
                  enabled: false,
                });
                setModalMode("add");
              }}
            >
              <ContentCopy />
            </IconButton>
          </>
        )}
      </Box>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      <ReportChatEditModal
        open={!!modalMode}
        mode={modalMode}
        initialData={mapSysCfgToEditReportChat(modalInitValues)}
        onSave={(data) => {
          if (modalMode === "add") return handleClickSaveNewConfig([data]);
          if (modalMode === "edit") return handleClickUpdateConfig(editItemName, data);
        }}
        /*onSave={(data) => {
          handleClickSaveNewConfig([data]);
        }}*/
        onCancel={() => {
          setModalMode(undefined);
          setModalInitValues(undefined);
          setEditItemName("");
        }}
      />

      {bulkOperationResult && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title="Operation result"
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};
