import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import SysAccountsGenerateModal from "@/module/accounts-module/components/sys-accounts-generate-modal";
import { useGenerateNewAccounts } from "@/module/accounts-module/hooks/accounts-mutate-hooks";
import { GenerateAccountsDto } from "@/shared/api";
import { Button } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";

const GenerateAccountsTableControl = ({
  roomName,
  onSuccess,
}: {
  roomName: string;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { mutate: handleClickGenerateNewAccounts, isPending: generatedAccountIsPending } =
    useGenerateNewAccounts({
      roomName: roomName,
      successCallback: () => {
        setIsOpen(false);
        onSuccess && onSuccess();
      },
    });

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <AddOutlined />
        {t("generate")}
      </Button>

      <SysAccountsGenerateModal
        mode="add"
        accountType="regular"
        isOpen={isOpen}
        roomName={roomName}
        onSave={(data: GenerateAccountsDto) => handleClickGenerateNewAccounts({ ...data })}
        onClose={() => setIsOpen(false)}
        confirmLoading={generatedAccountIsPending}
      />
    </>
  );
};

export default GenerateAccountsTableControl;
