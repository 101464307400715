import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { PlatformServiceRawDto, PlatformServiceRegularDto } from "@/shared/api";
import { useApi } from "@/shared/api/use-api";

export const usePlatformServicesFromAllRoomsQuery = (
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PlatformServiceRegularDto[]>>, "queryKey">
) => {
  const { platformServiceApi } = useApi();

  return useQuery({
    queryKey: [`/platform-service/combined/all-rooms/find-all`],
    queryFn: ({ signal }) => platformServiceApi.getAllPlatformServicesFromAllRooms({ signal }),
    ...queryOptions,
  });
};

export const useGetAllGSQuery = (
  { roomName }: { roomName: string },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PlatformServiceRegularDto[]>>, "queryKey">
) => {
  const { platformServiceApi } = useApi();

  return useQuery({
    queryKey: [`/platform-service/GS/${roomName}/find-all`],
    queryFn: ({ signal }) => platformServiceApi.getGSList(roomName, { signal }),
    ...queryOptions,
  });
};

export const useGetAllTESQuery = (
  { roomName }: { roomName: string },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PlatformServiceRegularDto[]>>, "queryKey">
) => {
  const { platformServiceApi } = useApi();

  return useQuery({
    queryKey: [`/platform-service/TES/${roomName}/find-all`],
    queryFn: ({ signal }) => platformServiceApi.getTESList(roomName, { signal }),
    ...queryOptions,
  });
};

export const useGetAllGSRawQuery = (
  { roomName }: { roomName: string },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PlatformServiceRawDto[]>>, "queryKey">
) => {
  const { platformServiceApi } = useApi();

  return useQuery({
    queryKey: [`/platform-service/GS/${roomName}/find-all/raw`],
    queryFn: ({ signal }) => platformServiceApi.getGSRaw(roomName, { signal }),
    ...queryOptions,
  });
};

export const useGetAllTESRawQuery = (
  { roomName }: { roomName: string },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PlatformServiceRawDto[]>>, "queryKey">
) => {
  const { platformServiceApi } = useApi();

  return useQuery({
    queryKey: [`/platform-service/TES/${roomName}/find-all/raw`],
    queryFn: ({ signal }) => platformServiceApi.getTESRaw(roomName, { signal }),
    ...queryOptions,
  });
};
