import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { AxiosResponse } from "axios";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import React from "react";
import { useTranslation } from "react-i18next";

import { useSysCfgDeleteMutation } from "@/api/hooks/sys-cfg-hooks";
import { assembleParticularFilter } from "@/lib/cfg-utils";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { KeyFilter, ResultDtoLong } from "@/shared/api";
import { NameIdentityItem } from "@/types/commonTypes";
import Popconfirm from "@/components/popcofirm";
import { enqueueSnackbar } from "notistack";

const DeleteConfigsTableControl = <T extends NameIdentityItem>({
  roomName,
  table,
  filters,
  onSuccessDelete,
  totalElementsCount,
}: {
  roomName: string;
  table: MRT_TableInstance<T>;
  filters: Set<KeyFilter>;
  onSuccessDelete?: (rowsCount: ResultDtoLong) => void;
  totalElementsCount: number;
}) => {
  const { t } = useTranslation();

  const mutation = useSysCfgDeleteMutation();

  function handleMutateAction(rows: MRT_Row<T>[]) {
    if (rows.length > 0) {
      mutateParticular(rows);
    } else {
      mutateAll();
    }
    table.resetRowSelection();
  }

  function mutateAll() {
    callMutation(filters);
  }

  function mutateParticular(rows: MRT_Row<T>[]) {
    callMutation(assembleParticularFilter(rows));
  }

  function callMutation(filters: Set<KeyFilter>) {
    mutation.mutate(
      {
        domain: roomName,
        cfgName: ROOM_CONFIGS_NAMES.ACCOUNT,
        filters,
      },
      {
        onSuccess: (rs: AxiosResponse<ResultDtoLong>) => {
          enqueueSnackbar(`${rs.data.data} configs successfully deleted`, { variant: "success" });
          onSuccessDelete && onSuccessDelete(rs.data);
        },
        onError: (err) => {
          console.error("configs deletion error", err);
          enqueueSnackbar(t("somethingWentWrongDuringDeleting"), { variant: "error" });
        },
      }
    );
  }

  return (
    <Popconfirm
      title={t("deleteItems")}
      description={t("deleteItemsConfirm")}
      onConfirm={() => handleMutateAction(table.getSelectedRowModel().rows)}
    >
      <IconButton size="small" style={{ fontSize: 12 }}>
        <Delete />{" "}
        {t("deleteLength", {
          length:
            table.getSelectedRowModel().rows.length > 0
              ? table.getSelectedRowModel().rows.length
              : totalElementsCount,
        })}
      </IconButton>
    </Popconfirm>
  );
};

export default DeleteConfigsTableControl;
