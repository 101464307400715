import { WebStorageStateStore } from "oidc-client-ts";

import { ConfigDto } from "@/shared/api";

export const COMMON_REFETCH_INTERVAL = 15 * 1000;
export const SESSION_ID_QUERY_PARAM_NAME = "session_id";

export const OIDC_CONFIG_INITIAL = {
  authority: "",
  client_id: "",
  redirect_uri: `${window.location.origin}`,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  post_logout_redirect_uri: `${window.location.origin}`,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

export const STALE_TIME_60 = 1000 * 60;

export const SUPER_ADMIN_NAME = "super_admin";

export const APP_GMT_DATE_FORMAT = "DD.MM.YYYY HH:mm [GMT]";
export const APP_COMMON_DATE_FORMAT = "DD.MM.YYYY HH:mm [UTC]Z";
export const DATE_FORMAT_WITH_UTC_TAG = "DD.MM.YYYY HH:mm";

export const APP_COMMON_DATE_TIME_FORMAT = "HH:mm:ss DD.MM.YYYY [UTC]Z";

export const ROOM_NAMES = {
  HH_ECO: "hh_eco",
  PW_ECO: "pw_eco",
  WPC_ECO: "wpc_eco",
  FF_ECO: "ff_eco",
  SERVICES: "services",
};

export const ROOM_CONFIGS_NAMES = {
  ACCOUNT: "account",
  PROXY: "proxy",
  BEHAVIOUR: "behaviour",
  SCHEDULE: "schedule",
  GAME_GROUP: "game_group",
  GAME_TYPE: "game_type",
  TIMING: "timing",
  INSURANCE: "insurance",
  BOARDING: "boarding",
  PLAYER_GROUP: "player_group",
  NICKNAME: "nickname",
  AVATAR: "avatar",
  CMS_AGGREGATOR: "cms_aggregator",
  CLUB: "_cms_aggregator",

  //Shared services
  AI_PROFILE: "ai_profile",
  CLUSTERING_TYPE: "clustering_type",
  CLUSTERING_PATTERN: "clustering_pattern",
  COMPENSATOR: "compensator",
  NOTIFY: "notify",
  ROUTER: "router",
  WHITELIST: "whitelist",
  STAT_SERVICE: "stat_service",
  EMAIL_DOMAIN: "email_domain",
  USERNAME: "username",
  TABLE_CACHE: "table_cache",
  PLAYHUB_ROOM: "playhub_room",
  BOARDING_TABLE: "boarding_tables",
  BOARDING_PLAYER_SESSIONS: "boarding_player_session",

  REPORT_CHAT: "report_chat",
  REPORT_RULE: "report_rule",
  PLAYERS_REPORTER: "players_reporter", //Entity for bounding report chat and report rule under single tab
  ROOM_TABLES_CACHE_ROOM_TABLES: "room_tables_cache_room_tables",
  ROOM_TABLES_CACHE_ROOM_INFO: "room_tables_cache_room_info",
} as const;

export const ACCOUNT_RELATION_CONFIGS_NAMES = [
  ROOM_CONFIGS_NAMES.PROXY,
  ROOM_CONFIGS_NAMES.BEHAVIOUR,
  ROOM_CONFIGS_NAMES.SCHEDULE,
  ROOM_CONFIGS_NAMES.AI_PROFILE,
  ROOM_CONFIGS_NAMES.GAME_GROUP,
  ROOM_CONFIGS_NAMES.GAME_TYPE,
  ROOM_CONFIGS_NAMES.TIMING,
  ROOM_CONFIGS_NAMES.INSURANCE,
];

export const ACCOUNT_RELATION_SERVICE_CONFIGS_NAMES = [ROOM_CONFIGS_NAMES.AI_PROFILE];

export enum ManageableServiceType {
  TES = 0,
  GS = 1,
}

export enum ExploitationStage {
  PRODUCTION = 0,
  TEST = 1,
  UNAVAILABLE = 2,
}

export const ACCOUNT_STATUS = new Map<number | undefined, string>([
  [-1, "Initial"],
  [0, "Ok"],
  [1, "Auth is failed"],
  [2, "Invalid wallet code"],
  [3, "Frozen"],
  [4, "Blocked"],
  [5, "Device is locked"],
  [6, "Kicked off"],
  [undefined, "Error"],
]);

export enum AccountTypeEnum {
  Regular = 0,
  Manager = 1,
  Grabber = 2,
  Scanner = 3,
}

export enum AccountStatusEnum {
  INITIAL = -1,
  OK = 0,
  AUTH_IS_FAILED = 1,
  INVALID_WALLET_CODE = 2,
  FROZEN = 3,
  BLOCKED = 4,
  DEVICE_IS_LOCKED = 5,
  KICKED_OFF = 6,
}

export const COMMON_PERM = {
  read: false,
  write: false,
  delete: false,
  execute: false,
  exclusive: false,
  disable_auth_filters: false,
};

export const PLAYHUB_CONFIGS: ConfigDto[] = [
  { name: "chat", ...COMMON_PERM },
  { name: "game", ...COMMON_PERM },
  { name: "man", ...COMMON_PERM },
  { name: "bot", ...COMMON_PERM },
  { name: "players", ...COMMON_PERM },
  { name: "table", ...COMMON_PERM },
];

// export const ACCOUNT_STAT_FILTER_TYPE = "stats"
export const ACCOUNT_STAT_ROW_ID_PREFIX = `data.aid_stats`;
export const ACCOUNT_STAT_GAME_TYPES = ["sd", "nlh", "omaha"];
export const ACCOUNT_STAT_GAME_TYPE_KEYS = ["vpip", "pfr", "hands"];
export const ACCOUNT_GAME_STATS_COLUMN_NAMES = ACCOUNT_STAT_GAME_TYPES.flatMap((gameType) =>
  ACCOUNT_STAT_GAME_TYPE_KEYS.map(
    (gameTypeKey) => `${ACCOUNT_STAT_ROW_ID_PREFIX}.${gameType}.${gameTypeKey}`
  )
);
