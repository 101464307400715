/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { BoardingTable } from "../models";

/**
 * BoardingTablesApi - axios parameter creator
 * @export
 */
export const BoardingTablesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получить все столы посадки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBoardingTables: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/boarding-tables`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить стол посадки по ID
     * @param {string} tableId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBoardingTable: async (
      tableId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tableId' is not null or undefined
      assertParamExists("getBoardingTable", "tableId", tableId);
      const localVarPath = `/api/v2/boarding-tables/{tableId}`.replace(
        `{${"tableId"}}`,
        encodeURIComponent(String(tableId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить столы посадки по списку ID
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBoardingTables: async (
      requestBody: Set<string>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists("getBoardingTables", "requestBody", requestBody);
      const localVarPath = `/api/v2/boarding-tables/batch`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BoardingTablesApi - functional programming interface
 * @export
 */
export const BoardingTablesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BoardingTablesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Получить все столы посадки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllBoardingTables(
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoardingTable>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBoardingTables(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BoardingTablesApi.getAllBoardingTables"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Получить стол посадки по ID
     * @param {string} tableId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBoardingTable(
      tableId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardingTable>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardingTable(
        tableId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BoardingTablesApi.getBoardingTable"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Получить столы посадки по списку ID
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBoardingTables(
      requestBody: Set<string>,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoardingTable>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardingTables(
        requestBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["BoardingTablesApi.getBoardingTables"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * BoardingTablesApi - factory interface
 * @export
 */
export const BoardingTablesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BoardingTablesApiFp(configuration);
  return {
    /**
     *
     * @summary Получить все столы посадки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllBoardingTables(options?: any): AxiosPromise<Array<BoardingTable>> {
      return localVarFp
        .getAllBoardingTables(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить стол посадки по ID
     * @param {string} tableId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBoardingTable(tableId: string, options?: any): AxiosPromise<BoardingTable> {
      return localVarFp
        .getBoardingTable(tableId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить столы посадки по списку ID
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBoardingTables(
      requestBody: Set<string>,
      options?: any
    ): AxiosPromise<Array<BoardingTable>> {
      return localVarFp
        .getBoardingTables(requestBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BoardingTablesApi - object-oriented interface
 * @export
 * @class BoardingTablesApi
 * @extends {BaseAPI}
 */
export class BoardingTablesApi extends BaseAPI {
  /**
   *
   * @summary Получить все столы посадки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardingTablesApi
   */
  public getAllBoardingTables(options?: RawAxiosRequestConfig) {
    return BoardingTablesApiFp(this.configuration)
      .getAllBoardingTables(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить стол посадки по ID
   * @param {string} tableId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardingTablesApi
   */
  public getBoardingTable(tableId: string, options?: RawAxiosRequestConfig) {
    return BoardingTablesApiFp(this.configuration)
      .getBoardingTable(tableId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить столы посадки по списку ID
   * @param {Set<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardingTablesApi
   */
  public getBoardingTables(requestBody: Set<string>, options?: RawAxiosRequestConfig) {
    return BoardingTablesApiFp(this.configuration)
      .getBoardingTables(requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
