import { MRT_ColumnDef, MRT_TableOptions, useMaterialReactTable } from "material-react-table";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useTableSettings } from "@/hooks/use-table-settings";
import CreateDomainCfgTableControl from "@/module/room-management-module/components/create-domain-cfg-table-control";
import { NameIdentityItem } from "@/types/commonTypes";

const useConfigsListTable = (
  {
    domain,
    configs,
    refetchConfigs,
  }: {
    domain: string;
    configs: NameIdentityItem[];
    refetchConfigs: () => void;
  },
  tableOptions?: Omit<MRT_TableOptions<NameIdentityItem>, "columns" | "data">
) => {
  const { t } = useTranslation();
  const columns = useMemo<MRT_ColumnDef<NameIdentityItem>[]>(
    () => [
      {
        id: "name",
        header: t("name"),
        accessorFn: (row) => row.name,
        grow: 1,
      },
    ],
    [t]
  );
  const { defaultProps, state } = useTableSettings<NameIdentityItem>("roomsConfigList");

  return useMaterialReactTable({
    data: configs,
    columns: columns,
    getRowId: (row) => String(row.name),
    layoutMode: "grid",
    enableStickyHeader: true,
    renderTopToolbarCustomActions: () => {
      return (
        <div>
          <CreateDomainCfgTableControl domain={domain} onSuccess={() => refetchConfigs()} />
        </div>
      );
    },
    ...tableOptions,
    ...defaultProps,
    state,
    initialState: {
      density: "compact",
      ...tableOptions?.initialState,
      sorting: [{ id: "name", desc: false }],
    },
  });
};

export default useConfigsListTable;
