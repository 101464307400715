import { ClearAll, DoneAll } from "@mui/icons-material";
import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

interface SelectAllItemProps<T> {
  value: T;
  onSelect?: (value: T) => void;
  onClear?: (value: T) => void;
  closeMenu: () => void;
}

const SelectAllColumnAction = <T,>({ value, onSelect, onClear, closeMenu }: SelectAllItemProps<T>) => {
  const { t } = useTranslation();

  return [
    <MenuItem
      key="select-all"
      onClick={() => {
        onSelect && onSelect(value);
        closeMenu();
      }}
    >
      <ListItemIcon>
        <DoneAll />
      </ListItemIcon>
      <ListItemText>{t("selectAll")}</ListItemText>
    </MenuItem>,
    <MenuItem
      key="clear-all"
      onClick={() => {
        onClear && onClear(value);
        closeMenu();
      }}
    >
      <ListItemIcon>
        <ClearAll />
      </ListItemIcon>
      <ListItemText>{t("clearAll")}</ListItemText>
    </MenuItem>,
    <Divider key="select-all-divider" />,
  ];
};

export default SelectAllColumnAction; 