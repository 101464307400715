import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ResultStatsDto } from "@/shared/api";
import { MRT_HidedColumnDef } from "@/types/commonTypes";

export const useStatserviceTableColumns = () => {
  const { t } = useTranslation();

  return useMemo<MRT_HidedColumnDef<ResultStatsDto>[]>(
    () => [
      {
        id: "player.pid",
        header: t("statsServiceTable.pid"),
        accessorFn: (row) => row.player.pid,
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.cid"),
        id: "player.cid",
        accessorFn: (row) => row.player.cid,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.aid"),
        id: "player.aid",
        accessorFn: (row) => row.player.aid,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.gameGroup"),
        id: "player.game_group",
        accessorFn: (row) => row.player.game_group,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.room"),
        id: "player.room",
        accessorFn: (row) => row.player.room,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.limit"),
        id: "player.limit",
        accessorFn: (row) => row.player.limit,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.gameType"),
        id: "player.game_type",
        accessorFn: (row) => row.player.game_type,
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.technology"),
        id: "player.technology",
        accessorFn: (row) => row.player.technology,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.hands_count"),
        id: "hands_count",
        accessorFn: (row) => row.hands_count,
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.vpip"),
        id: "vpip",
        accessorFn: (row) => row.vpip,
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.pfr"),
        id: "pfr",
        accessorFn: (row) => row.pfr,
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.wtsd"),
        id: "wtsd",
        accessorFn: (row) => row.wtsd,
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.third_bet"),
        id: "third_bet",
        accessorFn: (row) => row.third_bet,
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.open_raise_pot_bet"),
        id: "open_raise_pot_bet",
        accessorFn: (row) => row.open_raise_pot_bet,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_sb"),
        id: "open_raise_05_sb",
        accessorFn: (row) => row.open_raise_05_sb,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_sb"),
        id: "open_raise_0667_sb",
        accessorFn: (row) => row.open_raise_0667_sb,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_sb"),
        id: "open_raise_10_sb",
        accessorFn: (row) => row.open_raise_10_sb,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_bb"),
        id: "open_raise_05_bb",
        accessorFn: (row) => row.open_raise_05_bb,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_bb"),
        id: "open_raise_0667_bb",
        accessorFn: (row) => row.open_raise_0667_bb,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_bb"),
        id: "open_raise_10_bb",
        accessorFn: (row) => row.open_raise_10_bb,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_bt"),
        id: "open_raise_05_bt",
        accessorFn: (row) => row.open_raise_05_bt,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_bt"),
        id: "open_raise_0667_bt",
        accessorFn: (row) => row.open_raise_0667_bt,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_bt"),
        id: "open_raise_10_bt",
        accessorFn: (row) => row.open_raise_10_bt,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_co"),
        id: "open_raise_05_co",
        accessorFn: (row) => row.open_raise_05_co,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_co"),
        id: "open_raise_0667_co",
        accessorFn: (row) => row.open_raise_0667_co,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_co"),
        id: "open_raise_10_co",
        accessorFn: (row) => row.open_raise_10_co,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_mp"),
        id: "open_raise_05_mp",
        accessorFn: (row) => row.open_raise_05_mp,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_mp"),
        id: "open_raise_0667_mp",
        accessorFn: (row) => row.open_raise_0667_mp,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_mp"),
        id: "open_raise_10_mp",
        accessorFn: (row) => row.open_raise_10_mp,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_mp1"),
        id: "open_raise_05_mp1",
        accessorFn: (row) => row.open_raise_05_mp1,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_mp1"),
        id: "open_raise_0667_mp1",
        accessorFn: (row) => row.open_raise_0667_mp1,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_mp1"),
        id: "open_raise_10_mp1",
        accessorFn: (row) => row.open_raise_10_mp1,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_mp2"),
        id: "open_raise_05_mp2",
        accessorFn: (row) => row.open_raise_05_mp2,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_mp2"),
        id: "open_raise_0667_mp2",
        accessorFn: (row) => row.open_raise_0667_mp2,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_mp2"),
        id: "open_raise_10_mp2",
        accessorFn: (row) => row.open_raise_10_mp2,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_utg"),
        id: "open_raise_05_utg",
        accessorFn: (row) => row.open_raise_05_utg,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_utg1"),
        id: "open_raise_05_utg1",
        accessorFn: (row) => row.open_raise_05_utg1,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_utg2"),
        id: "open_raise_05_utg2",
        accessorFn: (row) => row.open_raise_05_utg2,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_utg"),
        id: "open_raise_0667_utg",
        accessorFn: (row) => row.open_raise_0667_utg,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_utg1"),
        id: "open_raise_0667_utg1",
        accessorFn: (row) => row.open_raise_0667_utg1,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_utg2"),
        id: "open_raise_0667_utg2",
        accessorFn: (row) => row.open_raise_0667_utg2,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_utg"),
        id: "open_raise_10_utg",
        accessorFn: (row) => row.open_raise_10_utg,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_utg1"),
        id: "open_raise_10_utg1",
        accessorFn: (row) => row.open_raise_10_utg1,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_utg2"),
        id: "open_raise_10_utg2",
        accessorFn: (row) => row.open_raise_10_utg2,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_sb"),
        id: "open_limp_sb",
        accessorFn: (row) => row.open_limp_sb,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_bb"),
        id: "open_limp_bb",
        accessorFn: (row) => row.open_limp_bb,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_bt"),
        id: "open_limp_bt",
        accessorFn: (row) => row.open_limp_bt,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_co"),
        id: "open_limp_co",
        accessorFn: (row) => row.open_limp_co,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_mp"),
        id: "open_limp_mp",
        accessorFn: (row) => row.open_limp_mp,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_mp1"),
        id: "open_limp_mp1",
        accessorFn: (row) => row.open_limp_mp1,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_mp2"),
        id: "open_limp_mp2",
        accessorFn: (row) => row.open_limp_mp2,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_utg"),
        id: "open_limp_utg",
        accessorFn: (row) => row.open_limp_utg,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_utg1"),
        id: "open_limp_utg1",
        accessorFn: (row) => row.open_limp_utg1,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_utg2"),
        id: "open_limp_utg2",
        accessorFn: (row) => row.open_limp_utg2,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_0001_to_015"),
        id: "bet_flop_0001_to_015",
        accessorFn: (row) => row.bet_flop_0001_to_015,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_015_to_029"),
        id: "bet_flop_015_to_029",
        accessorFn: (row) => row.bet_flop_015_to_029,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_029_to_038"),
        id: "bet_flop_029_to_038",
        accessorFn: (row) => row.bet_flop_029_to_038,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_038_to_045"),
        id: "bet_flop_038_to_045",
        accessorFn: (row) => row.bet_flop_038_to_045,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_045_to_055"),
        id: "bet_flop_045_to_055",
        accessorFn: (row) => row.bet_flop_045_to_055,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_055_to_062"),
        id: "bet_flop_055_to_062",
        accessorFn: (row) => row.bet_flop_055_to_062,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_062_to_071"),
        id: "bet_flop_062_to_071",
        accessorFn: (row) => row.bet_flop_062_to_071,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_071_to_08"),
        id: "bet_flop_071_to_08",
        accessorFn: (row) => row.bet_flop_071_to_08,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_08_to_095"),
        id: "bet_flop_08_to_095",
        accessorFn: (row) => row.bet_flop_08_to_095,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_095_to_105"),
        id: "bet_flop_095_to_105",
        accessorFn: (row) => row.bet_flop_095_to_105,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_105_to_12"),
        id: "bet_flop_105_to_12",
        accessorFn: (row) => row.bet_flop_105_to_12,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_12_to_100"),
        id: "bet_flop_12_to_100",
        accessorFn: (row) => row.bet_flop_12_to_100,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_0001_to_015"),
        id: "bet_turn_0001_to_015",
        accessorFn: (row) => row.bet_turn_0001_to_015,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_015_to_029"),
        id: "bet_turn_015_to_029",
        accessorFn: (row) => row.bet_turn_015_to_029,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_029_to_038"),
        id: "bet_turn_029_to_038",
        accessorFn: (row) => row.bet_turn_029_to_038,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_038_to_045"),
        id: "bet_turn_038_to_045",
        accessorFn: (row) => row.bet_turn_038_to_045,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_045_to_055"),
        id: "bet_turn_045_to_055",
        accessorFn: (row) => row.bet_turn_045_to_055,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_055_to_062"),
        id: "bet_turn_055_to_062",
        accessorFn: (row) => row.bet_turn_055_to_062,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_062_to_071"),
        id: "bet_turn_062_to_071",
        accessorFn: (row) => row.bet_turn_062_to_071,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_071_to_08"),
        id: "bet_turn_071_to_08",
        accessorFn: (row) => row.bet_turn_071_to_08,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_08_to_095"),
        id: "bet_turn_08_to_095",
        accessorFn: (row) => row.bet_turn_08_to_095,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_095_to_105"),
        id: "bet_turn_095_to_105",
        accessorFn: (row) => row.bet_turn_095_to_105,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_105_to_12"),
        id: "bet_turn_105_to_12",
        accessorFn: (row) => row.bet_turn_105_to_12,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_12_to_100"),
        id: "bet_turn_12_to_100",
        accessorFn: (row) => row.bet_turn_12_to_100,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_0001_to_015"),
        id: "bet_river_0001_to_015",
        accessorFn: (row) => row.bet_river_0001_to_015,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_015_to_029"),
        id: "bet_river_015_to_029",
        accessorFn: (row) => row.bet_river_015_to_029,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_029_to_038"),
        id: "bet_river_029_to_038",
        accessorFn: (row) => row.bet_river_029_to_038,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_038_to_045"),
        id: "bet_river_038_to_045",
        accessorFn: (row) => row.bet_river_038_to_045,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_045_to_055"),
        id: "bet_river_045_to_055",
        accessorFn: (row) => row.bet_river_045_to_055,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_055_to_062"),
        id: "bet_river_055_to_062",
        accessorFn: (row) => row.bet_river_055_to_062,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_062_to_071"),
        id: "bet_river_062_to_071",
        accessorFn: (row) => row.bet_river_062_to_071,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_071_to_08"),
        id: "bet_river_071_to_08",
        accessorFn: (row) => row.bet_river_071_to_08,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_08_to_095"),
        id: "bet_river_08_to_095",
        accessorFn: (row) => row.bet_river_08_to_095,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_095_to_105"),
        id: "bet_river_095_to_105",
        accessorFn: (row) => row.bet_river_095_to_105,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_105_to_12"),
        id: "bet_river_105_to_12",
        accessorFn: (row) => row.bet_river_105_to_12,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_12_to_100"),
        id: "bet_river_12_to_100",
        accessorFn: (row) => row.bet_river_12_to_100,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.river_raise"),
        id: "river_raise",
        accessorFn: (row) => row.river_raise,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.flop_raise"),
        id: "flop_raise",
        accessorFn: (row) => row.flop_raise,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.turn_raise"),
        id: "turn_raise",
        accessorFn: (row) => row.turn_raise,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.sb_hands"),
        id: "sb_hands",
        accessorFn: (row) => row.sb_hands,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bb_hands"),
        id: "bb_hands",
        accessorFn: (row) => row.bb_hands,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.st_hands"),
        id: "st_hands",
        accessorFn: (row) => row.st_hands,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bt_hands"),
        id: "bt_hands",
        accessorFn: (row) => row.bt_hands,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.co_hands"),
        id: "co_hands",
        accessorFn: (row) => row.co_hands,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.mp_hands"),
        id: "mp_hands",
        accessorFn: (row) => row.mp_hands,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.mp1_hands"),
        id: "mp1_hands",
        accessorFn: (row) => row.mp1_hands,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.mp2_hands"),
        id: "mp2_hands",
        accessorFn: (row) => row.mp2_hands,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.utg_hands"),
        id: "utg_hands",
        accessorFn: (row) => row.utg_hands,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.utg1_hands"),
        id: "utg1_hands",
        accessorFn: (row) => row.utg1_hands,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.utg2_hands"),
        id: "utg2_hands",
        accessorFn: (row) => row.utg2_hands,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.three_bet_bar"),
        id: "three_bet_bar",
        accessorFn: (row) => row.three_bet_bar,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.wr_vs_hero"),
        id: "wr_vs_hero",
        accessorFn: (row) => row.wr_vs_hero,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.wr"),
        id: "wr",
        accessorFn: (row) => row.wr,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.win_rate"),
        id: "win_rate",
        accessorFn: (row) => row.win_rate,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.wr_bb"),
        id: "wr_bb",
        accessorFn: (row) => row.wr_bb,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.group"),
        id: "group",
        accessorFn: (row) => row.group,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_top_diap_001_to_029"),
        id: "bet_flop_top_diap_001_to_029",
        accessorFn: (row) => row.bet_flop_top_diap_001_to_029,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop"),
        id: "bet_flop",
        accessorFn: (row) => row.bet_flop,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.fold_flop"),
        id: "fold_flop",
        accessorFn: (row) => row.fold_flop,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.fold_turn"),
        id: "fold_turn",
        accessorFn: (row) => row.fold_turn,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.fold_river"),
        id: "fold_river",
        accessorFn: (row) => row.fold_river,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.abnormal_raise"),
        id: "abnormal_raise",
        accessorFn: (row) => row.abnormal_raise,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.wr_vs_hero_100"),
        id: "wr_vs_hero_100",
        accessorFn: (row) => row.wr_vs_hero_100,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.min_raise_vs_bet_flop_exp"),
        id: "min_raise_vs_bet_flop_exp",
        accessorFn: (row) => row.min_raise_vs_bet_flop_exp,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.min_raise_vs_bet_turn_exp"),
        id: "min_raise_vs_bet_turn_exp",
        accessorFn: (row) => row.min_raise_vs_bet_turn_exp,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.min_raise_vs_bet_river_exp"),
        id: "min_raise_vs_bet_river_exp",
        accessorFn: (row) => row.min_raise_vs_bet_river_exp,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_flop"),
        id: "raise_vs_pot_bet_flop",
        accessorFn: (row) => row.raise_vs_pot_bet_flop,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_turn"),
        id: "raise_vs_pot_bet_turn",
        accessorFn: (row) => row.raise_vs_pot_bet_turn,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_river"),
        id: "raise_vs_pot_bet_river",
        accessorFn: (row) => row.raise_vs_pot_bet_river,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_pos_flop"),
        id: "raise_vs_pot_bet_pos_flop",
        accessorFn: (row) => row.raise_vs_pot_bet_pos_flop,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_pos_turn"),
        id: "raise_vs_pot_bet_pos_turn",
        accessorFn: (row) => row.raise_vs_pot_bet_pos_turn,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_pos_river"),
        id: "raise_vs_pot_bet_pos_river",
        accessorFn: (row) => row.raise_vs_pot_bet_pos_river,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_flop_count"),
        id: "raise_vs_pot_bet_flop_count",
        accessorFn: (row) => row.raise_vs_pot_bet_flop_count,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_turn_count"),
        id: "raise_vs_pot_bet_turn_count",
        accessorFn: (row) => row.raise_vs_pot_bet_turn_count,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_river_count"),
        id: "raise_vs_pot_bet_river_count",
        accessorFn: (row) => row.raise_vs_pot_bet_river_count,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_flop_pos_count"),
        id: "raise_vs_pot_bet_flop_pos_count",
        accessorFn: (row) => row.raise_vs_pot_bet_flop_pos_count,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_turn_pos_count"),
        id: "raise_vs_pot_bet_turn_pos_count",
        accessorFn: (row) => row.raise_vs_pot_bet_turn_pos_count,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_river_pos_count"),
        id: "raise_vs_pot_bet_river_pos_count",
        accessorFn: (row) => row.raise_vs_pot_bet_river_pos_count,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.finger_print_01"),
        id: "finger_print_01",
        accessorFn: (row) => row.finger_print_01,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.huge_open_raise"),
        id: "huge_open_raise",
        accessorFn: (row) => row.huge_open_raise,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.huge_limp_raise"),
        id: "huge_limp_raise",
        accessorFn: (row) => row.huge_limp_raise,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.huge_3bet"),
        id: "huge_3bet",
        accessorFn: (row) => row.huge_3bet,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },

      //Timing
      {
        header: t("statsServiceTable.timing.time_pre_avg_folds"),
        id: "timing.time_pre_avg_folds",
        accessorFn: (row) => row.timing.time_pre_avg_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_avg_folds"),
        id: "timing.time_flop_avg_folds",
        accessorFn: (row) => row.timing.time_flop_avg_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_avg_folds"),
        id: "timing.time_turn_avg_folds",
        accessorFn: (row) => row.timing.time_turn_avg_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_avg_folds"),
        id: "timing.time_river_avg_folds",
        accessorFn: (row) => row.timing.time_river_avg_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_avg_checks"),
        id: "timing.time_pre_avg_checks",
        accessorFn: (row) => row.timing.time_pre_avg_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_avg_checks"),
        id: "timing.time_flop_avg_checks",
        accessorFn: (row) => row.timing.time_flop_avg_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_avg_checks"),
        id: "timing.time_turn_avg_checks",
        accessorFn: (row) => row.timing.time_turn_avg_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_avg_checks"),
        id: "timing.time_river_avg_checks",
        accessorFn: (row) => row.timing.time_river_avg_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_avg_calls"),
        id: "timing.time_pre_avg_calls",
        accessorFn: (row) => row.timing.time_pre_avg_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_avg_calls"),
        id: "timing.time_flop_avg_calls",
        accessorFn: (row) => row.timing.time_flop_avg_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_avg_calls"),
        id: "timing.time_turn_avg_calls",
        accessorFn: (row) => row.timing.time_turn_avg_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_avg_calls"),
        id: "timing.time_river_avg_calls",
        accessorFn: (row) => row.timing.time_river_avg_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_avg_raises"),
        id: "timing.time_pre_avg_raises",
        accessorFn: (row) => row.timing.time_pre_avg_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_avg_raises"),
        id: "timing.time_flop_avg_raises",
        accessorFn: (row) => row.timing.time_flop_avg_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_avg_raises"),
        id: "timing.time_turn_avg_raises",
        accessorFn: (row) => row.timing.time_turn_avg_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_avg_raises"),
        id: "timing.time_river_avg_raises",
        accessorFn: (row) => row.timing.time_river_avg_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_avg_bets"),
        id: "timing.time_flop_avg_bets",
        accessorFn: (row) => row.timing.time_flop_avg_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_avg_bets"),
        id: "timing.time_turn_avg_bets",
        accessorFn: (row) => row.timing.time_turn_avg_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_avg_bets"),
        id: "timing.time_river_avg_bets",
        accessorFn: (row) => row.timing.time_river_avg_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_max_folds"),
        id: "timing.time_pre_max_folds",
        accessorFn: (row) => row.timing.time_pre_max_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_max_folds"),
        id: "timing.time_flop_max_folds",
        accessorFn: (row) => row.timing.time_flop_max_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_max_folds"),
        id: "timing.time_turn_max_folds",
        accessorFn: (row) => row.timing.time_turn_max_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_max_folds"),
        id: "timing.time_river_max_folds",
        accessorFn: (row) => row.timing.time_river_max_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_max_checks"),
        id: "timing.time_pre_max_checks",
        accessorFn: (row) => row.timing.time_pre_max_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_max_checks"),
        id: "timing.time_flop_max_checks",
        accessorFn: (row) => row.timing.time_flop_max_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_max_checks"),
        id: "timing.time_turn_max_checks",
        accessorFn: (row) => row.timing.time_turn_max_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_max_checks"),
        id: "timing.time_river_max_checks",
        accessorFn: (row) => row.timing.time_river_max_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_max_calls"),
        id: "timing.time_pre_max_calls",
        accessorFn: (row) => row.timing.time_pre_max_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_max_calls"),
        id: "timing.time_flop_max_calls",
        accessorFn: (row) => row.timing.time_flop_max_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_max_calls"),
        id: "timing.time_turn_max_calls",
        accessorFn: (row) => row.timing.time_turn_max_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_max_calls"),
        id: "timing.time_river_max_calls",
        accessorFn: (row) => row.timing.time_river_max_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_max_raises"),
        id: "timing.time_pre_max_raises",
        accessorFn: (row) => row.timing.time_pre_max_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_max_raises"),
        id: "timing.time_flop_max_raises",
        accessorFn: (row) => row.timing.time_flop_max_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_max_raises"),
        id: "timing.time_turn_max_raises",
        accessorFn: (row) => row.timing.time_turn_max_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_max_raises"),
        id: "timing.time_river_max_raises",
        accessorFn: (row) => row.timing.time_river_max_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_max_bets"),
        id: "timing.time_flop_max_bets",
        accessorFn: (row) => row.timing.time_flop_max_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_max_bets"),
        id: "timing.time_turn_max_bets",
        accessorFn: (row) => row.timing.time_turn_max_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_max_bets"),
        id: "timing.time_river_max_bets",
        accessorFn: (row) => row.timing.time_river_max_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_min_checks"),
        id: "timing.time_pre_min_checks",
        accessorFn: (row) => row.timing.time_pre_min_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_min_checks"),
        id: "timing.time_flop_min_checks",
        accessorFn: (row) => row.timing.time_flop_min_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_min_checks"),
        id: "timing.time_turn_min_checks",
        accessorFn: (row) => row.timing.time_turn_min_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_min_checks"),
        id: "timing.time_river_min_checks",
        accessorFn: (row) => row.timing.time_river_min_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_min_calls"),
        id: "timing.time_pre_min_calls",
        accessorFn: (row) => row.timing.time_pre_min_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_min_calls"),
        id: "timing.time_flop_min_calls",
        accessorFn: (row) => row.timing.time_flop_min_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_min_calls"),
        id: "timing.time_turn_min_calls",
        accessorFn: (row) => row.timing.time_turn_min_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_min_calls"),
        id: "timing.time_river_min_calls",
        accessorFn: (row) => row.timing.time_river_min_calls,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_min_raises"),
        id: "timing.time_pre_min_raises",
        accessorFn: (row) => row.timing.time_pre_min_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_min_raises"),
        id: "timing.time_flop_min_raises",
        accessorFn: (row) => row.timing.time_flop_min_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_min_raises"),
        id: "timing.time_turn_min_raises",
        accessorFn: (row) => row.timing.time_turn_min_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_min_raises"),
        id: "timing.time_river_min_raises",
        accessorFn: (row) => row.timing.time_river_min_raises,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_min_bets"),
        id: "timing.time_flop_min_bets",
        accessorFn: (row) => row.timing.time_flop_min_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_min_bets"),
        id: "timing.time_turn_min_bets",
        accessorFn: (row) => row.timing.time_turn_min_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_min_bets"),
        id: "timing.time_river_min_bets",
        accessorFn: (row) => row.timing.time_river_min_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_min_folds"),
        id: "timing.time_pre_min_folds",
        accessorFn: (row) => row.timing.time_pre_min_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_min_folds"),
        id: "timing.time_flop_min_folds",
        accessorFn: (row) => row.timing.time_flop_min_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_min_folds"),
        id: "timing.time_turn_min_folds",
        accessorFn: (row) => row.timing.time_turn_min_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_min_folds"),
        id: "timing.time_river_min_folds",
        accessorFn: (row) => row.timing.time_river_min_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_first_folds"),
        id: "timing.time_pre_first_folds",
        accessorFn: (row) => row.timing.time_pre_first_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_first_folds"),
        id: "timing.time_flop_first_folds",
        accessorFn: (row) => row.timing.time_flop_first_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_first_folds"),
        id: "timing.time_turn_first_folds",
        accessorFn: (row) => row.timing.time_turn_first_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_first_folds"),
        id: "timing.time_river_first_folds",
        accessorFn: (row) => row.timing.time_river_first_folds,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_first_checks"),
        id: "timing.time_pre_first_checks",
        accessorFn: (row) => row.timing.time_pre_first_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_first_checks"),
        id: "timing.time_flop_first_checks",
        accessorFn: (row) => row.timing.time_flop_first_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_first_checks"),
        id: "timing.time_turn_first_checks",
        accessorFn: (row) => row.timing.time_turn_first_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_first_checks"),
        id: "timing.time_river_first_checks",
        accessorFn: (row) => row.timing.time_river_first_checks,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_pre_first_bets"),
        id: "timing.time_pre_first_bets",
        accessorFn: (row) => row.timing.time_pre_first_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_flop_first_bets"),
        id: "timing.time_flop_first_bets",
        accessorFn: (row) => row.timing.time_flop_first_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_turn_first_bets"),
        id: "timing.time_turn_first_bets",
        accessorFn: (row) => row.timing.time_turn_first_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.timing.time_river_first_bets"),
        id: "timing.time_river_first_bets",
        accessorFn: (row) => row.timing.time_river_first_bets,
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
    ],
    [t]
  );
};
