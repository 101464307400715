import React, { ReactNode, useState } from "react";
import { Button, Popover, Paper, Typography, Box } from "@mui/material";

interface PopconfirmProps {
  onClose?: () => void;
  onConfirm: () => void;
  title: string;
  description?: string;
  children: ReactNode;
}

const Popconfirm: React.FC<PopconfirmProps> = ({ onClose, onConfirm, title, description, children }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose && onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    setAnchorEl(null);
  };

  return (
    <div style={{display: "inline-block"}}>
      <div onClick={handleOpen}>{children}</div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Paper sx={{ p: 2, boxShadow: 3, borderRadius: 2, minWidth: 250 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {title}
          </Typography>

          {description && <Typography variant="body2" sx={{ mt: 1 }}>{description}</Typography>}

          <Box display="flex" justifyContent="flex-end" gap={1} mt={2}>
            <Button onClick={handleClose} size="small">
              Cancel
            </Button>
            <Button onClick={handleConfirm} size="small" variant="contained">
              OK
            </Button>
          </Box>
        </Paper>
      </Popover>
    </div>
  );
};

export default Popconfirm;
