import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { BoardingPlayerSessionDto } from "@/shared/api";
import { useApi } from "@/shared/api/use-api";

export const useBoardingPlayerSessionsQuery = (
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<Array<BoardingPlayerSessionDto>>>, "queryKey">
) => {
  const { boardingPlayerSessionsApi } = useApi();

  return useQuery({
    queryKey: ["/boarding-player-sessions"],
    queryFn: ({ signal }) => boardingPlayerSessionsApi.getAllBoardingPlayerSessionsWithRoom({ signal }),
    ...queryOptions,
  });
}; 